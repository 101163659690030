import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { UserProfileEditComponent } from '../user-profile-edit/user-profile-edit.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import 'hammerjs';
import { HammerModule } from '@angular/platform-browser';

@Component({
  selector: 'app-chat-info-image-editor',
  templateUrl: './chat-info-image-editor.component.html',
  styleUrls: ['./chat-info-image-editor.component.scss'],
})
export class ChatInfoImageEditorComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;

  fileName: any = '';
  fileSelected: any = '';
  channelid: string = '';
  channelname: string = '';

  saveLoading: boolean = false;

  // Image cropper
  imageChangedEvent: any = '';
  showCropTool: boolean = false;

  submitForm: any = {
    imgURL: '',
    newImgURL: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserProfileEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    if (data) {
      this.submitForm.imgURL = data.imgurl;
      this.channelid = data.channelid;
      this.channelname = data.channelname;
    }
    if (data.imgurl) {
    }
  }

  ngOnInit(): void {}

  async submit() {
    this.saveLoading = true;
    if (this.fileName != '') {
      if (this.fileSelected.size >= 102400) {
        this.fileSelected = await this.allinoneService.compressImage(
          this.fileSelected,
          0.1
        );
        if (this.fileSelected == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.dialogRef.disableClose = false;
          this.saveLoading = false;
          return;
        }
      }

      // const result = await this.allinoneService.groupPhotoUploadToS3(
      //   this.fileSelected,
      //   this.channelid + '/' + this.fileName
      // );

      // if (result) {
      // } else {
      //   this.dialogRef.disableClose = false;
      //   this.messageService.openSnackBar(
      //     'Error while uploading attachment.',
      //     'warn'
      //   );
      // }

      let bucketkey = this.channelid + '/' + this.fileName;

      this.allinoneService
        .uploadFileToS3WithPresignedUrl(
          bucketkey,
          this.fileSelected,
          this.fileSelected.type
        )
        .subscribe({
          next: (success) => {
            if (success) {
              console.log('File upload completed:');
            }
          },
          error: (err) => {
            this.dialogRef.disableClose = false;
            this.messageService.openSnackBar(
              'Error while uploading attachment.',
              'warn'
            );
          },
        });
    }

    var data = {
      contact: [],
      channelname: this.channelname,
      channelid: this.channelid,
      date: '',
      sort: '',
      channelimage: this.fileName,
      deletechannelimage: '',
    };
    this.kunyekService.updateGroupInfo(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == 300) {
          var data = {
            success: true,
            imgurl: res.imageurl,
          };
          this.dialogRef.close(data);
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err: any) => {
        this.saveLoading = false;
      }
    );
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.course.file = this.allinoneService.base64ToFile(
  //     event.base64,
  //     this.course.filename
  //   );
  //   this.course.previewurl = event.base64;
  // }

  copyImage() {
    this.showCropTool = false;
    // this.fileInput.nativeElement.value = '';
    this.submitForm.imgURL = this.submitForm.newImgURL;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileSelected = this.allinoneService.base64ToFile(
      event.base64,
      this.fileName
    );
    this.submitForm.newImgURL = event.base64;
  }

  imageLoaded() {
    // show cropper
    console.log('image loaded.');
  }

  loadImageFailed() {
    console.log('image load fail.');
  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.submitForm.imgURL = '';
    this.imageChangedEvent = '';
    // this.fileInput.nativeElement.value = '';
  }

  uploadFile(event: any) {
    console.log('this fun');

    this.showCropTool = true;
    this.fileSelected = event[0];
    this.imageChangedEvent = event;

    console.log(this.fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.submitForm.imgURL = reader.result;
      this.submitForm.newImgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
  }

  uploadFile1(event: any) {
    this.showCropTool = true;
    this.fileSelected = event.target.files[0];
    this.imageChangedEvent = event;
    // console.log(fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.submitForm.imgURL = reader.result;
      this.submitForm.newImgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
