import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { OfficeWorkpolicyComponent } from '../office-workpolicy/office-workpolicy.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { MessageService } from 'src/app/shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OfficeWorkpolicyFilterComponent } from '../office-workpolicy-filter/office-workpolicy-filter.component';

@Component({
  selector: 'app-office-workpolicy-choose-members',
  templateUrl: './office-workpolicy-choose-members.component.html',
  styleUrls: ['./office-workpolicy-choose-members.component.scss'],
})
export class OfficeWorkpolicyChooseMembersComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isFocus: boolean = false;
  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 10;
  currentPage: number = 1;

  searchText: string = '';

  memberList: any = [];
  selectedMemberList: any = [];
  memberListLoading: boolean = false;
  orgid: string = '';
  isAdmin: boolean = false;
  organizations: any = [];
  selectedMembers = 0;
  saveLoading: boolean = false;

  currentPageAllSelected: boolean = false;
  searchPageAllSelected: boolean = false;

  filteredUserid: any = [];
  filterExpression: any = [];
  filterData: any = [];
  filteredMemberlist: any = [];

  selectedEmployeeList: any = [];

  startdate: any = '';
  enddate: any = '';

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private filterPipe: FilterPipe,
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<OfficeWorkpolicyComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.memberList = JSON.parse(JSON.stringify(data.members));
    this.selectedMemberList = JSON.parse(JSON.stringify(data.selectedCount));
    this.orgid = data.orgid;
    this.startdate = data.startdate;
    this.enddate = data.enddate;
    this.onResize(event);
  }

  ngOnInit(): void {
    interface Member {
      userid: string;
      employeeid: string;
      username: string;
      type: string;
      department: string;
      division: string;
    }
    this.filterData = this.memberList.map((member: Member) => ({
      post: member.type,
      department: member.department,
      division: member.division
    }));
    this.filteredMemberlist = this.memberList;
    // if (this.memberList.length == 0) {
    // this.getMemberList();
    // }
    // else {
    //   this.memberList.map((x: any) => {
    //     if (this.selectedMemberList.includes(x.userid)) {
    //       x.isChecked = true;
    //     }
    //     else {
    //       x.isChecked = false;
    //     }
    //   })
    // }
  }

  getMemberList() {
    this.memberListLoading = true;
    this.memberList = [];
    const data = {
      active: true,
      orgid: this.orgid,
      rosterstartdate: this.startdate,
      rosterenddate: this.enddate,
      checkrostercalendar: true,
    };
    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.datalist;
          console.log(`Employee List: ${JSON.stringify(this.memberList)}`);
          this.memberList = this.memberList.filter(
            (x: any) => x.employeeid != ''
          );
          this.memberList = this.memberList.map((item: any) => ({
            ...item,
            isChecked: false,
          }));

          this.memberList.map((x: any) => {
            if (this.selectedMemberList.includes(x.userid)) {
              x.isChecked = true;
            } else {
              x.isChecked = false;
            }
          });
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.memberListLoading = false;
      },
      (err) => {
        this.memberListLoading = false;
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.memberList;
    if (!sort.active || sort.direction === '') {
      this.memberList = data;
      return;
    }
    this.memberList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'post':
          return this.allinoneService.compare(a.post, b.post, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        default:
          return 0;
      }
    });
  }

  currentPageSelectAll() {
    if (this.searchText != '') {
      var filteredData = this.filterPipe.transform(
        this.memberList,
        this.searchText,
        'memberList'
      );

      if (this.currentPageAllSelected == true) {
        for (var i = 0; i < filteredData.length; i++) {
          this.filteredUserid.push(filteredData[i]['userid']);
        }
        for (var i = 0; i < this.filteredUserid.length; i++) {
          for (var x = 0; x < this.memberList.length; x++) {
            if (this.filteredUserid[i] == this.memberList[x]['userid']) {
              this.memberList[x]['isChecked'] = true;
            }
          }
        }
      } else {
        for (var i = 0; i < this.filteredUserid.length; i++) {
          for (var x = 0; x < this.memberList.length; x++) {
            if (this.filteredUserid[i] == this.memberList[x]['userid']) {
              this.memberList[x]['isChecked'] = false;
            }
          }
        }
        // filteredUserid = [];
      }
    } else {
      if (this.currentPageAllSelected == true) {
        for (var i = 0; i < this.memberList.length; i++) {
          this.memberList[i].isChecked = true;
        }
      } else {
        for (var i = 0; i < this.memberList.length; i++) {
          this.memberList[i].isChecked = false;
        }
      }
    }
  }

  selectMember(member: any) {
    if (member.isChecked) {
    } else {
      this.currentPageAllSelected = false;
    }
  }

  addEmployee() {
    this.selectedEmployeeList = [];
    for (var i = 0; i < this.memberList.length; i++) {
      if (this.memberList[i]['isChecked']) {
        this.selectedEmployeeList.push(this.memberList[i]);
      }
    }

    this.dialogRef.close(this.selectedEmployeeList);
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.currentPageAllSelected = false;
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  filter() {
    interface Member {
      userid: string;
      employeeid: string;
      username: string;
      type: string;
      department: string;
      division: string;
    }

    const dialog = this.dialog.open(OfficeWorkpolicyFilterComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        memberList: this.filterData
      }
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.filterExpression = dialogResult;
        this.filteredMemberlist = this.memberList.filter((member: Member) => {
          return (
            (!dialogResult.userid || member.userid.includes(this.filterExpression.userid)) &&
            (!dialogResult.username || member.username.includes(this.filterExpression.username)) &&
            (!dialogResult.employeeid || member.employeeid.includes(this.filterExpression.employeeid)) &&
            (!dialogResult.post || member.type === this.filterExpression.post) &&
            (!dialogResult.department || member.department === this.filterExpression.department) &&
            (!dialogResult.division || member.division === this.filterExpression.division)
          );
        });
      }
    });
  }
}
