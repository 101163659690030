import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;
  @ViewChild('postnote') postNoteInput: ElementRef | undefined;

  // 0 - People , 1 - Org
  selectedTab = new FormControl(0);
  finalSelectedTab = new FormControl(0);

  imageList: any[] = [];
  pdfList: any[] = [];
  choosePeoples: any[] = [];
  finalChoosePeople: any[] = [];
  contactsList: any = [];
  contactsListToShow: any[] = [];
  contactListForInfiniteScroll: any[] = [];
  classCodeList: any[] = [];
  chooseClassCode: any[] = [];
  finalChooseClassCode: any[] = [];
  orgListForTag: any[] = [];
  defaultAnswers: any = [];
  chooseDefaultAnswers: any = [];
  finalChooseDefaultAnswers: any = [];
  confirmChooseDefaultAnswers: any = [];
  confirmCustomAnswers: any = [];
  customFields: any = [];
  customFieldsOrigin: any = [];
  customFieldsFinal: any = [];

  saveLoading: boolean = false;
  isTagPeople: boolean = false;
  isMeetingLink: boolean = false;
  gettingContactList: boolean = false;
  gettingMoreContact: boolean = false;
  isFocus: boolean = false;
  editLoading: boolean = false;
  meetingFormSubmitted: boolean = false;
  meetingLinkAdded: boolean = false;
  selectingClassCode: boolean = false;
  gettingClassCode: boolean = false;
  isCheckAll: boolean = false;
  isFirstTime: boolean = true;
  defaultAnswer: boolean = false;
  customAnswer: boolean = false;
  isAudience: boolean = false;
  firstTimeAudience: boolean = true;
  isDuplicate: boolean = false;
  gettingGroupList: boolean = false;

  cusFieldCount: number = 99;
  maxOption: any = 1;
  maxOptionFinal: any = 1;
  currentCount: number = 20;
  currentStart: number = 0;
  currentEnd: number = 20;

  searchData: string = '';
  type: number = 2;

  isDomainAdmin: boolean = false;
  isOrgAdmin: boolean = false;

  domainName: any = '';
  domainImage: any = '';
  groupname: string = '';

  postAsDomain: boolean = false;
  postAsOrg: boolean = false;

  chooseOrg: any;
  chooseOrgImage: any = '';
  finalChooseOrg: any;
  finalChooseOrgImage: any = '';

  tagOrg: any;
  finalTagOrg: any;

  post: any;

  maxOptionCount: any[] = [];
  groupList: any[] = [];
  weeklyDay: string = '';
  weeklyDaylong: string = '';

  eventTypeList = [
    { id: '0', name: 'Post' },
    { id: '7', name: 'Video' },
    { id: '1', name: 'Meeting' },
    { id: '6', name: 'Class' },
    { id: '2', name: 'Seminar' },
    { id: '3', name: 'Conference' },
    { id: '4', name: 'Poll' },
    { id: '5', name: 'Others' },
  ];
  // this.metting.comment = true;
  //     this.metting.notifications = true;
  //     this.meetingForm.eventtype = '6';

  postSettingList = [
    {
      id: '001',
      icon: 'fas fa-globe-americas',
      name: 'Anyone',
      orgname: 'All members',
      orgicon: 'fas fa-globe-americas',
    },
    {
      id: '002',
      icon: 'fas fa-user-friends',
      name: 'Connections only',
      orgname: 'Member Types',
      orgicon: 'fal fa-user-friends',
    },
    {
      id: '003',
      icon: 'fa fa-tags',
      name: 'Tags only',
      orgname: 'Tags only',
      orgicon: 'fa fa-tags',
    },
    {
      id: '004',
      icon: 'fas fa-lock',
      name: 'No one',
      orgname: 'No one',
      orgicon: 'fas fa-lock',
    },
  ];

  postForOrgSettingList = [
    {
      id: '001',
      icon: 'fas fa-user-friends',
      name: 'Anyone',
      orgname: 'All members',
    },
    {
      id: '002',
      icon: 'fas fa-user-friends',
      name: 'Connections only',
      orgname: 'Member Types',
    },
  ];

  postAsList = [
    {
      orgid: '',
      name: this.allinoneService.profile.username
        ? this.allinoneService.profile.username
        : this.allinoneService.profile.userid,
      orgtag: false,
      username: '',
      domain: false,
    },
  ];

  membershipsTypes = [
    {
      id: '001',
      name: 'Association',
    },
    {
      id: '002',
      name: 'Company',
    },
    {
      id: '003',
      name: 'Loyalty',
    },
  ];

  postForm = {
    domain: this.allinoneService.getDomain().shortcode,
    org: '',
    type: '',
    postnote: '',
    imagelist: '',
  };

  finalAudienceForm = {
    postsettingindex: 1,
    postsettingindexorg: 1,
    postas: '',
    tag: 'people',
    postfororg: false,
    index: 1,
    domain: false,
    channelid: '',
    hidenoti: true,
  };

  tempAudienceForm = {
    postsettingindex: 0,
    postsettingindexorg: 0,
    postas: '',
    tag: 'people',
    postfororg: false,
    index: 0,
    domain: false,
    channelid: '',
    hidenoti: true,
  };

  formatDateToShow = 'yyyy-MM-dd';
  formatTimeToShow = 'HH:mm:ss';

  now = Date.now();

  oneHourPlus = new Date(
    new Date(this.now).setHours(new Date(this.now).getHours() + 1)
  );

  currentDate = formatDate(
    Date.now(),
    this.formatDateToShow,
    'en-US'
  ).toString();
  currentStartTime = formatDate(
    Date.now(),
    this.formatTimeToShow,
    'en-US'
  ).toString();
  currentEndTime = formatDate(
    new Date(this.oneHourPlus),
    this.formatTimeToShow,
    'en-US'
  ).toString();

  meetingForm = {
    eventtype: '0',
    link: '',
    description: '',
    venue: '',
    startdate: this.currentDate,
    enddate: this.currentDate,
    starttime: this.currentStartTime,
    endtime: this.currentEndTime,
    answertype: '1',
    showcount: false,
    showresult: false,
    shownamelist: false,
    mandatoryresp: false,
    maxoption: '1',
    noti: true,
    reminder: false,
    daterange: false,
    voting: 'true',
    ics: false,
    weekly: false,
    comment: true,
    videourl: '',
  };

  finalMeetingForm = {
    eventtype: '0',
    link: '',
    description: '',
    venue: '',
    startdate: '',
    enddate: '',
    starttime: '',
    endtime: '',
    answertype: '1',
    showcount: false,
    showresult: false,
    shownamelist: false,
    mandatoryresp: false,
    maxoption: '1',
    noti: false,
    reminder: false,
    daterange: false,
    voting: 'true',
    ics: false,
    weekly: false,
    comment: true,
    videourl: '',
  };

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<CreatePostComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orgListForTag = data.orgList ? data.orgList : [];
    this.createPostAsList();
    this.finalAudienceForm.index = this.postAsList.findIndex(
      (x: any) => x.orgid === ''
    );
    this.post = data.post;
    if (data.post) {
      this.editLoading = true;
      this.isDuplicate = data.duplicate;
    }
  }

  async ngOnInit(): Promise<void> {
    this.dialogRef.disableClose = true;
    if (!this.post) {
      this.getGroupList();
    }
    if (this.allinoneService.isAdminOf('domain')) {
      this.domainName = this.allinoneService.admindomain.description;
      this.isDomainAdmin = true;
      this.domainImage = this.allinoneService.admindomain.image.imageurl;

      this.postAsList.push({
        name: 'Domain - ' + this.domainName,
        orgid: '',
        username: '',
        orgtag: false,
        domain: true,
      });
    }

    if (this.allinoneService.isAdminOf('org')) {
      this.isOrgAdmin = true;
    }

    if (this.orgListForTag.length == 0) {
      const waitOrg = await this.getOrgs();
      this.createPostAsList();
      this.finalAudienceForm.index = this.postAsList.findIndex(
        (x: any) => x.orgid === ''
      );
    }

    if (this.post) {
      const waitDefaultAns = await this.getDefaultAnswers();
      const waitGroup = await this.promiseGetGroupList();
      this.postForm.postnote = this.post.note;
      this.finalMeetingForm.comment =
        this.post.commentsetting == 'true' ? true : false;
      this.finalMeetingForm.noti =
        this.post.notification == 'true' ? true : false;
      if (!this.isDuplicate) {
        if (this.post.type == '1') {
          this.clickPostDomain();
        } else if (this.post.type == '2') {
          var index = this.postSettingList
            .map((x: any) => x.id)
            .indexOf(this.post.postsetting);
          this.finalAudienceForm.postsettingindex = index > -1 ? index : 0;
          this.post.tag.map((tagP: any) => {
            var temp = {
              userid: tagP.userid,
              imagename: tagP.userimage,
              username: tagP.username,
              usersyskey: tagP.usersyskey,
            };
            this.finalChoosePeople.push(temp);
          });

          if (this.post.orgtag.length > 0) {
            this.finalTagOrg = this.post.orgtag[0].orgid;
            this.finalAudienceForm.tag = 'org';
          }
          if (this.post.channelid) {
            this.finalAudienceForm.channelid = this.post.channelid;
            this.groupname =
              this.groupList.find(
                (x: any) => x.channelid == this.finalAudienceForm.channelid
              )?.channelname || '';
          }
        } else if (this.post.type == '3' || this.post.type == '4') {
          var index = this.postSettingList
            .map((x: any) => x.id)
            .indexOf(this.post.postsetting);
          this.finalAudienceForm.postsettingindexorg = index;
          this.post.tag.map((tagP: any) => {
            var temp = {
              userid: tagP.userid,
              imagename: tagP.userimage,
              username: tagP.username,
              usersyskey: tagP.usersyskey,
            };
            this.finalChoosePeople.push(temp);
          });

          this.post.tagclass.map((item: any) => {
            const data = {
              name: item,
              checked: true,
            };
            this.chooseClassCode.push(data);
            this.finalChooseClassCode.push(data);
          });
          this.chooseOrg = this.post.orgid;
          this.finalChooseOrg = this.post.orgid;
          this.finalAudienceForm.postas = this.chooseOrg;
          if (this.post.type == '3') {
            this.finalAudienceForm.index = this.postAsList.findIndex(
              (x: any) => x.orgid === this.chooseOrg && x.orgtag === false
            );
            this.getOrgImage(this.chooseOrg);
            this.clickPostOrg();
          } else {
            this.finalChooseOrg = this.post.orgid;
            this.finalAudienceForm.index = this.postAsList.findIndex(
              (x: any) => x.orgid === this.chooseOrg && x.orgtag === true
            );
            this.finalAudienceForm.hidenoti = !this.orgListForTag.find(
              (x: any) => x.orgid == this.chooseOrg
            )?.admin;
            this.finalAudienceForm.postfororg = true;
          }
        }
      }
      for (let i = 0; i < this.post.image.length; i++) {
        var temp = {
          file: '',
          previewurl: this.post.image[i],
          filename: this.post.imagename[i],
        };
        this.imageList.push(temp);
      }
      this.post.filename.map((item: any) => {
        var temp2 = {
          file: '',
          filename: item,
        };
        this.pdfList.push(temp2);
      });
      if (
        (this.post.meetinglink && this.post.meetingtype == '') ||
        this.post.meetingtype != ''
      ) {
        this.meetingLinkAdded = true;
        ////////////Edit Post Meeting Form
        this.finalMeetingForm = {
          eventtype: this.post.meetingtype == '' ? '0' : this.post.meetingtype,
          link: this.post.meetinglink,
          description: this.post.meetingdesc,
          venue: this.post.venue,
          startdate: this.post.startdate,
          enddate: this.post.enddate,
          starttime: this.post.starttime,
          endtime: this.post.endtime,
          answertype: this.post.defaultvote.length == 0 ? '2' : '1',
          showcount: this.post.showcount == 'true' ? true : false,
          showresult: this.post.showresult == 'true' ? true : false,
          shownamelist: this.post.shownamelist == 'true' ? true : false,
          mandatoryresp: this.post.mandatory == 'true' ? true : false,
          maxoption: this.post.maxoption,
          noti: this.finalMeetingForm.noti,
          comment: this.finalMeetingForm.comment,
          reminder: this.post.reminder == 'true' ? true : false,
          daterange: this.post.manual == 'true' ? true : false,
          voting: this.post.voting == '' ? 'false' : this.post.voting,
          ics: this.post.ics == 'true' ? true : false,
          weekly: this.post.meetingappear == '' ? false : true,
          videourl: this.post.videourl,
        };
        this.confirmChooseDefaultAnswers = this.post.defaultvote;
        this.confirmCustomAnswers = this.post.customvote;
      }

      if (this.post.videopost) {
        this.finalMeetingForm.eventtype = '7';
        this.finalMeetingForm.videourl = this.post.videourl;
      }

      if (this.isDuplicate) {
        if (this.isOrgAdmin && this.postAsList[0].orgid) {
          var index = 0;
          if (this.allinoneService.selectedOrg) {
            index = this.postAsList.findIndex(
              (x: any) =>
                x.orgid === this.allinoneService.selectedOrg && x.orgtag
            );
          }
          this.tempAudienceForm.index = index;
          this.tempAudienceForm.postas = this.postAsList[index].orgid;
          this.tempAudienceForm.postfororg = this.postAsList[index].orgtag;
          if (this.tempAudienceForm.postfororg) {
            this.tempAudienceForm.postsettingindexorg = 1;
          }
          this.chooseOrg = this.tempAudienceForm.postas;
          this.isFirstTime = true;
          this.currentStart = 0;
          this.currentEnd = 20;
          this.choosePeoples = [];
          this.getContactList();
          const await_classocde = await this.getClassCodeForEdit(true);
          this.finishAudience();
          console.log(this.finalChooseClassCode);
        }
      }
      this.editLoading = false;
    } else {
      this.getDefaultAnswers();
      if (this.isOrgAdmin && this.postAsList[0].orgid) {
        var index = 0;
        if (this.allinoneService.selectedOrg) {
          index = this.postAsList.findIndex(
            (x: any) => x.orgid === this.allinoneService.selectedOrg && x.orgtag
          );
        }
        if (index >= 0) {
          this.tempAudienceForm.hidenoti = false;
          this.tempAudienceForm.index = index;
          this.tempAudienceForm.postas = this.postAsList[index].orgid;
          this.tempAudienceForm.postfororg = this.postAsList[index].orgtag;
          if (this.tempAudienceForm.postfororg) {
            this.tempAudienceForm.postsettingindexorg = 0;
          }
          this.chooseOrg = this.tempAudienceForm.postas;
          // this.getClassCodeForEdit(true);
          this.finishAudience();
        }
      }
    }
  }

  promiseGetGroupList() {
    return new Promise((resolve) => {
      this.gettingGroupList = true;
      const data = {
        contact: '',
        count: '10',
        channelid: '',
        domain: this.allinoneService.getDomain().shortcode,
        checkingroup: 'true',
      };
      this.kunyekService.getChatList(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.groupList = res.list.reverse();
            this.gettingGroupList = false;
          } else {
            this.gettingGroupList = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
          resolve(true);
        },
        (err) => {
          this.gettingGroupList = false;
          resolve(false);
        }
      );
    });
  }

  getGroupList() {
    this.gettingGroupList = true;
    const data = {
      contact: '',
      count: '10',
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
      checkingroup: 'true',
    };
    this.kunyekService.getChatList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.groupList = res.list.reverse();
          this.gettingGroupList = false;
        } else {
          this.gettingGroupList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingGroupList = false;
      }
    );
  }

  createPostAsList() {
    this.orgListForTag.map((item: any) => {
      if (item.admin == 'true' || item.postadmin) {
        this.postAsList.push({ ...item, orgtag: false });
      }
      if (item.orgtype == '001' || item.orgtype == '002') {
        this.postAsList.unshift({
          ...item,
          orgtag: true,
          username: this.allinoneService.profile.username
            ? this.allinoneService.profile.username
            : this.allinoneService.profile.userid,
        });
      }
    });
  }

  getOrgs() {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.kunyekService.getOrgs(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.orgListForTag = res.list;
            resolve(true);
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
            resolve(false);
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  focus() {
    if (
      this.imageList.length == 0 &&
      this.pdfList.length == 0 &&
      !this.meetingLinkAdded
    ) {
      this.postNoteInput?.nativeElement.focus();
    }
  }

  onScrollDown() {
    this.isFirstTime = false;
    if (this.contactListForInfiniteScroll.length == this.currentEnd) {
      this.currentStart = this.currentEnd;
      this.currentEnd += 10;
      this.getContactList();
    }
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }

  onPdfSelect(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if (file.type == 'application/pdf') {
          if (file.size < this.allinoneService.allowedFileSize) {
            var alreadySelect = false;
            this.pdfList.map((item: any) => {
              if (item.filename == file.name) {
                alreadySelect = true;
              }
            });
            if (!alreadySelect) {
              var temp = {
                file: file,
                filename: file.name,
              };
              this.pdfList.push(temp);
            }
          } else {
            this.messageService.openSnackBar(
              'Maximum file size is ' +
                this.allinoneService.allowedFileSize / 1024 / 1024 +
                ' MB.',
              'warn'
            );
          }
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenpdffileinput.nativeElement.value = '';
    }
  }

  removePdf(index: number) {
    this.pdfList.splice(index, 1);
  }

  removeImage(index: number) {
    this.imageList.splice(index, 1);
  }

  getFileName(file: any) {
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  submitPost() {
    if (
      this.postForm.postnote == '' &&
      this.imageList.length == 0 &&
      this.pdfList.length == 0 &&
      !this.meetingLinkAdded &&
      this.finalMeetingForm.eventtype != '7'
    ) {
      return;
    } else {
      this.savePost();
    }
  }

  async savePost() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var nameList: any[] = [];
    var oldNameList: any[] = [];
    var filestobeuploaded: any[] = [];
    if (this.imageList.length != 0) {
      this.imageList.map((data: any) => {
        if (this.post) {
          if (data.file) {
            filestobeuploaded.push(data);
            nameList.push(data.filename);
          } else {
            oldNameList.push(data.filename);
          }
        } else {
          filestobeuploaded.push(data);
          nameList.push(data.filename);
        }
      });
    }

    for (let i = 0; i < filestobeuploaded.length; i++) {
      if (filestobeuploaded[i].file.size >= 307200) {
        filestobeuploaded[i].file = await this.allinoneService.compressImage(
          filestobeuploaded[i].file
        );
        if (filestobeuploaded[i].file == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          return;
        }
      }
    }

    var filenamelist: any[] = [];
    var oldFileNameList: any[] = [];
    if (this.pdfList.length != 0) {
      this.pdfList.map((data: any) => {
        if (this.post) {
          if (data.file) {
            filestobeuploaded.push(data);
            filenamelist.push(data.filename);
          } else {
            oldFileNameList.push(data.filename);
          }
        } else {
          filestobeuploaded.push(data);
          filenamelist.push(data.filename);
        }
      });
    }

    var tagList: any[] = [];
    if (this.finalChoosePeople.length != 0) {
      this.finalChoosePeople.map((data: any) => {
        tagList.push(data.userid);
      });
    }

    var tagClass: any[] = [];
    if (this.finalChooseClassCode.length != 0) {
      this.finalChooseClassCode.map((data: any) => {
        tagClass.push(data.name);
      });
    }

    var type = '';
    if (this.postAsDomain == true) {
      type = '1';
      tagList = [];
      this.finalAudienceForm.postsettingindex = 0;
      this.finalAudienceForm.postsettingindexorg = 0;
      this.finalTagOrg = '';
    } else if (this.postAsOrg == true) {
      type = '3';
    } else if (this.finalAudienceForm.postfororg) {
      type = '4';
    } else {
      type = '2';
    }

    if (
      this.finalAudienceForm.postsettingindex == 3 ||
      this.finalAudienceForm.postsettingindexorg == 3
    ) {
      tagList = [];
      this.finalTagOrg = '';
    }

    var data;

    var fun;
    if (this.post && !this.isDuplicate) {
      data = {
        meetingappear: this.meetingForm.weekly == true ? this.weeklyDay : '',
        syskey: this.post.syskey,
        domain: this.postForm.domain,
        note: this.postForm.postnote,
        tag: tagList,
        imagename: nameList,
        deleteimage: oldNameList,
        type: type,
        orgid: this.finalChooseOrg ? this.finalChooseOrg : '',
        filename: filenamelist,
        deletefile: oldFileNameList,
        meetinglink:
          this.finalMeetingForm.eventtype != '4' &&
          this.finalMeetingForm.eventtype != '5'
            ? this.finalMeetingForm.link
            : '',
        startdate: this.finalMeetingForm.startdate,
        starttime: this.finalMeetingForm.starttime,
        enddate: this.finalMeetingForm.enddate,
        endtime: this.finalMeetingForm.endtime,
        tagclass: tagClass,
        orgtag: this.finalTagOrg ? [this.finalTagOrg] : [],
        ics:
          this.finalMeetingForm.eventtype != '1' &&
          this.finalMeetingForm.eventtype != '6'
            ? 'false'
            : this.finalMeetingForm.ics
            ? 'true'
            : 'false',
        tmpimage: '',
        tmpfile: '',
        meetingtype:
          this.finalMeetingForm.eventtype.toString() == '0' ||
          this.finalMeetingForm.eventtype.toString() == '7'
            ? ''
            : this.finalMeetingForm.eventtype.toString(),
        meetingdesc: this.finalMeetingForm.description,
        venue:
          this.finalMeetingForm.eventtype != '4'
            ? this.finalMeetingForm.venue
            : '',
        customvote:
          this.finalMeetingForm.answertype == '2' &&
          this.finalMeetingForm.eventtype == '4'
            ? this.confirmCustomAnswers
            : [],
        defaultvote:
          this.finalMeetingForm.answertype == '1' ||
          this.finalMeetingForm.answertype == '6'
            ? this.confirmChooseDefaultAnswers
            : [],
        showcount: this.finalMeetingForm.showcount ? 'true' : 'false',
        showresult: this.finalMeetingForm.showresult ? 'true' : 'false',
        shownamelist: this.finalMeetingForm.shownamelist ? 'true' : 'false',
        mandatory:
          this.finalMeetingForm.mandatoryresp &&
          this.finalMeetingForm.eventtype == '4'
            ? 'true'
            : 'false',
        maxoption:
          this.finalMeetingForm.eventtype == '4'
            ? this.maxOptionFinal.toString()
            : '1',
        notification:
          this.finalMeetingForm.noti && !this.finalAudienceForm.hidenoti
            ? 'true'
            : 'false',
        reminder:
          this.finalMeetingForm.eventtype == '4' &&
          this.finalMeetingForm.reminder
            ? 'true'
            : 'false',
        manual: this.finalMeetingForm.daterange ? 'true' : 'false',
        voting:
          this.finalMeetingForm.eventtype == '4' &&
          !this.finalMeetingForm.daterange
            ? this.finalMeetingForm.voting
            : 'false',
        manualstart: this.finalMeetingForm.startdate,
        manualend: this.finalMeetingForm.enddate,
        postsetting: this.finalAudienceForm.channelid
          ? '005'
          : this.postSettingList[
              this.postAsOrg || this.finalAudienceForm.postfororg
                ? this.finalAudienceForm.postsettingindexorg
                : this.finalAudienceForm.postsettingindex
            ].id,
        comment: this.finalMeetingForm.comment ? 'true' : 'false',
        channelid: this.finalAudienceForm.channelid,
        videopost: this.finalMeetingForm.eventtype == '7',
        videourl:
          this.finalMeetingForm.eventtype == '7'
            ? this.finalMeetingForm.videourl
            : '',
      };
      fun = this.kunyekService.editPost(data);
    } else if (this.post && this.isDuplicate) {
      data = {
        meetingappear: this.meetingForm.weekly == true ? this.weeklyDay : '',
        oldsyskey: this.post.syskey,
        domain: this.postForm.domain,
        domainid: this.allinoneService.getDomain().domainid,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        note: this.postForm.postnote,
        tag: tagList,
        imagename: nameList,
        oldimage: oldNameList,
        type: type,
        orgid: this.finalChooseOrg ? this.finalChooseOrg : '',
        filename: filenamelist,
        oldfile: oldFileNameList,
        meetinglink:
          this.finalMeetingForm.eventtype != '4' &&
          this.finalMeetingForm.eventtype != '5'
            ? this.finalMeetingForm.link
            : '',
        startdate: this.finalMeetingForm.startdate,
        starttime: this.finalMeetingForm.starttime,
        enddate: this.finalMeetingForm.enddate,
        endtime: this.finalMeetingForm.endtime,
        tagclass: tagClass,
        orgtag: this.finalTagOrg ? [this.finalTagOrg] : [],
        ics:
          this.finalMeetingForm.eventtype != '1' &&
          this.finalMeetingForm.eventtype != '6'
            ? 'false'
            : this.finalMeetingForm.ics
            ? 'true'
            : 'false',
        tmpimage: '',
        tmpfile: '',
        meetingtype:
          this.finalMeetingForm.eventtype.toString() == '0' ||
          this.finalMeetingForm.eventtype.toString() == '7'
            ? ''
            : this.finalMeetingForm.eventtype.toString(),
        meetingdesc: this.finalMeetingForm.description,
        venue:
          this.finalMeetingForm.eventtype != '4'
            ? this.finalMeetingForm.venue
            : '',
        customvote:
          this.finalMeetingForm.answertype == '2' &&
          this.finalMeetingForm.eventtype == '4'
            ? this.confirmCustomAnswers
            : [],
        defaultvote:
          this.finalMeetingForm.answertype == '1' ||
          this.finalMeetingForm.answertype == '6'
            ? this.confirmChooseDefaultAnswers
            : [],
        showcount: this.finalMeetingForm.showcount ? 'true' : 'false',
        showresult: this.finalMeetingForm.showresult ? 'true' : 'false',
        shownamelist: this.finalMeetingForm.shownamelist ? 'true' : 'false',
        mandatory:
          this.finalMeetingForm.mandatoryresp &&
          this.finalMeetingForm.eventtype == '4'
            ? 'true'
            : 'false',
        maxoption:
          this.finalMeetingForm.eventtype == '4'
            ? this.maxOptionFinal.toString()
            : '1',
        notification:
          this.finalMeetingForm.noti && !this.finalAudienceForm.hidenoti
            ? 'true'
            : 'false',
        reminder:
          this.finalMeetingForm.eventtype == '4' &&
          this.finalMeetingForm.reminder
            ? 'true'
            : 'false',
        manual: this.finalMeetingForm.daterange ? 'true' : 'false',
        voting:
          this.finalMeetingForm.eventtype == '4' &&
          !this.finalMeetingForm.daterange
            ? this.finalMeetingForm.voting
            : 'false',
        manualstart: this.finalMeetingForm.startdate,
        manualend: this.finalMeetingForm.enddate,
        postsetting: this.finalAudienceForm.channelid
          ? '005'
          : this.postSettingList[
              this.postAsOrg || this.finalAudienceForm.postfororg
                ? this.finalAudienceForm.postsettingindexorg
                : this.finalAudienceForm.postsettingindex
            ].id,
        comment: this.finalMeetingForm.comment ? 'true' : 'false',
        channelid: this.finalAudienceForm.channelid,
        videopost: this.finalMeetingForm.eventtype == '7',
        videourl:
          this.finalMeetingForm.eventtype == '7'
            ? this.finalMeetingForm.videourl
            : '',
      };
      fun = this.kunyekService.createPost(data);
    } else {
      data = {
        meetingappear: this.meetingForm.weekly == true ? this.weeklyDay : '',
        domain: this.postForm.domain,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        note: this.postForm.postnote,
        tag: tagList,
        imagename: nameList,
        type: type,
        orgid: this.finalChooseOrg ? this.finalChooseOrg : '',
        filename: filenamelist,
        meetinglink:
          this.finalMeetingForm.eventtype != '4' &&
          this.finalMeetingForm.eventtype != '5'
            ? this.finalMeetingForm.link
            : '',
        startdate: this.finalMeetingForm.startdate,
        starttime: this.finalMeetingForm.starttime,
        enddate: this.finalMeetingForm.enddate,
        endtime: this.finalMeetingForm.endtime,
        tagclass: tagClass,
        orgtag: this.finalTagOrg ? [this.finalTagOrg] : [],
        ics:
          this.finalMeetingForm.eventtype != '1' &&
          this.finalMeetingForm.eventtype != '6'
            ? 'false'
            : this.finalMeetingForm.ics
            ? 'true'
            : 'false',
        tmpimage: '',
        tmpfile: '',
        meetingtype:
          this.finalMeetingForm.eventtype.toString() == '0' ||
          this.finalMeetingForm.eventtype.toString() == '7'
            ? ''
            : this.finalMeetingForm.eventtype.toString(),
        meetingdesc: this.finalMeetingForm.description,
        venue:
          this.finalMeetingForm.eventtype != '4'
            ? this.finalMeetingForm.venue
            : '',
        customvote:
          this.finalMeetingForm.answertype == '2' &&
          this.finalMeetingForm.eventtype == '4'
            ? this.confirmCustomAnswers
            : [],
        defaultvote:
          this.finalMeetingForm.answertype == '1' ||
          this.finalMeetingForm.answertype == '6'
            ? this.confirmChooseDefaultAnswers
            : [],
        showcount: this.finalMeetingForm.showcount ? 'true' : 'false',
        showresult: this.finalMeetingForm.showresult ? 'true' : 'false',
        shownamelist: this.finalMeetingForm.shownamelist ? 'true' : 'false',
        mandatory:
          this.finalMeetingForm.mandatoryresp &&
          this.finalMeetingForm.eventtype == '4'
            ? 'true'
            : 'false',
        maxoption:
          this.finalMeetingForm.eventtype == '4'
            ? this.maxOptionFinal.toString()
            : '1',
        notification: this.finalMeetingForm.noti ? 'true' : 'false',
        reminder:
          this.finalMeetingForm.eventtype == '4' &&
          this.finalMeetingForm.reminder
            ? 'true'
            : 'false',
        manual: this.finalMeetingForm.daterange ? 'true' : 'false',
        voting:
          this.finalMeetingForm.eventtype == '4' &&
          !this.finalMeetingForm.daterange
            ? this.finalMeetingForm.voting
            : 'false',
        manualstart: this.finalMeetingForm.startdate,
        manualend: this.finalMeetingForm.enddate,
        postsetting: this.finalAudienceForm.channelid
          ? '005'
          : this.postSettingList[
              this.postAsOrg || this.finalAudienceForm.postfororg
                ? this.finalAudienceForm.postsettingindexorg
                : this.finalAudienceForm.postsettingindex
            ].id,
        comment: this.finalMeetingForm.comment ? 'true' : 'false',
        channelid: this.finalAudienceForm.channelid,
        videopost: this.finalMeetingForm.eventtype == '7',
        videourl:
          this.finalMeetingForm.eventtype == '7'
            ? this.finalMeetingForm.videourl
            : '',
      };

      fun = this.kunyekService.createPost(data);
    }

    fun.subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (filestobeuploaded.length == 0) {
            if (this.post && !this.isDuplicate) {
              this.getPosts(this.post.syskey);
            } else {
              this.getPosts(res.postsyskey);
            }
          } else {
            // const result =
            //   await this.allinoneService.fileMultiUploadToS3WithLoop(
            //     filestobeuploaded,
            //     res.postsyskey
            //   );
            // if (result) {
            //   if (this.post && !this.isDuplicate) {
            //     this.getPosts(this.post.syskey);
            //   } else {
            //     this.getPosts(res.postsyskeys);
            //   }
            // } else {
            //   this.dialogRef.disableClose = false;
            //   this.messageService.openSnackBar(
            //     'Error while uploading images.',
            //     'warn'
            //   );
            //   this.saveLoading = false;
            // }

            let bucketkey = 'post/' + res.postsyskey + '/' + filestobeuploaded[0].filename;

            this.allinoneService
              .uploadFileToS3WithPresignedUrl(
                bucketkey,
                filestobeuploaded[0],
                filestobeuploaded[0].type
              )
              .subscribe({
                next: (success) => {
                  if (success) {
                    console.log('File upload completed:');
                  }
                },
                error: (err) => console.error('Error:', err),
              });
          }
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err: any) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  tagPeople() {
    if (this.finalAudienceForm.tag == 'people') {
      var taglist = this.finalChoosePeople;
      if (taglist.length == 0 || taglist == undefined || taglist == null) {
        return '';
      } else {
        if (taglist.length == 1) {
          return (
            '&nbsp;is&nbsp;with&nbsp;<b>' + this.getName(taglist[0]) + '</b>'
          );
        } else if (taglist.length == 2) {
          return (
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b> and <b>' +
            this.getName(taglist[1]) +
            '</b>'
          );
        } else {
          return (
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b>&nbsp;and&nbsp;'
          );
        }
      }
    } else {
      if (this.finalTagOrg != '') {
        const index = this.orgListForTag.findIndex(
          (item: any) => item.orgid == this.finalTagOrg
        );
        const orgname = this.orgListForTag[index].name;
        return '&nbsp;is&nbsp;with&nbsp;<b>' + orgname + '</b>';
      } else {
        return '';
      }
    }
  }
  getName(user: any) {
    return user.username ? user.username : user.userid;
  }

  getDefaultAnswers() {
    return new Promise((resolve) => {
      if (this.isFirstTime) {
        this.gettingContactList = true;
        this.contactsList = [];
      } else {
        this.gettingMoreContact = true;
      }

      const data = {
        domain: this.allinoneService.getDomain().shortcode,
      };
      this.kunyekService.getDefaultAnswers(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.defaultAnswers = res.list;
            this.finalChooseDefaultAnswers = res.list;
            resolve(true);
          } else {
            resolve(false);
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve(true);
        }
      );
    });
  }

  // Tag People
  getContactList() {
    return new Promise((resolve) => {
      if (this.isFirstTime) {
        this.gettingContactList = true;
        this.contactsList = [];
        this.contactListForInfiniteScroll = [];
      } else {
        this.gettingMoreContact = true;
      }

      const data = {
        contact: [],
        count: this.currentCount,
        search: '',
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        start: this.currentStart,
        end: this.currentEnd,
        orgid: this.chooseOrg,
      };
      this.kunyekService.getContactList(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.contactListForInfiniteScroll.push.apply(
              this.contactListForInfiniteScroll,
              res.list
            );

            var templist: any[] = [];
            res.list.map((item: any) => {
              if (item.block == '' && item.usersyskey != '') {
                templist.push(item);
              }
            });
            if (
              (this.tempAudienceForm.postas &&
                this.chooseOrg == this.tempAudienceForm.postas) ||
              !this.tempAudienceForm.postas
            ) {
              templist.map((titem: any) => {
                this.choosePeoples.map((fitem: any) => {
                  if (fitem.userid == titem.userid) {
                    fitem = titem;
                    fitem.checked = true;
                  }
                });
              });
            }
            this.contactsList.push.apply(this.contactsList, templist);
            this.contactsListToShow = this.allinoneService.groupContacts(
              this.contactsList
            );

            this.contactsListToShow.sort((a, b) => {
              let fa = a.key.toLowerCase(),
                fb = b.key.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            this.gettingContactList = false;
            this.gettingMoreContact = false;
            resolve(true);
          } else {
            resolve(false);
            this.gettingContactList = false;
            this.gettingMoreContact = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve(true);
          this.gettingMoreContact = false;
          this.gettingContactList = false;
          this.messageService.openSnackBar(err, 'warn');
        }
      );
    });
  }

  openMeetingLinkPage() {
    console.log(this.post);
    if (this.meetingLinkAdded || this.finalMeetingForm.eventtype == '7') {
      this.editMeetingLink();
    } else {
      this.meetingForm.eventtype = '0';
      this.meetingForm.comment = this.finalMeetingForm.comment;
      this.meetingForm.noti = this.finalMeetingForm.noti;
      this.customFieldsFinal = JSON.parse(
        JSON.stringify(this.confirmCustomAnswers)
      );
      this.customFieldsOrigin = JSON.parse(
        JSON.stringify(this.confirmCustomAnswers)
      );
      this.finalChooseDefaultAnswers = this.defaultAnswers;
      this.isMeetingLink = true;
    }
  }
  closeMeetingLinkPage() {
    this.meetingFormSubmitted = false;
    this.isMeetingLink = false;
    this.finalChooseDefaultAnswers = [];
    this.meetingForm = {
      eventtype: '1',
      description: '',
      link: '',
      venue: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttime: this.currentStartTime,
      endtime: this.currentEndTime,
      answertype: '1',
      showcount: false,
      showresult: false,
      shownamelist: false,
      mandatoryresp: false,
      maxoption: '1',
      noti: false,
      reminder: false,
      daterange: false,
      voting: 'true',
      ics: false,
      weekly: false,
      comment: true,
      videourl: '',
    };
  }

  editMeetingLink() {
    this.isMeetingLink = true;
    if (this.finalMeetingForm.eventtype == '7') {
      this.meetingForm.eventtype = '7';
      this.meetingForm.videourl = this.finalMeetingForm.videourl;
    } else {
      this.customFieldsFinal = JSON.parse(
        JSON.stringify(this.confirmCustomAnswers)
      );
      this.customFieldsOrigin = JSON.parse(
        JSON.stringify(this.confirmCustomAnswers)
      );
      this.finalChooseDefaultAnswers = this.confirmChooseDefaultAnswers;
      this.meetingForm = {
        eventtype: this.finalMeetingForm.eventtype,
        description: this.finalMeetingForm.description,
        link: this.finalMeetingForm.link,
        venue: this.finalMeetingForm.venue,
        startdate: this.allinoneService.formatDBToShowInEdit(
          this.finalMeetingForm.startdate
        ),
        enddate: this.allinoneService.formatDBToShowInEdit(
          this.finalMeetingForm.enddate
        ),
        starttime: formatDate(
          new Date(
            this.allinoneService.formatDBToShowInEdit(
              this.finalMeetingForm.startdate,
              '/'
            ) +
              ' ' +
              this.finalMeetingForm.starttime
          ),
          this.formatTimeToShow,
          'en-US'
        ).toString(),
        endtime: formatDate(
          new Date(
            this.allinoneService.formatDBToShowInEdit(
              this.finalMeetingForm.startdate,
              '/'
            ) +
              ' ' +
              this.finalMeetingForm.endtime
          ),
          this.formatTimeToShow,
          'en-US'
        ).toString(),
        answertype: this.finalMeetingForm.answertype,
        showcount: this.finalMeetingForm.showcount,
        showresult: this.finalMeetingForm.showresult,
        shownamelist: this.finalMeetingForm.shownamelist,
        mandatoryresp: this.finalMeetingForm.mandatoryresp,
        maxoption: this.finalMeetingForm.maxoption,
        noti: this.finalMeetingForm.noti,
        reminder: this.finalMeetingForm.reminder,
        daterange: this.finalMeetingForm.daterange,
        voting: this.finalMeetingForm.voting,
        ics: this.finalMeetingForm.ics,
        weekly: this.finalMeetingForm.weekly,
        comment: this.finalMeetingForm.comment,
        videourl: this.finalMeetingForm.videourl,
      };
    }
  }

  removeVideoLink() {
    this.finalMeetingForm.eventtype = '0';
    this.finalMeetingForm.videourl = '';
    this.meetingForm.eventtype = '0';
    this.meetingForm.videourl = '';
  }

  removeMeetingLink() {
    this.confirmCustomAnswers = [];
    this.meetingLinkAdded = false;
    this.meetingForm = {
      eventtype: '0',
      description: '',
      link: '',
      venue: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttime: this.currentStartTime,
      endtime: this.currentEndTime,
      answertype: '1',
      showcount: false,
      showresult: false,
      shownamelist: false,
      mandatoryresp: false,
      maxoption: '1',
      noti: false,
      reminder: false,
      daterange: false,
      voting: 'true',
      ics: false,
      weekly: false,
      comment: true,
      videourl: '',
    };
    this.finalMeetingForm = {
      eventtype: '0',
      link: '',
      description: '',
      venue: '',
      startdate: '',
      enddate: '',
      starttime: '',
      endtime: '',
      answertype: '',
      showcount: false,
      showresult: false,
      shownamelist: false,
      mandatoryresp: false,
      maxoption: '1',
      noti: false,
      reminder: false,
      daterange: false,
      voting: 'true',
      ics: false,
      weekly: false,
      comment: true,
      videourl: '',
    };
  }

  eventTypeChanged(event: any) {
    this.meetingForm = {
      eventtype: event.target.value,
      description: this.meetingForm.description,
      link: this.meetingForm.link,
      venue: this.meetingForm.venue,
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttime: this.currentStartTime,
      endtime: this.currentEndTime,
      answertype: '1',
      showcount: false,
      showresult: false,
      shownamelist: false,
      mandatoryresp: false,
      maxoption: '1',
      noti: event.target.value != '0' && event.target.value != '5',
      reminder: false,
      daterange: false,
      voting: 'true',
      ics: this.meetingForm.ics,
      weekly: this.meetingForm.weekly,
      comment: this.meetingForm.comment,
      videourl: this.meetingForm.videourl,
    };
    this.chooseDefaultAnswers = this.defaultAnswers;
  }

  openDefaultAnswer() {
    this.defaultAnswer = true;
    this.chooseDefaultAnswers = JSON.parse(
      JSON.stringify(this.finalChooseDefaultAnswers)
    );
    this.defaultAnswers.map((item: any) => {
      this.chooseDefaultAnswers.map((citem: any) => {
        if (citem.code == item.code) {
          item.checked = true;
        }
      });
    });
  }

  openCustomAnswer() {
    this.customAnswer = true;
    this.cusFieldCount = 99;
    this.customFields = [];
    this.maxOptionCount = [];
    this.customFields = JSON.parse(JSON.stringify(this.customFieldsOrigin));
    if (this.customFieldsOrigin.length == 0) {
      this.addCusField();
    } else {
      for (let i = 0; i < this.customFields.length; i++) {
        this.maxOptionCount.push(i + 1);
      }
    }
  }

  closeDefaultAnswer() {
    this.defaultAnswer = false;
    this.chooseDefaultAnswers = [];
    this.defaultAnswers.map((item: any) => {
      item.checked = false;
    });
  }

  closeCustomAnswer() {
    this.customAnswer = false;
    this.customFieldsOrigin = JSON.parse(
      JSON.stringify(this.customFieldsFinal)
    );
    this.maxOption = this.maxOptionFinal;
  }

  addToDefaultAns(item: any) {
    item.checked = !item.checked;
    if (item.checked) {
      this.chooseDefaultAnswers.push(item);
    } else {
      this.removeDefaultAns(item);
    }
  }

  removeDefaultAns(item: any) {
    let index = this.chooseDefaultAnswers.findIndex(
      (data: { code: any }) => data.code === item.code
    );
    this.chooseDefaultAnswers.splice(index, 1);
    item.checked = false;
  }

  finishDefaultAnswer() {
    if (this.chooseDefaultAnswers.length < 2) {
      this.messageService.openSnackBar(
        'Please select at least two answers!',
        'warn'
      );
      return;
    }
    this.defaultAnswer = false;
    this.finalChooseDefaultAnswers = this.chooseDefaultAnswers;
    this.chooseDefaultAnswers = [];
    this.defaultAnswers.map((item: any) => {
      item.checked = false;
    });
  }

  finishCustomAnswer() {
    console.log('custom fields');
    console.log(this.customFields);
    console.log('custom fields origin');
    console.log(this.customFieldsOrigin);

    if (
      this.maxOption == '' ||
      this.maxOption == undefined ||
      this.maxOption < 1
    ) {
      this.messageService.openSnackBar(
        'Max selection should be at least 1!',
        'warn'
      );
    } else {
      var lgh = this.customFieldsOrigin.length;
      if (lgh == 0) {
        this.cusFieldCount = 99;
      } else {
        var lastCount = this.customFieldsOrigin[lgh - 1].code;
        this.cusFieldCount = parseInt(lastCount);
      }

      var tempList = [];

      for (let i = 0; i < this.customFields.length; i++) {
        if (this.customFields[i].name.trim() != '') {
          var c = 0;
          for (let j = 0; j < this.customFieldsOrigin.length; j++) {
            // if (this.customFields[i].name == this.customFieldsOrigin[j].name) {
            //   c = 1
            // }
            if (this.customFields[i].code == this.customFieldsOrigin[j].code) {
              c = 2;
              this.customFieldsOrigin[j].name = this.customFields[i].name;
              break;
            }
          }
          if (c == 2) {
          } else if (c == 0) {
            this.cusFieldCount = this.cusFieldCount + 1;
            tempList.push({
              code: this.cusFieldCount.toString(),
              name: this.customFields[i].name,
            });
            // this.customFieldsOrigin.push({ "code": this.cusFieldCount.toString(), "name": this.customFields[i].name })
          }
        }
      }
      this.customFieldsOrigin.push.apply(this.customFieldsOrigin, tempList);
      if (this.customFieldsOrigin.length < 2) {
        this.messageService.openSnackBar(
          'Please add at least two custom answers!',
          'warn'
        );
      } else if (this.customFieldsOrigin.length < this.maxOption) {
        this.messageService.openSnackBar(
          'Max option is greater than total answers!',
          'warn'
        );
      } else {
        this.customAnswer = false;
        this.customFieldsFinal = JSON.parse(
          JSON.stringify(this.customFieldsOrigin)
        );
        this.maxOptionFinal = this.maxOption;
      }
    }
  }

  ischeckWeekly(event: any) {
    if (event.target.checked == true) {
      this.meetingForm.weekly = true;
    } else if (event.target.checked == false) {
      this.meetingForm.weekly = false;
    }
  }

  finishMeetingLink(event: any) {
    event.preventDefault();
    // if (this.meetingForm.eventtype == '0') {
    //   return;
    // } else {

    if (this.meetingForm.eventtype == '0') {
      var comment = this.meetingForm.comment;
      var noti = this.meetingForm.noti;
      this.finalMeetingForm = this.meetingForm;
      this.removeMeetingLink();
      this.finalMeetingForm.comment = comment;
      this.finalMeetingForm.noti = noti;
      this.isMeetingLink = false;
      return;
    }
    if (this.meetingForm.eventtype == '7') {
      if (this.meetingForm.videourl.trim() == '') {
        this.messageService.openSnackBar('Video URL cannot be empty!', 'warn');
        return;
      }
      if (
        (!this.meetingForm.videourl
          .trim()
          .toLocaleLowerCase()
          .startsWith('http://') &&
          !this.meetingForm.videourl
            .trim()
            .toLocaleLowerCase()
            .startsWith('https://')) ||
        this.meetingForm.videourl.trim().length < 15
      ) {
        this.messageService.openSnackBar('Please enter a valid url!', 'warn');
        return;
      }
      var comment = this.meetingForm.comment;
      var noti = this.meetingForm.noti;
      var videourl = this.meetingForm.videourl.trim();
      this.finalMeetingForm = this.meetingForm;
      this.removeMeetingLink();
      this.finalMeetingForm.eventtype = '7';
      this.finalMeetingForm.comment = comment;
      this.finalMeetingForm.noti = noti;
      this.finalMeetingForm.videourl = videourl;
      this.isMeetingLink = false;
      return;
    }
    this.meetingFormSubmitted = true;
    if (this.meetingForm.description.trim() == '') {
      this.messageService.openSnackBar('Description cannot be empty!', 'warn');
      return;
    } else if (
      (this.meetingForm.eventtype == '3' ||
        (this.meetingForm.eventtype == '4' && this.meetingForm.daterange)) &&
      this.meetingForm.startdate > this.meetingForm.enddate
    ) {
      this.messageService.openSnackBar(
        'Please select a valid end date!',
        'warn'
      );
    } else if (this.meetingForm.starttime > this.meetingForm.endtime) {
      this.messageService.openSnackBar(
        'Please select a valid end time!',
        'warn'
      );
    } else if (
      this.meetingForm.eventtype != '4' &&
      this.meetingForm.eventtype != '5' &&
      this.meetingForm.link != '' &&
      ((!this.meetingForm.link.toLocaleLowerCase().startsWith('http://') &&
        !this.meetingForm.link.toLocaleLowerCase().startsWith('https://')) ||
        this.meetingForm.link.length < 15)
    ) {
      this.messageService.openSnackBar('Please enter a valid url!', 'warn');
    } else if (
      this.meetingForm.answertype == '1' &&
      this.meetingForm.eventtype == '4' &&
      this.finalChooseDefaultAnswers.length < 2
    ) {
      this.messageService.openSnackBar(
        'Please select at least two answers!',
        'warn'
      );
    } else if (
      this.meetingForm.answertype == '2' &&
      this.meetingForm.eventtype == '4' &&
      this.customFieldsFinal.length < 2
    ) {
      this.messageService.openSnackBar(
        'Please add at least two custom answers!',
        'warn'
      );
    } else {
      this.meetingLinkAdded = true;
      this.meetingFormSubmitted = false;
      this.confirmChooseDefaultAnswers = this.finalChooseDefaultAnswers;
      this.confirmCustomAnswers = this.customFieldsFinal;
      if (
        this.meetingForm /*  */.eventtype == '1' ||
        this.meetingForm.eventtype == '6'
      ) {
        const d = new Date(this.meetingForm.startdate);
        const day = d.getDay();
        if (day == 1) {
          this.weeklyDay = 'Mon';
          this.weeklyDaylong = 'Monday';
        } else if (day == 2) {
          this.weeklyDay = 'Tue';
          this.weeklyDaylong = 'Tuesday';
        } else if (day == 3) {
          this.weeklyDay = 'Wed';
          this.weeklyDaylong = 'Wednesday';
        } else if (day == 4) {
          this.weeklyDay = 'Thu';
          this.weeklyDaylong = 'Thursday';
        } else if (day == 5) {
          this.weeklyDay = 'Fri';
          this.weeklyDaylong = 'Friday';
        } else if (day == 6) {
          this.weeklyDay = 'Sat';
          this.weeklyDaylong = 'Saturday';
        } else if (day == 7) {
          this.weeklyDay = 'Sun';
          this.weeklyDaylong = 'Sunday';
        }
      }
      this.finalMeetingForm = {
        eventtype: this.meetingForm.eventtype,
        link:
          this.meetingForm.eventtype != '4' && this.meetingForm.eventtype != '5'
            ? this.meetingForm.link
            : '',
        description: this.meetingForm.description,
        venue: this.meetingForm.eventtype != '4' ? this.meetingForm.venue : '',
        startdate: formatDate(
          new Date(this.meetingForm.startdate),
          'yyyyMMdd',
          'en-US'
        ).toString(),
        enddate: formatDate(
          new Date(this.meetingForm.enddate),
          'yyyyMMdd',
          'en-US'
        ).toString(),
        starttime: formatDate(
          new Date(
            this.meetingForm.startdate + 'T' + this.meetingForm.starttime
          ),
          'hh:mm:ss aa',
          'en-US'
        ).toString(),
        endtime: formatDate(
          new Date(this.meetingForm.startdate + 'T' + this.meetingForm.endtime),
          'hh:mm:ss aa',
          'en-US'
        ).toString(),
        answertype: this.meetingForm.answertype,
        showcount: this.meetingForm.showcount,
        showresult: this.meetingForm.showresult,
        shownamelist: this.meetingForm.shownamelist,
        mandatoryresp: this.meetingForm.mandatoryresp,
        maxoption: this.meetingForm.maxoption,
        noti: this.meetingForm.noti,
        reminder: this.meetingForm.reminder,
        daterange: this.meetingForm.daterange,
        voting: this.meetingForm.voting,
        ics: this.meetingForm.ics,
        weekly: this.weeklyDay == '' ? false : true,
        comment: this.meetingForm.comment,
        videourl: this.meetingForm.videourl,
      };

      this.isMeetingLink = false;
    }
    // }
  }

  addToPeople(item: any) {
    item.checked = !item.checked;
    if (item.checked) {
      this.choosePeoples.push(item);
    } else {
      this.removePeople(item);
    }
  }

  checkAll() {
    if (this.isCheckAll == false) {
      this.classCodeList.map((item: any) => {
        if (!item.checked) {
          item.checked = true;
          this.chooseClassCode.push(item);
        }
      });
      this.isCheckAll = true;
    } else {
      this.classCodeList.map((item: any) => {
        if (item.checked) {
          this.removeClasscode(item);
        }
      });
      this.isCheckAll = false;
    }
  }

  addToClassCode(item: any) {
    item.checked = !item.checked;
    if (item.checked) {
      this.chooseClassCode.push(item);
      if (this.chooseClassCode.length == this.classCodeList.length) {
        this.isCheckAll = true;
      }
    } else {
      this.removeClasscode(item);
    }
  }

  removeClasscode(item: any) {
    let index = this.chooseClassCode.indexOf(item);
    this.chooseClassCode.splice(index, 1);
    item.checked = false;
    this.isCheckAll = false;
  }

  removePeople(item: any) {
    let index = this.choosePeoples.indexOf(item);
    this.choosePeoples.splice(index, 1);
    item.checked = false;
  }

  clear() {
    this.searchData = '';
  }
  splitUserName(username: any) {
    return username.split(' ').slice(0, 1);
  }

  finishTagPeople() {
    this.isTagPeople = false;
    this.postAsDomain = false;
    this.postAsOrg = false;
    if (this.selectedTab.value == 0) {
      this.finalTagOrg = '';
      this.finalChoosePeople = this.choosePeoples;
    } else {
      this.cleanTag();
      this.finalChoosePeople = [];
      this.finalTagOrg = this.tagOrg;
    }
    this.finalSelectedTab = this.selectedTab;
    this.selectedTab = new FormControl(0);
    this.tagOrg = '';
    this.choosePeoples = [];
    this.finalChooseOrg = '';
    this.finalChooseOrgImage = '';
    this.finalChooseClassCode = [];
  }

  closeTagPeople() {
    this.isTagPeople = false;
    this.tagOrg = '';
    this.cleanTag();
  }

  removeTag() {
    this.cleanTag();
    this.finalChoosePeople = [];
    this.finalTagOrg = '';
  }

  removePostForOrg() {
    this.finalAudienceForm.postfororg = false;
    this.finalAudienceForm.index = this.postAsList.findIndex(
      (x: any) => x.orgid === ''
    );
    this.finalAudienceForm.postas = '';
    this.finalAudienceForm.hidenoti = true;
    this.finalChooseOrg = '';
    this.finalChooseOrgImage = '';
    this.finalChooseClassCode = [];
    this.getContactList();
  }

  removePostForGroup() {
    this.finalAudienceForm.channelid = '';
  }

  removeTagAndOrg() {
    if (this.finalChoosePeople.length == 0) {
      this.removePostForOrg();
      this.removePostForGroup();
    } else {
      this.removeTag();
    }
  }

  clickPostDomain() {
    this.postAsDomain = true;
    this.postAsOrg = false;
    this.finalChooseOrg = '';
    this.finalChooseOrgImage = '';
    this.finalChooseClassCode = [];
    this.finalAudienceForm.postfororg = false;
    this.finalAudienceForm.domain = true;
    this.finalAudienceForm.hidenoti = false;
    this.finalAudienceForm.index = this.postAsList.findIndex(
      (x: any) => x.orgid === '' && x.domain
    );
    this.finalAudienceForm.postas = '';
  }

  removeDomainPost() {
    this.postAsDomain = false;
    this.finalAudienceForm.postas = '';
    this.finalAudienceForm.postfororg = false;
    this.finalAudienceForm.domain = false;
    this.finalAudienceForm.hidenoti = true;
    this.finalAudienceForm.index = this.postAsList.findIndex(
      (x: any) => x.orgid === '' && !x.domain
    );

    console.log(this.finalAudienceForm);
  }

  cleanTag() {
    // this.choosePeoples = [];
    this.contactsList.map((data: any) => {
      this.removePeople(data);
    });
  }

  async openSelectingClassCode() {
    this.selectingClassCode = true;
    this.gettingClassCode = true;
    if (this.postAsOrg) {
      this.chooseOrg = this.finalChooseOrg;
      this.chooseClassCode = JSON.parse(
        JSON.stringify(this.finalChooseClassCode)
      );
      const wait_classcode = await this.getClassCodeForEdit();
      this.classCodeList.map((item: any) => {
        this.chooseClassCode.map((fitem: any) => {
          if (item.name == fitem.name) {
            item.checked = true;
          }
        });
      });
      if (this.classCodeList.length == this.chooseClassCode.length) {
        this.isCheckAll = true;
      }
    } else {
      this.chooseOrg = this.orgListForTag[0].orgid;
      this.getClassCode();
    }
    this.getOrgImage(this.chooseOrg);
  }

  closeSelectingClassCode() {
    this.chooseOrg = '';
    this.chooseOrgImage = '';
    this.chooseClassCode = [];
    this.selectingClassCode = false;
  }

  clickPostOrg() {
    this.selectingClassCode = false;
    this.postAsDomain = false;
    this.postAsOrg = true;
    this.finalChooseOrg = this.chooseOrg;
    this.finalChooseOrgImage = this.chooseOrgImage;
    this.finalChooseClassCode = this.chooseClassCode;
    this.chooseOrg = '';
    this.chooseOrgImage = '';
    this.chooseClassCode = [];
    this.finalAudienceForm.hidenoti = false;
  }

  cleanClassCode() {
    this.classCodeList.map((data: any) => {
      this.removeClasscode(data);
    });
  }

  getOrgImage(orgid: any) {
    const index = this.orgListForTag.findIndex(
      (item: any) => item.orgid == orgid
    );
    this.chooseOrgImage = this.orgListForTag[index].orgimageurl;
  }

  getOrgName(id: string) {
    const index = this.orgListForTag.findIndex((item: any) => item.orgid == id);
    return this.orgListForTag[index].name ? this.orgListForTag[index].name : '';
  }

  removeOrgPostAndTag() {
    if (this.finalChoosePeople.length > 0 || this.finalTagOrg != '') {
      this.removeTag();
    } else {
      this.removeOrgPost();
    }
  }

  removeOrgPost() {
    this.postAsOrg = false;
    this.finalChooseOrg = '';
    this.finalChooseOrgImage = '';
    this.finalChooseClassCode = [];
    this.finalAudienceForm.postas = '';
    this.finalAudienceForm.postfororg = false;
    this.finalAudienceForm.domain = false;
    this.finalAudienceForm.hidenoti = true;
    this.finalAudienceForm.index = this.postAsList.findIndex(
      (x: any) => x.orgid === ''
    );
    // this.removeTag();
  }

  orgChanged(event: any) {
    this.gettingClassCode = true;
    this.chooseOrg = event.target.value;
    this.isCheckAll = false;
    this.getOrgImage(this.chooseOrg);
    this.getClassCode();
  }

  getPosts(syskey: string) {
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      date: '',
      time: '',
      note: '',
      tag: [],
      imagename: [],
      type: '',
      count: 1,
      viewasadmin: '',
      postsyskey: syskey,
    };
    this.kunyekService.getPosts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.disableClose = false;
          this.saveLoading = false;
          this.dialogRef.close({ data: res.list[0] });
        } else {
          this.dialogRef.disableClose = false;
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  getClassCode() {
    this.isCheckAll = false;
    this.gettingClassCode = true;
    this.classCodeList = [];
    this.chooseClassCode = [];
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.chooseOrg,
    };
    this.kunyekService.getClassCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var temp = res.list;
          temp.map((item: any) => {
            const data = {
              name: item,
              checked: false,
            };
            this.classCodeList.push(data);
          });
          this.checkAll();
          this.gettingClassCode = false;
        } else {
          this.gettingClassCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingClassCode = false;
      }
    );
  }

  getClassCodeForEdit(first?: boolean) {
    console.log('it reached here!');
    console.log(first);
    return new Promise((resolve) => {
      this.gettingClassCode = true;
      this.classCodeList = [];
      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.tempAudienceForm.postas,
      };
      this.kunyekService.getClassCode(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            var temp = res.list;
            temp.map((item: any) => {
              const data = {
                name: item,
                checked: false,
              };
              this.classCodeList.push(data);
            });
            if (first) {
              console.log('it is first');
              this.checkAll();
            }
            this.gettingClassCode = false;
            resolve(true);
          } else {
            resolve(false);
            this.gettingClassCode = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve(false);
          this.gettingClassCode = false;
        }
      );
    });
  }

  clickMetting(url: any) {
    window.open(url, '_blank');
  }

  getPreview(meetinglink: string) {
    return new Promise((resolve) => {
      this.kunyekService.getLinkPreviewData(meetinglink).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  tabChange(event: any) {
    this.selectedTab.setValue(event);
    if (this.selectedTab.value == 0) {
      this.type = 2;
    } else {
      this.type = 3;
    }
    this.searchData = '';
  }

  removeCusField(idx: any) {
    if (this.customFields.length > 1) {
      var code = this.customFields[idx].code;
      this.customFields.splice(idx, 1);
      if (this.customFieldsOrigin.length != 0) {
        for (var i = 0; i < this.customFieldsOrigin.length; i++) {
          if (code == this.customFieldsOrigin[i].code) {
            this.customFieldsOrigin.splice(i, 1);
          }
        }
      }
      this.maxOptionCount.pop();
    }
  }
  addCusField() {
    this.customFields.push({ name: '' });
    this.maxOptionCount.push(this.customFields.length);
  }

  eventName() {
    const index = this.eventTypeList.findIndex(
      (item: any) => item.id == this.finalMeetingForm.eventtype
    );
    return this.eventTypeList[index].name ? this.eventTypeList[index].name : '';
  }

  async openAudience() {
    this.isAudience = true;
    this.tempAudienceForm = JSON.parse(JSON.stringify(this.finalAudienceForm));
    this.chooseOrg = this.finalChooseOrg;
    // if (this.postAsDomain){

    // }
    if (this.postAsOrg || this.tempAudienceForm.postfororg == true) {
      this.chooseClassCode = JSON.parse(
        JSON.stringify(this.finalChooseClassCode)
      );
      const wait_classcode = await this.getClassCodeForEdit();
      this.classCodeList.map((item: any) => {
        this.chooseClassCode.map((fitem: any) => {
          if (item.name == fitem.name) {
            item.checked = true;
          }
        });
      });
      if (this.classCodeList.length == this.chooseClassCode.length) {
        this.isCheckAll = true;
      }
    }
    if (this.tempAudienceForm.tag == 'people') {
      this.finalChoosePeople.map((data: any) => {
        data.checked = true;
        this.choosePeoples.push(data);
      });
    }
    this.isFirstTime = true;
    this.currentStart = 0;
    this.currentEnd = 20;
    this.getContactList();
  }

  closeAudience() {
    this.isAudience = false;
    this.tempAudienceForm = {
      postsettingindex: 0,
      postsettingindexorg: 0,
      postas: '',
      tag: '',
      postfororg: false,
      index: 0,
      domain: false,
      channelid: '',
      hidenoti: false,
    };
    this.tagOrg = '';
    this.choosePeoples = [];
    this.chooseClassCode = [];
  }

  finishAudience() {
    this.isAudience = false;
    this.firstTimeAudience = false;
    this.finalAudienceForm = JSON.parse(JSON.stringify(this.tempAudienceForm));
    this.chooseOrg = this.finalAudienceForm.postas;
    this.finalChooseOrg = this.chooseOrg;
    if (this.finalAudienceForm.postas) {
      this.getOrgImage(this.chooseOrg);
      this.finalChooseOrgImage = this.chooseOrgImage;
      this.finalChooseClassCode = this.chooseClassCode;
      this.chooseOrg = '';
      this.chooseOrgImage = '';
      this.chooseClassCode = [];
      this.postAsOrg = this.tempAudienceForm.postfororg ? false : true;
    } else {
      if (this.finalAudienceForm.domain) {
        this.clickPostDomain();
      } else {
        this.removeDomainPost();
        this.removeOrgPost();
      }
    }
    if (this.finalAudienceForm.tag == 'people') {
      this.finalTagOrg = '';
      this.finalChoosePeople = this.choosePeoples;
    } else {
      this.cleanTag();
      this.finalChoosePeople = [];
      this.finalTagOrg = this.tagOrg;
    }
    if (
      (this.finalAudienceForm.postas &&
        this.finalAudienceForm.postsettingindexorg == 3) ||
      (!this.finalAudienceForm.postas &&
        this.finalAudienceForm.postsettingindex == 3) ||
      this.finalAudienceForm.domain
    ) {
      this.finalChoosePeople = [];
      this.finalTagOrg = '';
    }
    this.tagOrg = '';
    this.choosePeoples = [];
    this.groupname =
      this.groupList.find(
        (x: any) => x.channelid == this.finalAudienceForm.channelid
      )?.channelname || '';
    this.closeAudience();
  }

  postAsGroupChange(event: any) {
    this.tempAudienceForm.channelid = event.target.value;
  }

  postAsTypeChange(event: any) {
    const index = event.target.value;
    // this.tempAudienceForm.index = index;
    this.tempAudienceForm.domain = this.postAsList[index].domain;
    this.tempAudienceForm.postas = this.postAsList[index].orgid;
    this.tempAudienceForm.postfororg = this.postAsList[index].orgtag;
    if (this.tempAudienceForm.postfororg) {
      this.tempAudienceForm.postsettingindexorg = 1;
    }
    this.chooseOrg = this.tempAudienceForm.postas;
    if (this.tempAudienceForm.postas) {
      this.getClassCode();
      const isAdmin =
        this.orgListForTag.find(
          (x: any) => x.orgid == this.tempAudienceForm.postas
        )?.admin == 'true';
      console.log(isAdmin);
      this.tempAudienceForm.hidenoti = !isAdmin;
    } else {
      this.tempAudienceForm.hidenoti = !this.tempAudienceForm.domain;
    }
    this.finalMeetingForm.noti =
      this.finalMeetingForm.eventtype != '0' &&
      this.finalMeetingForm.eventtype != '1';
    this.isFirstTime = true;
    this.currentStart = 0;
    this.currentEnd = 20;
    this.choosePeoples = [];
    this.getContactList();
  }

  orgPostSettingTypeChanged(event: any) {
    this.tempAudienceForm.postsettingindex = event.target.value;
  }
}
