<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">Filter</h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="row">
  <!-- User ID Search -->
  <div class="col-md-4 mb-3">
    <label for="userid" class="form-label">User ID</label>
    <input
      type="text"
      class="form-control"
      id="userid"
      [(ngModel)]="filters.userid"
      placeholder="Enter User ID"
    />
  </div>

  <!-- Username Search -->
  <div class="col-md-4 mb-3">
    <label for="username" class="form-label">Username</label>
    <input
      type="text"
      class="form-control"
      id="username"
      [(ngModel)]="filters.username"
      placeholder="Enter Username"
    />
  </div>

  <!-- Employee ID Search -->
  <div class="col-md-4 mb-3">
    <label for="employeeid" class="form-label">Employee ID</label>
    <input
      type="text"
      class="form-control"
      id="employeeid"
      [(ngModel)]="filters.employeeid"
      placeholder="Enter Employee ID"
    />
  </div>

  <!-- Post Dropdown -->
  <div class="col-md-4 mb-3">
    <label for="post" class="form-label">Post</label>
    <select
      class="form-select"
      id="post"
      [(ngModel)]="filters.post"
    >
      <option value="" disabled>Select Post</option>
      <option *ngFor="let post of posts" [value]="post">{{ post }}</option>
    </select>
  </div>

  <!-- Department Dropdown -->
  <div class="col-md-4 mb-3">
    <label for="department" class="form-label">Department</label>
    <select
      class="form-select"
      id="department"
      [(ngModel)]="filters.department"
    >
      <option value="" disabled>Select Department</option>
      <option *ngFor="let department of departments" [value]="department">
        {{ department }}
      </option>
    </select>
  </div>

  <!-- Division Dropdown -->
  <div class="col-md-4 mb-3">
    <label for="division" class="form-label">Division</label>
    <select
      class="form-select"
      id="division"
      [(ngModel)]="filters.division"
    >
      <option value="" disabled>Select Division</option>
      <option *ngFor="let division of divisions" [value]="division">
        {{ division }}
      </option>
    </select>
  </div>
</div>

<!-- Buttons Section -->
<div class="d-flex justify-content-end mt-4">
  <button
    class="btn btn-secondary me-2"
    type="button"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="onSearch()"
  >
    Search
  </button>
</div>