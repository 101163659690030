<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h2 class="mb-0">Employee</h2>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
  <div
    class="input-group"
    style="width: 300px !important"
    *ngIf="!isMobileView"
  >
    <input
      class="form-control"
      type="text"
      placeholder="&#xf002; Search by ID/Name"
      aria-label="Search by Name/Description"
      (focus)="isFocus = true"
      (blur)="isFocus = false"
      [(ngModel)]="searchText"
      (ngModelChange)="searchTextChange()"
      [ngClass]="{ 'c-search-input-with-clear': searchText }"
      style="font-family: FontAwesome, Ubuntu"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      *ngIf="searchText"
      (click)="clear()"
      [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
    >
      <mat-icon class="c-icon">close</mat-icon>
    </button>
  </div>
  <div class="ms-2">
    <button class="btn btn-custom" title="Filter" style="height: 32px" (click)="filter()">
      <i class="fa fa-filter"></i>
    </button>
  </div>
  <div class="line"></div>
  <div class="d-flex align-items-center">
    <span class="total-count-text">Total:&nbsp;</span>
    <span class="total-count-num">{{
      (filteredMemberlist | filter : searchText : "memberList").length
    }}</span>
  </div>

  <div class="input-group mt-2" *ngIf="isMobileView">
    <input
      class="form-control"
      type="text"
      placeholder="&#xf002; Search by Name/Description"
      aria-label="Search"
      (focus)="isFocus = true"
      (blur)="isFocus = false"
      [(ngModel)]="searchText"
      [ngClass]="{ 'c-search-input-with-clear': searchText }"
      style="font-family: FontAwesome, Ubuntu"
      (ngModelChange)="searchTextChange()"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      *ngIf="searchText"
      (click)="clear()"
      [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
    >
      <mat-icon class="c-icon">close</mat-icon>
    </button>
  </div>

  <pagination-controls
    class="ms-auto paginate"
    (pageChange)="currentPage = $event"
    *ngIf="filteredMemberlist.length > itemPerPage"
    previousLabel="Prev"
    nextLabel="Next"
  >
  </pagination-controls>
</div>
<div
  class="table-responsive"
  matSort
  (matSortChange)="sortData($event)"
  style="height: 401px"
>
  <table class="table table-responsive table-borderless">
    <thead class="table-header">
      <tr>
        <td scope="col">
          <div class="form-check">
            <input
              class="form-check-input td-edit"
              type="checkbox"
              id="primary"
              [(ngModel)]="currentPageAllSelected"
              (ngModelChange)="currentPageSelectAll()"
            />
          </div>
        </td>
        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">
          User ID
        </td>
        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">
          User Name
        </td>
        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">
          Employee ID
        </td>
        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="post">
          Post
        </td>
        <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="department">
          Department
        </td>
        <td scope="col" class="col-lg-2 col-md-1" mat-sort-header="division">
          Division
        </td>
        <!-- <td scope="col" class="d-flex justify-content-center">Action</td> -->
      </tr>
    </thead>

    <tbody *ngIf="memberListLoading">
      <tr>
        <td colspan="7" class="nodata">
          <div class="spinner-border" entitle="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="!memberListLoading">
      <tr *ngIf="filteredMemberlist.length == 0">
        <td colspan="7" class="nodata">Empty</td>
      </tr>

      <tr
        *ngFor="
          let member of filteredMemberlist
            | filter : searchText : 'memberList'
            | paginate
              : { itemsPerPage: itemPerPage, currentPage: currentPage };
          let index = index
        "
      >
        <!-- <td style="vertical-align: top;
      padding-top: 10px !important;">
          <div class="form-check">
            <input class="form-check-input td-edit" type="checkbox" id="primary" [(ngModel)]="member.isChecked"
              (ngModelChange)="selectMember(member)" />
          </div>
        </td> -->
        <td style="vertical-align: top; padding-top: 3px !important">
          <div class="form-check">
            <input
              class="form-check-input td-edit"
              type="checkbox"
              id="primary"
              [(ngModel)]="member.isChecked"
              (ngModelChange)="selectMember(member)"
            />
          </div>
        </td>
        <td class="td-data">
          <!-- <span [title]="member.userid">{{ member.userid }}</span> -->
          <div
            [title]="member.userid"
            class="d-flex flex-column"
            style="padding-bottom: 10px"
          >
            <span class="data-overflow">{{ member.userid }}</span>
            <span class="data-overflow" *ngIf="member.newuserid">{{
              member.newuserid
            }}</span>
            <!-- <span class="data-overflow">htetwaiyan@my.people.eduhtetwaiyan@my.people.edu</span> -->
            <!-- <span  class="data-overflow" *ngIf="member.userid == 'hw.yan@mit.com.mm'">htetwaiyan@my.people.eduhtetwaiyan@my.people.edu</span> -->
          </div>
        </td>
        <td class="td-data">
          <span [title]="member.username">{{ member.username }}</span>
        </td>
        <td class="td-data td-edit">
          <span [title]="member.employeeid">{{ member.employeeid }}</span>
        </td>
        <td class="td-data">
          <span [title]="member.type">{{ member.type }}</span>
        </td>
        <td class="td-data">
          <span [title]="member.department">{{ member.department }}</span>
        </td>
        <td class="td-data">
          <span [title]="member.division">{{ member.division }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex align-items-center justify-content-end">
  <!-- <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading"> -->
  <button type="button" class="btn btn-custom me-2 mb-3" mat-dialog-close>
    Cancel
  </button>
  <app-button-loader
    class="mb-3"
    (click)="addEmployee()"
    [button_text]="'Save'"
    [load_button_text]="'Saving'"
    [isLoading]="saveLoading"
  >
  </app-button-loader>
</div>
