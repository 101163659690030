import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { data } from 'jquery';
import { formatDate } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import * as S3 from 'aws-sdk/clients/s3';
import * as XLSX from 'xlsx';
import imageCompression from 'browser-image-compression';
import { environment } from '../../../environments/environment';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ImageProcessingService } from './image-processing-service.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as ExcelJS from 'exceljs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import ImageViewer from 'awesome-image-viewer';
import { env } from 'process';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AllInOneService {
  input_err_msg = 'This field may not be blank';
  unauthorizedAccessMessage =
    'You are not authorized to perform this operation.';
  unauthorizedViewMessage = 'You are not authorized to view.';
  crmdateformat = 'dd/MM/yyyy';

  allowedFileSize: number = 1024 * 1024 * 5;
  allowedFileType: string[] = [
    'docx',
    'pdf',
    'xlsx',
    'pptx',
    'txt',
    'png',
    'jpg',
    'jpeg',
  ];

  reportSocketTimeoutMins = 10;

  // 001 = kunyek, 002 = team
  appView = '001'; // connect 365
  // appView = '002'; // neo teams
  ///  test

  expandSideBar: boolean = true;

  menus: any;
  behaviourMenu = new BehaviorSubject<any>([]);
  domains: any = [];
  orgs: any = [];
  admins: any = [];
  contributorOrgs: any = [];
  hrOrgs: any = [];
  tranOrgs: any = [];
  postOrgs: any = [];
  officePermissionList: any = [];
  officePolicies: any = [];
  hrPolicies: any = [];
  requisitionList: any = [];

  profile: any;
  selectedOrg: any = '';
  appname: string = environment.appname;
  mobileappname: string = environment.mobileappname;
  socketurl: string = environment.socketurl;

  attendanceSocketUrl: string = environment.attendancesocketurl;

  currentUserCheckInterval: any;
  currentUserid: string = '';
  currentUserChanged: boolean = false;
  selectedHrOrg: any = '';
  selectedConOrg: any = '';
  selectedTranOrg: any = '';
  orgsCRm: any = [];
  crmOrgAdmin: string = '';
  selectedOrgCRM: any = '';
  activeroute: string = '/adminconsole';
  openedPost: any;
  admindomain: any;
  currentDomain: string = '';
  isQrModule: boolean = false;

  removeContactTracing: boolean = false;
  isLoading: boolean = false;
  isonline: boolean = true;
  navbar: boolean = true;
  sidebar: boolean = true;
  private secretKey: string = '!@#$29!@#$Gk**&*';
  isCRMServiceView: boolean = false;
  selected_orgcrm: any;
  isCustomerCare: boolean = false;
  crmBlocked: string = 'false';

  isOfficeView: boolean = false;
  isTransportionView: boolean = false;
  isAttendanceView: boolean = false;
  isCs365View: boolean = false;
  gallery: boolean = false;
  attachments: boolean = false;
  TeamsPermisson: boolean = true;
  isMemberModule: boolean = false;

  // akid: string = environment.accessKeyId;
  // skid: string = environment.secretAccessKey;
  private region: string = environment.region;
  private bucketname: string = environment.bucketname;

  bucketUrl: string = environment.bucketUrl;

  routeSnapshot: any;

  settingmenus: any[] = [];
  appmenus: any[] = [];

  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
  };

  userStatusFilterList = [
    { name: 'Active', id: '001' },
    { name: 'Inactive', id: '002' },
  ];

  constructor(
    private cookieService: CookieService,
    private imageProcessingService: ImageProcessingService,

    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    if (this.cookieService.check('atoken')) {
      this.logout();
    }
  }

  hash(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.secretKey);

    var encrypted = CryptoJS.MD5(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key
    );

    return encrypted.toString();
  }

  encrypt(value: any) {
    var key = CryptoJS.enc.Utf8.parse(this.secretKey);
    var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  decrypt(value: any) {
    if (value) {
      try {
        var key = CryptoJS.enc.Utf8.parse(this.secretKey);
        var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (e) {
        return '';
      }
    } else {
      return '';
    }
  }
  getIvs() {
    return CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  }
  generateKey(salt: any, passPhrase: any) {
    console.log('salt: :: ::: :: :: :: ', salt);
    var keySize = 128;
    return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
      keySize: keySize / 32,
      iterations: 1000,
    });
  }
  setMenus(menus: any) {
    this.behaviourMenu.next(menus);
    this.menus = menus;
  }

  getPayrollType() {
    return sessionStorage.getItem('ptype') || '';
  }

  isAdminOf(type: string) {
    return this.admins.indexOf(type) > -1;
  }

  setCookie(key: string, value: string) {
    const expiredDate = new Date();
    expiredDate.setTime(expiredDate.getTime() + 365 * 24 * 60 * 60 * 1000);
    this.cookieService.set(this.encrypt(key), this.encrypt(value), {
      expires: expiredDate,
      sameSite: 'Lax',
      path: '/',
    });
  }
  setSession(key: string, value: any) {
    sessionStorage.setItem(this.encrypt(key), this.encrypt(value));
  }

  setLoyalSession(key: string, value: any) {
    sessionStorage.setItem(this.encrypt(key), this.encrypt(value));
  }

  getLoyaltySession(key: string) {
    return this.decrypt(sessionStorage.getItem(this.encrypt(key))) || '';
  }

  setSelectedRole(value: any) {
    this.setCookie('selectedRole', value);
  }

  setSelectedOrg(value: any) {
    this.selectedOrg = value;
    this.setCookie('selectedOrg', value);
  }

  setSelectedConOrg(value: any) {
    this.selectedConOrg = value;
    this.setCookie('selectedConOrg', value);
  }

  setSelectedHrOrg(value: any) {
    this.selectedHrOrg = value;
    this.setCookie('selectedHrOrg', value);
  }

  setSelectedTranOrg(value: any) {
    this.selectedTranOrg = value;
    this.setCookie('selectedTranOrg', value);
  }

  setSelectedCRM(value: any) {
    this.setCookie('selectedCRMorg', value);
  }
  setIndex(value: any) {
    this.setCookie('index', value);
  }

  getindex() {
    var index =
      this.decrypt(this.cookieService.get(this.encrypt('index'))) || '';
    if (index == '') {
      return '';
    } else {
      return index;
    }
  }

  getSelectedCRMOrg() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedCRMorg'))) ||
      '';

    if (orgid == '') {
      return '';
    } else {
      const index = this.orgsCRm.findIndex((x: any) => x.orgid == orgid);
      if (index > -1) {
        return orgid;
      } else {
        this.setSelectedOrg('');
        return '';
      }
    }
  }

  getSelectedOrg() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedOrg'))) || '';
    if (orgid == '') {
      return '';
    } else {
      const index = this.orgs.findIndex((x: any) => x.orgid == orgid);
      if (index > -1) {
        return orgid;
      } else {
        this.setSelectedOrg('');
        return '';
      }
    }
  }

  getSelectedRole() {
    var roleid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedRole'))) || '';
    if (roleid == '') {
      if (this.officePermissionList.length > 0) {
        this.setSelectedRole(this.officePermissionList[0].roleid);
        return this.officePermissionList[0].roleid;
      } else {
        return '';
      }
    } else {
      if (this.officePermissionList.length > 0) {
        const index = this.officePermissionList.findIndex(
          (x: any) => x.roleid == roleid
        );
        if (index > -1) {
          return roleid;
        } else {
          this.setSelectedRole(this.officePermissionList[0].roleid);
          return this.officePermissionList[0].roleid;
        }
      } else {
        return '';
      }
    }
  }

  getSelectedConOrg() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedConOrg'))) ||
      '';
    if (orgid == '') {
      return '';
    } else {
      const index = this.contributorOrgs.findIndex(
        (x: any) => x.orgid == orgid
      );
      if (index > -1) {
        return orgid;
      } else {
        this.setSelectedConOrg('');
        return '';
      }
    }
  }

  getSelectedHrOrg() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedHrOrg'))) || '';
    if (orgid == '') {
      return '';
    } else {
      const index = this.hrOrgs.findIndex((x: any) => x.orgid == orgid);
      if (index > -1) {
        return orgid;
      } else {
        this.setSelectedHrOrg('');
        return '';
      }
    }
  }

  getSelectedTranOrg() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('selectedTranOrg'))) ||
      '';
    if (orgid == '') {
      return '';
    } else {
      const index = this.tranOrgs.findIndex((x: any) => x.orgid == orgid);
      if (index > -1) {
        return orgid;
      } else {
        this.setSelectedTranOrg('');
        return '';
      }
    }
  }

  getUserId() {
    return this.decrypt(this.cookieService.get(this.encrypt('userid'))) || '';
  }
  getSession() {
    return this.decrypt(sessionStorage.getItem(this.encrypt('session'))) || '';
  }

  getSessionValue(key: string) {
    try {
      return JSON.parse(
        this.decrypt(sessionStorage.getItem(this.encrypt(key)))
      );
    } catch {
      return '';
    }
  }

  getKunyekUserId() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('kunyek_userid'))) || ''
    );
  }
  getKunyekUserName() {
    return this.profile.username || '';
  }
  getAtoken() {
    return this.decrypt(this.cookieService.get(this.encrypt('atoken'))) || '';
  }
  getAppId() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('appid'))) || 'kunyek'
    );
  }
  getVotingUserid() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('voting_userid'))) || ''
    );
  }
  getVotingRoundid() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('voting_roundid'))) || ''
    );
  }
  getVotingUsername() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('voting_username'))) ||
      ''
    );
  }
  getVotingToken() {
    return (
      this.decrypt(this.cookieService.get(this.encrypt('voting_atoken'))) || ''
    );
  }
  getVotingAdminToken() {
    return (
      this.decrypt(
        this.cookieService.get(this.encrypt('voting_admin_atoken'))
      ) || ''
    );
  }
  getRole() {
    if (!this.cookieService.check(this.encrypt('role'))) {
      return 'null';
    }
    return this.decrypt(this.cookieService.get(this.encrypt('role'))) || '';
  }

  getVotingProxyList() {
    var temp =
      this.decrypt(this.cookieService.get(this.encrypt('voting_proxy'))) || '';
    if (temp == '') {
      return [];
    } else {
      return JSON.parse(temp);
    }
  }
  // getDomain() {
  //   return this.cookieService.get("domain") == "object" ? JSON.parse(this.cookieService.get("domain")) : undefined;

  // }
  // getOrganization() {
  //   return typeof this.cookieService.get("organization") == "object" ? JSON.parse(this.cookieService.get("organization")) : undefined;
  // }

  getDomain() {
    try {
      // var tempOrg: any = this.getOrganization();
      return JSON.parse(
        this.decrypt(this.cookieService.get(this.encrypt('domain')))
      );
    } catch (err) {
      return {
        appid: 'kunyek',
        description: '',
        domainid: '',
        id: '',
        shortcode: '',
      };
    }
  }
  getOrganization() {
    try {
      // return JSON.parse(this.cookieService.get("organization"));
      return JSON.parse(
        this.decrypt(this.cookieService.get(this.encrypt('organization')))
      );
    } catch (err) {
      return {
        appid: 'kunyek',
        createdby: '',
        createddate: '',
        description: '',
        domainid: '',
        name: '',
        orgid: '',
      };
    }
  }

  getCustomer() {
    try {
      return JSON.parse(
        this.decrypt(this.cookieService.get(this.encrypt('customercrm')))
      );
    } catch (err) {
      return '';
    }
  }

  getOrganizations() {
    try {
      // return JSON.parse(this.cookieService.get("organizations"));
      return JSON.parse(
        this.decrypt(this.cookieService.get(this.encrypt('organizations')))
      );
    } catch (err) {
      return {
        appid: 'kunyek',
        createdby: '',
        createddate: '',
        description: '',
        domainid: '',
        name: '',
        orgid: '',
      };
    }
  }
  getProfileImage() {
    return this.profile ? this.profile.imagename : '';
  }

  getProfile() {
    return this.profile;
  }

  checkOrgIsInAdminOrgList(orgid: string) {
    var res = false;
    this.orgs.map((item: any) => {
      if (item.orgid == orgid) {
        res = true;
      }
    });
    return res;
  }

  checkOrgIsInPostAdminOrgList(orgid: string) {
    var res = false;
    this.postOrgs.map((item: any) => {
      if (item.orgid == orgid) {
        res = true;
      }
    });
    return res;
  }

  checkDomainIsInAdminDomainList(shortcode: string) {
    var res = false;
    if (this.admindomain.shortcode == shortcode) {
      res = true;
    }
    return res;
  }

  checkToken() {}

  formatEventDatetime(date: string) {
    const format = 'dd/MM/yyyy hh:mm aa';
    return formatDate(new Date(date), format, 'en-US').toString();
  }

  checkDate(date: string) {
    // 2021-07-20
    if (date && date != '') {
      var temp = date.split('-');
      if (temp.length > 0 && temp[0].length == 4) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  parseDate(date: Date | string, format: string = 'yyyyMMdd') {
    try {
      return formatDate(date, format, 'en-US').toString();
    } catch {
      return '';
    }
  }

  parseDateToShowInEdit(date: string) {
    // 20210729
    if (date) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      const formattedDate = month + '/' + day + '/' + year;
      const newDate = new Date(formattedDate);

      return newDate;
    } else {
      return '';
    }
  }

  formatDate(date: string) {
    // 2021-07-20
    if (date != undefined) {
      return date.split('-').join('');
    } else {
      return '';
    }
  }

  formatCorrectDate(date: string) {
    // 2024-06-28 / 28-06-2024
    if (!date) return;
    let parts = date.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }

  convertDate(
    inputDate: string | { date: { year: number; month: number; day: number } }
  ): string {
    if (!inputDate) {
      return '';
    }

    if (typeof inputDate === 'string') {
      // Format "12/07/2024"
      const [day, month, year] = inputDate.split('/');
      return `${year}${month}${day}`;
    }

    if (typeof inputDate === 'object' && inputDate.date) {
      // Format "{date: {year: 2024, month: 07, day: 12}}"
      const { year, month, day } = inputDate.date;
      const monthStr = month < 10 ? `0${month}` : `${month}`;
      const dayStr = day < 10 ? `0${day}` : `${day}`;
      return `${year}${monthStr}${dayStr}`;
    }

    return '';
  }

  convertDateObjtoDate(m: any) {
    if (m != null) {
      var returnDate =
        m.date.year +
        ('0' + m.date.month).slice(-2) +
        ('0' + m.date.day).slice(-2);
      return returnDate;
    } else {
      return null;
    }
  }

  changeStringToDateobject(dt: any) {
    if (dt && dt != '1900-01-01 00:00:00.0') {
      return {
        date: {
          year: Number(dt.slice(0, 4)),
          month: Number(dt.slice(4, 6)),
          day: Number(dt.slice(6, 8)),
        },
      };
    } else {
      return null;
    }
  }

  formatImportDate(date: string) {
    // 20/07/2021
    if (date != undefined) {
      var temp = date.split('/');
      return temp[2] + temp[1] + temp[0];
    } else {
      return '';
    }
  }

  formatDBToShow(date: string) {
    // 20210729
    if (date) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  formatDBToShowMY(date: string) {
    // 20210729
    if (date) {
      // const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + '/' + month;
    } else {
      return '';
    }
  }

  formatMonthYear(date: string, spliter?: string) {
    // 20210729
    if (data != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);

      if (spliter == undefined) {
        return year + '-' + month;
      } else {
        return year + spliter + month;
      }
    } else {
      return '';
    }
  }

  formatDBToShowInEdit(date: string, spliter?: string) {
    // 20210729
    if (data != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);

      if (spliter == undefined) {
        return year + '-' + month + '-' + day;
      } else {
        return year + spliter + month + spliter + day;
      }
    } else {
      return '';
    }
  }

  formatDateForInOutReportDate(date: string) {
    if (data != undefined) {
      const day = date.substring(0, 2);
      const month = date.substring(3, 5);
      const year = date.substring(6, 10);
      return year + '/' + month + '/' + day;
    } else {
      return '';
    }
  }

  formatTimeAmpm(time: String) {
    if (time.toUpperCase() == 'AM' || time.toUpperCase() == 'PM') {
      return time;
    }
    const timeParts = time.split(':');
    const hours24 = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    const date = new Date();
    date.setHours(hours24, minutes);

    const timeString12 = date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
    });
    return timeString12;
  }

  formatTimeToShow(time: string) {
    // 03:57:14 PM
    var hour = time.substring(0, 2);
    if (hour.startsWith('0')) {
      hour = hour.substring(1);
    }

    const minute = time.substring(2, 5);
    const ampm = time.substring(9, 11);
    return hour + minute + ' ' + ampm;
  }

  getCurrentDateToDB() {
    var now = new Date(Date.now());
    var year = now.getFullYear();
    var month: any = now.getMonth() + 1;
    month = month.toString().length == 1 ? 0 + '' + month : month;
    var day: any = now.getDate();
    day = day.toString().length == 1 ? 0 + '' + day : day;

    var currentdate = year + '' + month + '' + day;

    return currentdate;
  }

  getCurrentTimeToDB2() {
    var now = new Date(Date.now());
    var hours: any = now.getHours();
    var minutes: any = now.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().length == 1 ? 0 + '' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var second: any = now.getSeconds();
    second = second < 10 ? '0' + second : second;
    var currentdate = hours + minutes + second;
    return currentdate;
  }

  getCurrentTimeToDB() {
    // 03:57:14 PM

    var now = new Date(Date.now());
    var hours: any = now.getHours();

    var minutes: any = now.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().length == 1 ? 0 + '' + hours : hours;

    minutes = minutes < 10 ? '0' + minutes : minutes;
    var second: any = now.getSeconds();
    second = second < 10 ? '0' + second : second;

    var currentdate = hours + ':' + minutes + ':' + second + ' ' + ampm;

    return currentdate;
  }

  generateID() {
    var now = new Date(Date.now());
    var hours: any = now.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().length == 1 ? 0 + '' + hours : hours;
    hours = (Number(hours) + 12).toString();
    var minutes: any = now.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var second: any = now.getSeconds();
    second = second < 10 ? '0' + second : second;

    var time = hours + '' + minutes + '' + second;
    return this.getCurrentDateToDB() + time;
  }

  finishVoting(vrid: any) {
    this.cookieService.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl(`/voting/${vrid}`);
  }

  logout(next?: string) {
    this.currentUserChanged = true;
    this.TeamsPermisson = true;
    clearInterval(this.currentUserCheckInterval);
    this.cookieService.deleteAll('/');
    localStorage.clear();
    sessionStorage.clear();
    var nextroute = next
      ? next
      : this.router.url.toString().replace('/', ',').slice(1);
    if (this.isQrModule) {
      this.router.navigateByUrl(`/login?next=${nextroute}`);
    } else {
      this.router.navigateByUrl(`/qrlogin?next=${nextroute}`);
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  validatePhone(phone: string) {
    if (/^\+?\d+$/.test(phone)) {
      return true;
    }
    return false;
  }

  isEmail(userid: string) {
    const checkmail = this.validateMail(userid);
    if (checkmail && userid.includes('@')) {
      return true;
    } else {
      return false;
    }
  }

  checkUserId(userid: string) {
    userid = userid.trim();
    userid = userid.split(' ').join('');
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (this.validatePhone(userid)) {
        if (userid.startsWith('09-')) {
          userid = '+959' + userid.slice(3, userid.length);
        } else if (userid.startsWith('09')) {
          userid = '+959' + userid.slice(2, userid.length);
        } else if (userid.startsWith('+959')) {
          userid = userid;
        } else if (userid.startsWith('959')) {
          userid = '+' + userid;
        } else {
          userid = '+959' + userid;
        }
      }
    } else {
      userid = userid.toLowerCase();
    }
    return userid;
  }

  isBase64(str: string) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    // soft without case sensitive
    if (typeof a == 'string') {
      a = a.toLowerCase();
    }
    if (typeof b == 'string') {
      b = b.toLowerCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getChatTime(date: any) {
    var year, month, day, hour, min, amorpm;
    year = date.substring(0, 4);
    month = date.substring(4, 6);
    day = date.substring(6, 8);
    hour = date.substring(8, 10);
    min = date.substring(10, 12);
    var msgDate = new Date(year, Number(month) - 1, day);
    var date2 = new Date(Date.now());
    var Time = date2.getTime() - msgDate.getTime();
    var totDays = Math.floor(Time / (1000 * 3600 * 24));
    if (totDays == 0) {
      hour = Number(hour);
      if (hour > 12) {
        hour = hour - 12;
        if (hour == 0) {
          amorpm = 'AM';
        } else {
          amorpm = 'PM';
        }
      } else {
        if (hour == 12) {
          amorpm = 'PM';
        } else {
          amorpm = 'AM';
        }
      }
      return hour.toString() + ':' + min + ' ' + amorpm;
    } else if (totDays == 1) {
      return 'Yesterday';
    } else {
      return month + '-' + day;
    }
  }

  isPhone(userid: string) {
    return userid.startsWith('+959');
  }

  isAdmin(userid: string, adminlist: any) {
    return adminlist.indexOf(userid) > -1;
  }

  replaceEnter(msg: any) {
    if (msg == '' || msg == undefined || msg == null) {
      return '';
    } else {
      let match = msg.match(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
      );
      let final = msg;
      if (match == undefined || match == null) {
        return this.replaceAll(final, '\n', '<br>');
      } else {
        match.map((url: any) => {
          final = final.replace(
            url,
            '<a href="' + url + '" target="_BLANK">' + url + '</a>'
          );
        });
        return this.replaceAll(final, '\n', '<br>');
      }
    }
  }

  replaceEnterChat(msg: any, check: boolean) {
    if (msg == '' || msg == undefined || msg == null) {
      return '';
    } else {
      let match = msg.match(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
      );
      let final = msg;
      if (match == undefined || match == null) {
        return this.replaceAll(final, '\n', '<br>');
      } else {
        match.map((url: any) => {
          if (check) {
            final = final.replace(
              url,
              "<a class = 'a-color' href=\"" +
                url +
                '" target="_BLANK">' +
                url +
                '</a>'
            );
          } else {
            final = final.replace(
              url,
              '<a href="' + url + '" target="_BLANK">' + url + '</a>'
            );
          }
        });
        return this.replaceAll(final, '\n', '<br>');
      }
    }
  }

  replaceAll(str: string, find: string, replace: string) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  getDomainDesc(shortcode: string) {
    var des = '';
    this.domains.map((item: any) => {
      if (item.shortcode == shortcode) {
        des = item.description;
      }
    });
    return des;
  }

  // fileUploadToS3(file: File, name: string) {
  //   return new Promise((resolve) => {
  //     const contentType = file.type;
  //     const bucket = new S3({
  //       accessKeyId: this.akid,
  //       secretAccessKey: this.skid,
  //       region: this.region,
  //     });
  //     const params = {
  //       Bucket: this.bucketname,
  //       Key: 'member/' + name,
  //       Body: file,
  //       ACL: 'public-read',
  //       ContentType: contentType,
  //     };

  //     bucket.upload(params, function (err: any, data: any) {
  //       if (err) {
  //         console.log('There was an error uploading your file: ', err);
  //         resolve(false);
  //       } else {
  //         console.log('Successfully uploaded file.', data);
  //         resolve(true);
  //       }
  //     });
  //   });
  // }

  //Post Image Upload
  // fileMultiUploadToS3WithLoop(imgList: any, folder: string) {
  //   return new Promise((resolve) => {
  //     const bucket = new S3({
  //       accessKeyId: this.akid,
  //       secretAccessKey: this.skid,
  //       region: this.region,
  //     });
  //     for (let i = 0; i < imgList.length; i++) {
  //       const contentType = imgList[i].file.type;
  //       var tmpType = contentType.toLowerCase();
  //       if (tmpType == 'jpg' || tmpType == 'jpeg' || tmpType == 'png') {
  //         this.imageProcessingService
  //           .removeMetadata(imgList[i].file)
  //           .subscribe((sanitizedBlob) => {
  //             imgList[i].file = new File(
  //               [sanitizedBlob],
  //               imgList[i].file.name,
  //               { type: imgList[i].file.type }
  //             );
  //             const params = {
  //               Bucket: this.bucketname,
  //               Key: 'post/' + folder + '/' + imgList[i].filename,
  //               Body: imgList[i].file,
  //               ACL: 'public-read',
  //               ContentType: contentType,
  //             };
  //             bucket.upload(params, function (err: any, data: any) {
  //               if (err) {
  //                 console.log('There was an error uploading your file: ', err);
  //                 resolve(false);
  //               } else {
  //                 console.log('Successfully uploaded file.', data);
  //                 if (imgList.length - 1 == i) {
  //                   console.log('FINAL>>>');
  //                   resolve(true);
  //                   //   // return true;
  //                 }
  //               }
  //             });
  //           });
  //       } else {
  //         const params = {
  //           Bucket: this.bucketname,
  //           Key: 'post/' + folder + '/' + imgList[i].filename,
  //           Body: imgList[i].file,
  //           ACL: 'public-read',
  //           ContentType: contentType,
  //         };
  //         bucket.upload(params, function (err: any, data: any) {
  //           if (err) {
  //             console.log('There was an error uploading your file: ', err);
  //             resolve(false);
  //           } else {
  //             console.log('Successfully uploaded file.', data);
  //             if (imgList.length - 1 == i) {
  //               console.log('FINAL>>>');
  //               resolve(true);
  //               //   // return true;
  //             }
  //           }
  //         });
  //       }
  //     }
  //   });
  // }

  // attachfileMultiUploadToS3WithLoop(imgList: any, folder: string) {
  //   return new Promise((resolve) => {
  //     const bucket = new S3({
  //       accessKeyId: this.akid,
  //       secretAccessKey: this.skid,
  //       region: this.region,
  //     });
  //     console.log('IMG>>> ' + imgList.name);

  //     const contentType = imgList.type;
  //     const params = {
  //       Bucket: this.bucketname,
  //       Key: folder + '/' + imgList.name,
  //       Body: imgList,
  //       ACL: 'public-read',
  //       ContentType: contentType,
  //     };
  //     bucket.upload(params, function (err: any, data: any) {
  //       if (err) {
  //         console.log('There was an error uploading your file: ', err);
  //         resolve(false);
  //       } else {
  //         console.log('Successfully uploaded file.', data);
  //         console.log('FINAL>>>');
  //         resolve(true);
  //         //   // return true;
  //       }
  //     });
  //   });
  // }

  attachfileMultiUploadToS3WithLoop(
    bucketKey: string,
    file: File,
    fileType: string
  ): Observable<boolean> {
    const url = environment.domain + 'auth/' + 'test3';
    const atoken = this.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = {
      bucket_name: 'kunyekbucket',
      bucket_key: bucketKey,
      filetype: fileType,
    };

    return this.http.post(url, body, options).pipe(
      switchMap((response: any) => {
        const presignedUrl = response?.url;
        console.log(`PresignedUrl: ${presignedUrl}`);
        if (!presignedUrl) {
          return of(false);
        }

        return this.http
          .put(presignedUrl, file, {
            headers: {
              'Content-Type': fileType,
            },
            reportProgress: false,
            observe: 'response',
          })
          .pipe(
            map((putResponse) => putResponse.status === 200),
            catchError(() => of(false))
          );
      }),
      catchError(() => of(false))
    );
  }

  uploadFileToS3WithPresignedUrl(
    bucketKey: string,
    file: File,
    fileType: string
  ): Observable<boolean> {
    const url = environment.domain + 'auth/' + 'test3';
    const atoken = this.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = {
      bucket_name: 'kunyekbucket',
      bucket_key: bucketKey,
      filetype: fileType,
    };

    return this.http.post(url, body, options).pipe(
      switchMap((response: any) => {
        const presignedUrl = response?.url;
        console.log(`PresignedUrl: ${presignedUrl}`);
        if (!presignedUrl) {
          return of(false);
        }

        return this.http
          .put(presignedUrl, file, {
            headers: {
              'Content-Type': fileType,
            },
            reportProgress: false,
            observe: 'response',
          })
          .pipe(
            map((putResponse) => putResponse.status === 200),
            catchError(() => of(false))
          );
      }),
      catchError(() => of(false))
    );
  }

  uploadFileToS3WithPresignedUrlProgress(
    bucketKey: string,
    file: File,
    fileType: string
  ): Observable<{ success: boolean; progress: number }> {
    const url = environment.domain + 'auth/' + 'test3';
    const atoken = this.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const body = {
      bucket_name: 'kunyekbucket',
      bucket_key: bucketKey,
      filetype: fileType,
    };

    return new Observable<{ success: boolean; progress: number }>(
      (observer) => {
        this.http
          .post<{ url: string }>(url, body, options)
          .pipe(
            catchError((err) => {
              observer.error({
                success: false,
                progress: 0,
                error: 'Failed to fetch presigned URL.',
              });
              return of(null);
            })
          )
          .subscribe((response) => {
            const presignedUrl = response?.url;
            console.log(`PresignedUrl: ${presignedUrl}`);
            if (!presignedUrl) {
              observer.error({
                success: false,
                progress: 0,
                error: 'No presigned URL provided.',
              });
              return;
            }

            const uploadReq = new HttpRequest('PUT', presignedUrl, file, {
              headers: new HttpHeaders({ 'Content-Type': fileType }),
              reportProgress: true,
            });

            this.http
              .request(uploadReq)
              .pipe(
                catchError((err) => {
                  observer.error({
                    success: false,
                    progress: 0,
                    error: 'File upload failed.',
                  });
                  return of(null);
                })
              )
              .subscribe({
                next: (event) => {
                  if (event?.type === HttpEventType.UploadProgress) {
                    const progress = event.total
                      ? Math.round((event.loaded / event.total) * 100)
                      : 0;
                    observer.next({ success: false, progress });
                  } else if (event?.type === HttpEventType.Response) {
                    observer.next({ success: true, progress: 100 });
                    observer.complete();
                  }
                },
                error: () => {
                  observer.error({
                    success: false,
                    progress: 0,
                    error: 'Unexpected error during file upload.',
                  });
                },
              });
          });
      }
    );
  }

  // proffileUploadToS3(file: File, name: string, path: string) {
  //   return new Promise((resolve) => {
  //     this.imageProcessingService
  //       .removeMetadata(file)
  //       .subscribe((sanitizedBlob) => {
  //         file = new File([sanitizedBlob], file.name, { type: file.type });

  //         const contentType = file.type;
  //         const bucket = new S3({
  //           accessKeyId: this.akid,
  //           secretAccessKey: this.skid,
  //           region: this.region,
  //         });
  //         const params = {
  //           Bucket: this.bucketname,
  //           Key: path + name + '/' + name,
  //           Body: file,
  //           ACL: 'public-read',
  //           ContentType: contentType,
  //         };
  //         bucket.upload(params, function (err: any, data: any) {
  //           if (err) {
  //             console.log('There was an error uploading your file: ', err);
  //             resolve(false);
  //           } else {
  //             console.log('Successfully uploaded file.', data);
  //             resolve(true);
  //           }
  //         });
  //       });
  //   });
  // }

  // groupPhotoUploadToS3(file: File, name: string) {
  //   return new Promise((resolve) => {
  //     this.imageProcessingService
  //       .removeMetadata(file)
  //       .subscribe((sanitizedBlob) => {
  //         file = new File([sanitizedBlob], file.name, { type: file.type });
  //         const contentType = file.type;
  //         const bucket = new S3({
  //           accessKeyId: this.akid,
  //           secretAccessKey: this.skid,
  //           region: this.region,
  //         });
  //         const params = {
  //           Bucket: this.bucketname,
  //           Key: name,
  //           Body: file,
  //           ACL: 'public-read',
  //           ContentType: contentType,
  //         };
  //         bucket.upload(params, function (err: any, data: any) {
  //           if (err) {
  //             console.log('There was an error uploading your file: ', err);
  //             resolve(false);
  //           } else {
  //             console.log('Successfully uploaded file.', data);
  //             resolve(true);
  //           }
  //         });
  //       });
  //   });
  // }

  //Attachments Upload to AWS S3 with Loop
  // multipleUploadToAWS(filelist: any, folder: string) {
  //   return new Promise((resolve) => {
  //     const bucket = new S3({
  //       accessKeyId: this.akid,
  //       secretAccessKey: this.skid,
  //       region: this.region,
  //     });
  //     for (let i = 0; i < filelist.length; i++) {
  //       const contentType = filelist[i].file.type;
  //       var tmpType = contentType.toLowerCase();
  //       if (tmpType == 'jpg' || tmpType == 'jpeg' || tmpType == 'png') {
  //         this.imageProcessingService
  //           .removeMetadata(filelist[i].file)
  //           .subscribe((sanitizedBlob) => {
  //             filelist[i].file = new File(
  //               [sanitizedBlob],
  //               filelist[i].file.name,
  //               { type: filelist[i].file.type },
  //             );
  //           });
  //         const params = {
  //           Bucket: this.bucketname,
  //           Key: folder + '/' + filelist[i].filename,
  //           Body: filelist[i].file,
  //           ACL: 'public-read',
  //           ContentType: contentType,
  //         };
  //         bucket.upload(params, function (err: any, data: any) {
  //           if (err) {
  //             console.log('There was an error uploading your file: ', err);
  //             resolve(false);
  //           } else {
  //             console.log('Successfully uploaded file.', data);
  //             if (filelist.length - 1 == i) {
  //               console.log('FINAL>>>');
  //               resolve(true);
  //               //   // return true;
  //             }
  //           }
  //         });
  //       } else {
  //         const params = {
  //           Bucket: this.bucketname,
  //           Key: folder + '/' + filelist[i].filename,
  //           Body: filelist[i].file,
  //           ACL: 'public-read',
  //           ContentType: contentType,
  //         };
  //         bucket.upload(params, function (err: any, data: any) {
  //           if (err) {
  //             console.log('There was an error uploading your file: ', err);
  //             resolve(false);
  //           } else {
  //             console.log('Successfully uploaded file.', data);
  //             if (filelist.length - 1 == i) {
  //               console.log('FINAL>>>');
  //               resolve(true);
  //               //   // return true;
  //             }
  //           }
  //         });
  //       }
  //     }
  //   });
  // }

  async multipleUploadToAWS(
    filelist: any[],
    folder: string
  ): Promise<boolean[]> {
    const url = `${environment.domain}auth/test3`;
    const atoken = this.getAtoken();

    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html,application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${atoken}`,
      }),
    };

    const uploadResults: boolean[] = [];

    for (const fileItem of filelist) {
      try {
        const contentType = fileItem.file.type;
        const body = {
          bucket_name: 'kunyekbucket',
          bucket_key: `${folder}/${fileItem.filename}`,
          filetype: contentType,
        };

        // Step 1: Get presigned URL
        const response: any = await this.http
          .post(url, body, options)
          .toPromise();
        const presignedUrl = response?.url;

        if (!presignedUrl) {
          uploadResults.push(false);
          continue;
        }

        // Step 2: Upload file to S3 using presigned URL
        const putResponse = await this.http
          .put(presignedUrl, fileItem.file, {
            headers: {
              'Content-Type': contentType,
            },
            reportProgress: false,
            observe: 'response',
          })
          .toPromise();

        uploadResults.push(putResponse.status === 200);
      } catch (error) {
        console.error('Upload failed:', error);
        uploadResults.push(false);
      }
    }

    return uploadResults;
  }

  addtoCalendar(post: any, createpost?: boolean) {
    const ics = require('ics');
    let alarms = [];
    alarms.push({
      action: 'audio',
      description: 'Reminder',
      trigger: { hours: 0, minutes: 5, before: true },
      repeat: 2,
      attachType: '',
      attach: '',
    });
    var year = '';
    var month = '';
    var day = '';
    var start: any;
    var end: any;
    var start2: any;
    var end2: any;
    var formatToShow = 'yyyyMMddTHHmmss';
    console.log(
      this.formatDBToShowInEdit(post.startdate) + ' ' + post.starttime
    );
    //
    start = formatDate(
      new Date(
        this.formatDBToShowInEdit(post.startdate, '/') + ' ' + post.starttime
      ),
      formatToShow,
      'en-US'
    ).toString();
    end = formatDate(
      new Date(
        this.formatDBToShowInEdit(post.startdate, '/') + ' ' + post.endtime
      ),
      formatToShow,
      'en-US'
    ).toString();
    if (post.startdate != '') {
      year = post.startdate.substring(0, 4);
      month = post.startdate.substring(4, 6);
      day = post.startdate.substring(6, 8);
      var shour = post.starttime.substring(0, 2);
      if (shour.startsWith('0')) {
        shour = shour.substring(1);
      }
      var sminute = post.starttime.substring(3, 5);
      var ehour = post.endtime.substring(0, 2);
      if (ehour.startsWith('0')) {
        ehour = ehour.substring(1);
      }
      var eminute = post.endtime.substring(3, 5);
      start2 = [
        parseInt(year),
        parseInt(month),
        parseInt(day),
        parseInt(shour),
        parseInt(sminute),
      ];
      end2 = [
        parseInt(year),
        parseInt(month),
        parseInt(day),
        parseInt(ehour),
        parseInt(eminute),
      ];
    }
    console.log(start);
    const event = {
      start: start2,
      end: end2,
      title: post.meetingdesc,
      description: post.meetinglink,
      url: createpost ? post.link : post.meetinglink,
      attendees: [],
      alarms: alarms,
    };

    ics.createEvent(event, (error: any, value: any) => {
      if (error) {
        console.log(error);
        return;
      }
      download('kunyek_event.ics', value);
      if (createpost == undefined) {
        var url =
          'https://calendar.google.com/calendar/u/0/r/eventedit?dates=' +
          start +
          '/' +
          end +
          '&details=' +
          post.meetinglink +
          '&text=' +
          post.meetingdesc;
      } else {
        var url =
          'https://calendar.google.com/calendar/u/0/r/eventedit?dates=' +
          start +
          '/' +
          end +
          '&details=' +
          post.link +
          '&text=' +
          post.meetingdesc;
      }
      window.open(url, '_blank');
    });
  }

  groupContacts(list: any) {
    const sorted = list.sort((a: any, b: any) =>
      a.username > b.username ? 1 : -1
    );

    const grouped = sorted.reduce((groups: any, contact: any) => {
      var letter = contact.username.charAt(0).toLocaleUpperCase();
      if (letter == '') {
        letter = '#';
      }

      groups[letter] = groups[letter] || [];
      groups[letter].push(contact);

      return groups;
    }, {});

    const result = Object.keys(grouped).map((key) => ({
      key,
      contacts: grouped[key],
    }));

    console.log(result);
    return result;
  }
  exportEcecl(data: any, name: string) {
    /* generate a worksheet */
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* write workbook and force a download */
    XLSX.writeFile(wb, name);
  }

  // exportExcelLatePolicy(data: any, name: string, titleCount: number) {

  //   const dataWithTitles = [
  //     ...data
  //   ];
  //   /* generate a worksheet */

  //   var ws = XLSX.utils.json_to_sheet(data);

  //   /* add to workbook */
  //   var wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* merge cells vertically for 'name' */
  //    ws['!merges'] = [
  //   { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
  //   { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
  //   { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
  //   { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
  //   { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
  //   { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
  // ];

  // var temp = []
  // var tempTitleCount = titleCount * 2
  // for (let i = 0; i < tempTitleCount; i = i + 2) {
  //   temp.push(
  //     { s: { r: 0, c: 6+i }, e: { r: 0, c: 6+i+1 } }
  //   )
  // }

  // ws['!merges'].push(...temp)

  // ws['!merges'].push(
  //   { s: { r: 0, c: 6 + (titleCount * 2) }, e: { r: 1, c: 6 + (titleCount * 2) } },
  // )

  //   /* write workbook and force a download */
  //   XLSX.writeFile(wb, name);
  // }

  exportExcelLatePolicy(
    data: any,
    name: string,
    titleCount: number,
    isLate: boolean = true,
    count: number
  ) {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = Array(26).fill({ width: 18 });

    worksheet.addRow([
      isLate
        ? 'Attendance Late Policy Report'
        : 'Attendance Early Out Policy Report',
      ,
      'Count ' + count,
    ]);
    worksheet.addRow([]);

    worksheet.getCell('A1').font = { bold: true };
    worksheet.getCell('C1').font = { bold: true };

    // Define the border style
    let borderStyle = { style: 'thin', color: { argb: '000000' } };

    // Add data and apply border style
    data.forEach((row: any, rowIndex: any) => {
      rowIndex = rowIndex + 2;
      Object.values(row).forEach((cell, cellIndex) => {
        let excelCell: any = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        excelCell.border = {
          top: borderStyle,
          left: borderStyle,
          bottom: borderStyle,
          right: borderStyle,
        };
        // Make the first two rows bold
        if (rowIndex < 4) {
          excelCell.font = { bold: true };
          excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
        }
      });
    });

    worksheet.mergeCells('A3:A4');
    worksheet.mergeCells('B3:B4');
    worksheet.mergeCells('C3:C4');
    worksheet.mergeCells('D3:D4');
    worksheet.mergeCells('E3:E4');
    worksheet.mergeCells('F3:F4');
    worksheet.mergeCells('G3:G4');

    // Merge cells
    for (let i = 0; i < titleCount * 2; i += 2) {
      worksheet.mergeCells(3, 7 + i + 1, 3, 7 + i + 2);
    }
    worksheet.mergeCells(3, 7 + titleCount * 2 + 1, 4, 7 + titleCount * 2 + 1);

    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, name);
    });
  }
  exportExcelMultipleSheets(dataArray: any, name: string) {
    var wb = XLSX.utils.book_new();

    dataArray.map((item: any) => {
      /* generate a worksheet */
      var ws = XLSX.utils.json_to_sheet(item.data);

      /* add to workbook */

      XLSX.utils.book_append_sheet(wb, ws, item.name);
    });

    /* write workbook and force a download */
    XLSX.writeFile(wb, name);
  }

  exportExeclCarDetail(data: any, name: string) {
    /* generate a worksheet */
    var ws = XLSX.utils.json_to_sheet([]);
    /* add to workbook */
    var wb = XLSX.utils.book_new();
    if (data[0]['main'].length > 0) {
      var main = data[0]['main'];
      for (let i = 0; i < main.length; i++) {
        XLSX.utils.sheet_add_aoa(ws, [[main[i]['name'], main[i]['value']]], {
          origin: i,
        });
      }
    }
    if (data[0]['data'].length > 0) {
      var datavalue = data[0]['data'];
      console.log(datavalue);
      XLSX.utils.sheet_add_json(ws, datavalue, {
        origin: data[0]['main'].length + 1,
      });
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    }
    /* write workbook and force a download */
    XLSX.writeFile(wb, name);
  }

  async compressImage(imageFile: File, maxSize?: number) {
    return new Promise(async (resolve) => {
      const options = {
        maxSizeMB: maxSize ? maxSize : 0.3,
        useWebWorker: true,
        maxWidthOrHeight: 1024,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        resolve(compressedFile);
      } catch (error) {
        resolve(false);
      }
    });
  }

  replaceLocation(
    nextroute: any,
    selfservice: any,
    customerid: any,
    ticketno: any
  ) {
    this.router.navigateByUrl(
      `?next=${nextroute},${selfservice},${customerid},${ticketno}`
    );
  }

  generateRandomID() {
    var random = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 2; i++) {
      random += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (let i = 0; i < 8; i++) {
      random += Math.floor(Math.random() * 10).toString();
    }
    return random;
  }

  getFileName(file: any) {
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName = finalName + '_' + this.generateID() + '.' + splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  getCRMpermission() {
    try {
      return JSON.parse(
        this.decrypt(this.cookieService.get(this.encrypt('getPermissionCRM')))
      );
    } catch (err) {
      return '';
    }
  }

  setWebView(value: any) {
    this.setCookie('webview', value);
  }
  getWebView() {
    var web =
      this.decrypt(this.cookieService.get(this.encrypt('webview'))) || '';
    if (web == '') {
      return '';
    } else {
      return web;
    }
  }

  setOrgMobile(value: any) {
    this.setCookie('getorgmobile', value);
  }

  getOrgMobile() {
    var orgid =
      this.decrypt(this.cookieService.get(this.encrypt('getorgmobile'))) || '';
    if (orgid == '') {
      return '';
    } else {
      return orgid;
    }
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  lengthCheck(name: any, length: any) {
    return `${name} cannot be longer than ${length} characters.`;
  }

  dateDifference(start: Date, end: Date, minutes: boolean = false) {
    if (minutes) {
      return (end.getTime() - start.getTime()) / 1000 / 60;
    } else {
      return Math.floor(
        (end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24
      );
    }
  }

  scrollTo(id: string) {
    let element = document.getElementById(id);
    element?.scrollIntoView();
  }

  formatSortIntoDateTime(date: string) {
    // sort format is 20230928112839201230 - YYYYMMDDHHMMSS, change it into YYYY-MM-DD HH:MM with am/pm
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const hour = parseInt(date.substring(8, 10));
    const minute = parseInt(date.substring(10, 12));
    const second = parseInt(date.substring(12, 14));
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour > 12 ? hour - 12 : hour;
    const hour12str = hour12 < 10 ? '0' + hour12 : hour12.toString();
    const time =
      hour12str +
      ':' +
      (minute < 10 ? '0' + minute : minute.toString()) +
      ' ' +
      ampm;
    var formatteddate = day + '-' + month + '-' + year + ' ' + time;
    return formatteddate;
  }

  format24HourTimeTo12Hour(time: string) {
    const hour = parseInt(time.substring(0, 2));
    const minute = parseInt(time.substring(3, 5));
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour > 12 ? hour - 12 : hour;
    const hour12str = hour12 < 10 ? '0' + hour12 : hour12.toString();
    const time12 =
      hour12str +
      ':' +
      (minute < 10 ? '0' + minute : minute.toString()) +
      ' ' +
      ampm;
    return time12;
  }

  format12HourTimeTo24Hour(time: string) {
    const hour = parseInt(time.substring(0, 2));
    const minute = parseInt(time.substring(3, 5));
    const ampm = time.substring(6, 8);
    const hour24 = ampm.toUpperCase() == 'PM' ? hour + 12 : hour;
    const hour24str = hour24 < 10 ? '0' + hour24 : hour24.toString();
    const time24 =
      hour24str + ':' + (minute < 10 ? '0' + minute : minute.toString());
    return time24;
  }

  f12to24WithSeconds(time12hr: string) {
    if (time12hr != undefined && time12hr != '' && time12hr != '-') {
      const [time, period] = time12hr.split(' ');
      const [hours, minutes, seconds] = time.split(':');

      let hours24 = parseInt(hours);

      if (period === 'PM' && hours24 !== 12) {
        hours24 += 12;
      } else if (period === 'AM' && hours24 === 12) {
        hours24 = 0;
      }

      const formattedHours24 = hours24.toString().padStart(2, '0');
      const formattedMinutes = minutes.padStart(2, '0');
      const formattedSeconds = seconds.padStart(2, '0');

      return `${formattedHours24}:${formattedMinutes}:${formattedSeconds}`;
    }
    return '';
  }

  generateCalendar(startDate: Date, endDate: Date): Date[][] {
    const weeks: any[][] = [];
    const firstDay = startDate;
    const lastDay = endDate;

    console.log(startDate, endDate);

    let currentDay = new Date(firstDay);
    let currentWeek: any[] = [];

    // Fill in the days of the previous month
    for (let i = 0; i < currentDay.getDay(); i++) {
      const day = new Date(currentDay);
      day.setDate(day.getDate() - i);
      const temp = {
        date: '',
        day: '',
        shift: '',
      };
      currentWeek.unshift(temp);
    }

    while (currentDay <= lastDay) {
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
      const temp = {
        date: formatDate(currentDay, 'yyyyMMdd', 'en-US'),
        day: currentDay.getDate(),
        shift: '',
      };
      currentWeek.push(temp);
      currentDay.setDate(currentDay.getDate() + 1);
    }

    // Fill in the days of the next month
    // while (currentWeek.length < 7) {
    //   currentWeek.push(new Date(currentDay));
    //   currentDay.setDate(currentDay.getDate() + 1);
    // }

    weeks.push(currentWeek);
    return weeks;
  }

  imageViewer(
    img: any,
    index: number,
    isObject: boolean = true,
    fieldName: string = 'previewurl'
  ) {
    var imgArray: any = [];
    img.map((item: any) => {
      imgArray.push({
        mainUrl: isObject ? item[fieldName] : item,
        thumbnailUrl: isObject ? item[fieldName] : item,
        description: '',
      });
    });
    const viewer = new ImageViewer({
      images: imgArray,
      currentSelected: index,
      stretchImages: true,
      isZoomable: false,
      // buttons: [
      //   {
      //     name: 'Download',
      //     iconSrc: '../../../../assets/images/download.svg',
      //     iconSize: '35px auto',
      //     onSelect: () => window.open(imgArray[index]['mainUrl'], '_blank'),
      //   },
      // ],
    });
  }

  // to change browser favicon
  changeFavicon(imageUrl: string) {
    const icon: any = document.querySelector("link[rel='icon']");
    console.log('chhc', icon);
    icon.href = imageUrl;
  }

  uploadAnswerFile(file: File, filepath: string) {
    console.log(file, 'file');
    return new Promise((resolve) => {
      const bucket = new S3({
        // accessKeyId: this.akid,
        // secretAccessKey: this.skid,
        region: this.region,
      });
      const contentType = file.type;
      console.log('type', contentType);
      // const allowimage = ["jpg", "jpeg", "png"];
      // if (allowimage.includes(contentType)) {

      // }
      // this.imageProcessingService.removeMetadata(file).subscribe((blob) => {
      //   file = new File([blob], file.name, { type: contentType });
      // });
      const params = {
        Bucket: this.bucketname,
        Key: filepath,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
      };
      console.log(params, params, 'llllllllllllllllllllllllll');
      bucket.upload(params, (err: any, data: any) => {
        if (err) {
          console.log('answer file upload fail', err);
          resolve(false);
        }
        resolve(true);
      });
      // resolve(false);
    });
  }
}

export const download = (filename: any, text: any) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  element.click();
};