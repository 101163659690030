<div class="container-fluid">
  <div class="content">
    <ng-container *ngIf="!isAdmin">
      <app-breadcrumb [s_breadcrumb]="'Check In Attendances'"
      t_breadcrumb="{{detailForm.orgname}} - {{allinoneService.formatDBToShow(detailForm.date)}}" (emit)="backToCIA()">
    </app-breadcrumb>
    </ng-container>
    <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb [s_breadcrumb]="'Check In Attendances'"
      t_breadcrumb="{{detailForm.orgname}} - {{allinoneService.formatDBToShow(detailForm.date)}}" (emit)="backToCIA()">
      </app-hcm-breadcrumb>
    </ng-container>
    <div class="mt-2 d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex align-items-center mb-3">
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </div>
      <div class="d-flex align-items-center mb-3">
        <div>
          <i class="fa fa-circle" aria-hidden="true" style="color: red;" *ngIf="detailForm.type =='out'"></i>
          <i class="fa fa-circle" aria-hidden="true" style="color: green;" *ngIf="detailForm.type =='in'"></i>
          <i class="fa fa-circle" aria-hidden="true" style="color: gray;" *ngIf="detailForm.type =='checkin'"></i>
          <span class="total-count-text" *ngIf="detailForm.type == 'in' || detailForm.type == 'out' ">&nbsp;Total Time
            {{detailForm.type == 'in' ? 'In' :'Out'}}:&nbsp;</span>
          <span *ngIf="detailForm.type == 'in' || detailForm.type == 'out' " class="total-count-num">{{tempType ==
            'timein' || tempType == 'timeout' ? detailList.length : groupedDetailList.length}}</span>

          <span class="total-count-text" *ngIf="detailForm.type == 'checkin'">
            &nbsp;Total Check In :&nbsp;</span>
          <span *ngIf="detailForm.type == 'checkin'" class="total-count-num">{{ tempType ==
            'checkin' ? detailList.length: groupedDetailList.length}}</span>

          <span class="total-count-text" *ngIf="detailForm.type == 'activity'">&nbsp;Total Activity :&nbsp;</span>
          <span *ngIf="detailForm.type == 'activity'" class="total-count-num">{{ tempType ==
            'activity' ? detailList.length: groupedDetailList.length}}</span>

          <span class="total-count-text" *ngIf="detailForm.type == 'leave'">&nbsp;Total Leave :&nbsp;</span>
          <span *ngIf="detailForm.type == 'leave'" class="total-count-num">{{ tempType ==
            'leave' ? detailList.length: groupedDetailList.length}}</span>

          <span class="total-count-text" *ngIf="detailForm.type == 'notimein'">&nbsp;Absence:&nbsp;</span> <span
            *ngIf="detailForm.type == 'notimein'" class="total-count-num">{{ tempType ==
            'notimein' ? detailList.length: groupedDetailList.length}}</span>
          <span class="total-count-text" *ngIf="detailForm.type == 'noactivity'">&nbsp;No Activity:&nbsp;</span> <span
            *ngIf="detailForm.type == 'noactivity'" class="total-count-num">{{ tempType ==
            'noactivity' ? detailList.length: groupedDetailList.length}}</span>
          <span class="total-count-text" *ngIf="detailForm.type == 'activeuser'">&nbsp;Active Users:&nbsp;</span> <span
            *ngIf="detailForm.type == 'activeuser'" class="total-count-num">{{ tempType ==
            'activeuser' ? detailList.length: groupedDetailList.length}}</span>
          &nbsp;<span style="color: gray;">&nbsp;of&nbsp;</span><span>{{totalcounts}}</span>
        </div>
        <div class="line">
        </div>
        <div class="input-group" style="width: 300px !important;">
          <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'search-input' : !searchText, 'search-input-with-clear' : searchText }"
            style="font-family: FontAwesome, Ubuntu;">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div
        *ngIf="tempType == 'usertimein' || tempType == 'usertimeout' || tempType == 'usercheckin' || tempType == 'useractivity'">
        <div class="table-responsive" matSort (matSortChange)="sortGroupData($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="branch">Branch</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="employeeid">Employee ID</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="userid">User ID</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="username">Name</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="department">Department</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="division">Division</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="teamid">Team ID</td>
                <td scope="col" class="col-lg-3 col-md-3 th-data">Date</td>
                <td scope="col" class="col-lg-3 col-md-3">{{tempType== 'useractivity' ? 'Description' : 'Location'}}
                </td>
              </tr>
            </thead>
            <tbody *ngIf="gettingDetails">
              <tr>
                <td colspan="8" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!gettingDetails">
              <tr *ngIf="groupedDetailList.length == 0">
                <td colspan="8" class="nodata">Empty</td>
              </tr>
              <ng-container
                *ngFor="let item of groupedDetailList |filter: searchText:gtype | paginate: { itemsPerPage: 100, currentPage: p };">
                <tr>
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.iolist[0].employeeid }}
                  </td>
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.userid}}</td>
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.iolist[0].username }}
                  </td>
                  
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.iolist[0].department }}
                  </td>
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.iolist[0].division }}
                  </td>
                  <td [attr.rowspan]="item.iolist.length" class="td-data">{{ item.iolist[0].teamid }}
                  </td>
                  <td class="td-data">
                    <ng-container
                      *ngIf="tempType == 'usertimein' || tempType == 'usertimeout' || tempType == 'usercheckin'; else activityDateTemplate">
                      <span
                        title="{{allinoneService.formatDBToShow(item.iolist[0].date)}} {{allinoneService.formatTimeToShow(item.iolist[0].starttime)}}">{{allinoneService.formatDBToShow(item.iolist[0].date)}}
                        {{allinoneService.formatTimeToShow(item.iolist[0].starttime)}} </span>
                    </ng-container>
                    <ng-template #activityDateTemplate>
                      <div>
                        {{allinoneService.formatDBToShow(item.iolist[0].date)}}
                      </div>
                      <div>
                        {{allinoneService.formatTimeToShow(item.iolist[0].starttime)}} - {{allinoneService.formatTimeToShow(item.iolist[0].endtime)}}
                      </div>
                    </ng-template>
                  </td>
                  <td class="td-data">
                    <ng-container *ngIf="tempType != 'useractivity'; else activityTemplate">
                      <ng-container
                        *ngIf="item.iolist[0].location != '' || (item.iolist[0].lat != '' && item.iolist[0].long != '')">
                        <a *ngIf="item.iolist[0].lat && item.iolist[0].long"
                          href="https://www.google.com/maps/search/?api=1&query={{item.iolist[0].lat}},{{item.iolist[0].long}}"
                          target="_blank" [ngClass]="{'red' : item.iolist[0].locationtype != '3'}">
                          <span class="d-flex flex-column loc">
                            <div *ngIf="item.iolist[0].location">
                              <span
                                *ngIf="item.iolist[0].locationtype == '1' || item.iolist[0].locationtype == '2' || item.iolist[0].locationtype=='3' || item.iolist[0].locationtype == '5'">@</span>
                              <span *ngIf="item.iolist[0].locationtype == '4'">#</span>
                              {{item.iolist[0].location}}
                            </div>
                            <div class="td-location">
                              {{item.iolist[0].lat}}, {{item.iolist[0].long}}
                            </div>
                          </span>
                        </a>
                        <span *ngIf="item.iolist[0].lat == '' && item.iolist[0].long == ''"
                          [ngClass]="{'red' : item.iolist[0].locationtype != '3'}">
                          <span
                            *ngIf="item.iolist[0].locationtype == '1' || item.iolist[0].locationtype == '2' || item.iolist[0].locationtype=='3' || item.iolist[0].locationtype == '5'">@</span>
                          <span *ngIf="item.iolist[0].locationtype == '4'">#</span>
                          {{item.iolist[0].location}}

                        </span>
                      </ng-container>
                      <ng-container
                        *ngIf="item.iolist[0].location == '' && item.iolist[0].lat == '' && item.iolist[0].long == ''">
                        <span class="gray">Unknown Location</span>
                      </ng-container>
                    </ng-container>
                    <ng-template #activityTemplate>
                      <span [title]="item.iolist[0].description" class="desc">{{item.iolist[0].description}} </span>
                    </ng-template>
                  </td>
                </tr>
                <ng-container *ngFor="let citem of item.iolist; let j = index">
                  <tr *ngIf="j+1 < item.iolist.length">
                    <td class="td-data">
                      <ng-container
                        *ngIf="tempType == 'usertimein' || tempType == 'usertimeout'  || tempType == 'usercheckin'; else activityDateTemplateList2">
                        <span
                          title="{{allinoneService.formatDBToShow(item.iolist[j+1].date)}} {{allinoneService.formatTimeToShow(item.iolist[j+1].starttime)}}">{{allinoneService.formatDBToShow(item.iolist[j+1].date)}}
                          {{allinoneService.formatTimeToShow(item.iolist[j+1].starttime)}} </span>
                      </ng-container>
                      <ng-template #activityDateTemplateList2>
                        <div>
                          {{allinoneService.formatDBToShow(item.iolist[j+1].date)}}
                        </div>
                        <div>
                          {{allinoneService.formatTimeToShow(item.iolist[j+1].starttime)}} - {{allinoneService.formatTimeToShow(item.iolist[j+1].endtime)}}
                        </div>
                      </ng-template>
                    </td>
                    <td class="td-data">
                      <ng-container *ngIf="tempType != 'useractivity'; else activityTemplate2">
                        <ng-container
                          *ngIf="item.iolist[j+1].location != '' || (item.iolist[j+1].lat != '' && item.iolist[j+1].long != '')">
                          <a *ngIf="item.iolist[j+1].lat && item.iolist[j+1].long"
                            href="https://www.google.com/maps/search/?api=1&query={{item.iolist[j+1].lat}},{{item.iolist[j+1].long}}"
                            target="_blank" [ngClass]="{'red' : item.iolist[j+1].locationtype != '3' }">
                            <span class="d-flex flex-column loc">
                              <div *ngIf="item.iolist[j+1].location">
                                <span
                                  *ngIf="item.iolist[j+1].locationtype == '1' || item.iolist[j+1].locationtype == '2' || item.iolist[j+1].locationtype=='3' || item.iolist[j+1].locationtype == '5'">@</span>
                                <span *ngIf="item.iolist[j+1].locationtype == '4'">#</span>
                                {{item.iolist[j+1].location}}
                              </div>
                              <div class="td-location">
                                {{item.iolist[j+1].lat}}, {{item.iolist[j+1].long}}
                              </div>
                            </span>
                          </a>
                          <span *ngIf="item.iolist[j+1].lat == '' && item.iolist[j+1].long== ''"
                            [ngClass]="{'red' : item.iolist[j+1].locationtype != '3' }">
                            <span
                              *ngIf="item.iolist[j+1].locationtype == '1' || item.iolist[j+1].locationtype == '2' || item.iolist[j+1].locationtype=='3' || item.iolist[j+1].locationtype == '5'">@</span>
                            <span *ngIf="item.iolist[j+1].locationtype == '4'">#</span>
                            {{item.iolist[j+1].location}}

                          </span>
                        </ng-container>
                        <ng-container
                          *ngIf="item.iolist[j+1].location == '' && item.iolist[j+1].lat == '' && item.iolist[j+1].long == ''">
                          <span class="gray">Unknown Location</span>
                        </ng-container>
                      </ng-container>
                      <ng-template #activityTemplate2>
                        <span [title]="item.iolist[j+1].description" class="desc">{{item.iolist[j+1].description}}
                        </span>
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>

              </ng-container>
              <!-- </tr> -->
            </tbody>
          </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="groupedDetailList.length > 100"
          previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div *ngIf="tempType == 'timein' || tempType == 'timeout' || tempType == 'checkin' || tempType=='activity' || tempType == 'useractive'">
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="branch">Branch</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="employeeid">Employee ID</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="userid">User ID</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="username">Name</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="department">Department</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="division">Divison</td>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="teamid">Team ID</td>
                <td scope="col" class="col-lg-2 col-md-2 th-data" mat-sort-header="date">Date</td>
                <td scope="col" class="col-lg-3 col-md-3">{{tempType == 'activity' ? 'Description' : 'Location'}}
                </td>

              </tr>
            </thead>
            <tbody *ngIf="gettingDetails">
              <tr>
                <td colspan="8" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!gettingDetails">
              <tr *ngIf="detailList.length == 0">
                <td colspan="8" class="nodata">Empty</td>
              </tr>
              <tr
                *ngFor="let item of detailList| filter: searchText:type | paginate: { itemsPerPage: 100, currentPage: p }; let index = index">
                <td class="td-data">
                  <span [title]="item.employeeid">{{item.employeeid}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.userid">{{item.userid}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.username">{{item.username}} </span>
                </td>
                
                <td class="td-data">
                  <span [title]="item.department">{{item.department}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.division">{{item.division}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.teamid">{{item.teamid}} </span>
                </td>


                <td class="td-data">
                  <ng-container
                    *ngIf="tempType == 'timein' || tempType == 'timeout'  || tempType == 'checkin'; else activityDateTemplate">
                    <span
                      title="{{allinoneService.formatDBToShow(item.date)}} {{allinoneService.formatTimeToShow(item.starttime)}}">{{allinoneService.formatDBToShow(item.date)}}
                      {{allinoneService.formatTimeToShow(item.starttime)}} </span>
                  </ng-container>
                  <ng-template #activityDateTemplate>
                    <div>
                      {{allinoneService.formatDBToShow(item.date)}}
                    </div>
                    <div>
                      {{allinoneService.formatTimeToShow(item.starttime)}} - {{allinoneService.formatTimeToShow(item.endtime)}}
                    </div>
                  </ng-template>
                </td>
                <td class="td-data">
                  <ng-container *ngIf="tempType != 'activity'; else activityTemplate">
                    <ng-container *ngIf="item.location != '' || (item.lat != '' && item.long != '')">
                      <a *ngIf="item.lat && item.long"
                        href="https://www.google.com/maps/search/?api=1&query={{item.lat}},{{item.long}}"
                        target="_blank" [ngClass]="{'red' : item.locationtype != '3'}">
                        <span class="d-flex flex-column loc">
                          <div *ngIf="item.location">
                            <span
                              *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                            <span *ngIf="item.locationtype == '4'">#</span>
                            {{item.location}}
                          </div>
                          <div class="td-location">
                            {{item.lat}}, {{item.long}}
                          </div>
                        </span>
                      </a>
                      <span *ngIf="item.lat == '' && item.long == ''" [ngClass]="{'red' : item.locationtype != '3'}">
                        <span
                          *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                        <span *ngIf="item.locationtype == '4'">#</span>
                        {{item.location}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="item.location == '' && item.lat == '' && item.long == ''">
                      <span class="gray">Unknown Location</span>
                    </ng-container>
                  </ng-container>
                  <ng-template #activityTemplate>
                    <span [title]="item.description" class="desc">{{item.description}} </span>
                  </ng-template>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="detailList.length > 100"
          previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div *ngIf="tempType == 'usernotimein' || tempType == 'usernoactivity' || tempType == 'userleave'">
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-1 col-md-1 th-data" mat-sort-header="branch">Branch</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="employeeid">Employee ID</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="username">Name</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="department">Department</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="division">Division</td>
                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="teamid">Team ID</td>
              </tr>
            </thead>
            <tbody *ngIf="gettingDetails">
              <tr>
                <td colspan="6" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!gettingDetails">
              <tr *ngIf="detailList.length == 0">
                <td colspan="6" class="nodata">Empty</td>
              </tr>
              <tr
                *ngFor="let item of detailList| filter: searchText:otype | paginate: { itemsPerPage: 100, currentPage: p }; let index = index">
                <td class="td-data">
                  <span [title]="item.employeeid">{{item.employeeid}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.userid">{{item.userid}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.username">{{item.username}} </span>
                </td>
                
                <td class="td-data">
                  <span [title]="item.department">{{item.department}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.divistion">{{item.division}} </span>
                </td>
                <td class="td-data">
                  <span [title]="item.teamid">{{item.teamid}} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="detailList.length > 100"
          previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>


<!-- -->