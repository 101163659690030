import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-office-travel-detail',
  templateUrl: './office-travel-detail.component.html',
  styleUrls: ['./office-travel-detail.component.scss'],
})
export class OfficeTravelDetailComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService
  ) { }

  showVehicle = '';
  showTravelMode = '';
  showTypeName = "";
  requestTypeList: any = [];


  modeList = [
    {
      id: '001',
      value: 'By Air',
    },
    {
      id: '002',
      value: 'By Road',
    },
    {
      id: '003',
      value: 'By Rail',
    },
    {
      id: '004',
      value: 'By Ship',
    },
  ];

  vehicleuseList = [
    {
      id: '001',
      value: "Office's Vehicle",
    },
    {
      id: '002',
      value: 'Public Transportation',
    },
    {
      id: '003',
      value: 'By Own Vehicle(state reason)',
    },
  ];

  getRequestType() {
    var data = {
      orgid: this.allinoneService.getSelectedOrg(),
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.kunyekService.getRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log("Requt tyep", res)
          this.requestTypeList = res.requesttypelist;
          this.getRequestTypeName();
        }
      },
      (err) => {
      }
    );
  }

  getModeOfTravel() {
    this.showTravelMode = this.data.modeoftravel
      .map((m: any) => {
        const mode = this.modeList.find((x) => x.id == m);
        console.log('mode: ', mode);
        return mode !== undefined ? mode.value : '';
      })
      .join(' , ');
  }

  getVehicle() {
    this.showVehicle = this.data.vehicleuse
      .map((v: any) => {
        const vehicle = this.vehicleuseList.find((x) => x.id == v);
        return vehicle !== undefined ? vehicle.value : '';
      })
      .join(' , ');
  }

  getRequestTypeName() {
    let type = this.requestTypeList.find((x: any) => x.requesttypeid == this.data.requesttype);
    this.showTypeName = type !== undefined ? type.requesttypename : ""
    console.log(type)
  }

  ngOnInit(): void {
    this.getRequestType();
    this.getVehicle();
    this.getModeOfTravel();
  }
}
