<div [ngClass]="{ 'mat-typography': !isPayroll() }">
  <app-navigation-bar
    *ngIf="checkedToken && allinoneService.navbar"
  ></app-navigation-bar>
  <div
    class="check-token"
    *ngIf="!checkedToken && !isError && !isChooseDomain && !checkservice"
  >
    <img
      [src]="
        allinoneService.isQrModule
          ? 'assets/images/qr360.png'
          : 'assets/images/kunyek.png'
      "
      alt=""
    />
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>

  <!--  *ngIf="!checkedToken && !isError && !isChooseDomain && isMobileView && checkservice"-->

  <div
    class="loading-container-mobile"
    *ngIf="
      !checkedToken &&
      !isError &&
      !isChooseDomain &&
      isMobileView &&
      checkservice
    "
  >
    <div class="splash-header">
      <div><img src="assets/images/kunyek.png" alt="" /></div>
      <span>Service</span>
    </div>
    <div class="load">
      <div class="snippet" data-title=".dot-flashing">
        <div class="stage">
          <div class="dot-flashing"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="check-token" *ngIf="isError && !checkedToken">
    <h3 *ngIf="!orgListBlankError" style="margin: 0">
      {{ errorMsg }}, please reload
    </h3>
    <h3 *ngIf="orgListBlankError" style="margin: 0">
      {{ orgListBlankErrorMsg }}
    </h3>
    <br />
    <button type="button" (click)="reload()" class="btn btn-outline-secondary">
      Reload
    </button>
    <span class="mx-3">or</span>
    <a (click)="login()" class="login">Login</a>
  </div>
  <app-domain
    *ngIf="isChooseDomain"
    [domains]="domains"
    (domainClick)="chooseDomain($event)"
  ></app-domain>
  <app-loading *ngIf="allinoneService.isLoading"></app-loading>
  <ng-container *ngIf="checkedToken">
    <!-- <div class="d-flex justify-content-start" [ngClass]="{
        crmscrollbarhide: router.url.startsWith('/service/service-tickets')
      }"> -->
    <div class="d-flex justify-content-start">
      <!-- <div class="side-nav"> -->
      <div
        *ngIf="
          checkedToken &&
          this.allinoneService.navbar &&
          allinoneService.TeamsPermisson
        "
      >
        <div
          [ngClass]="{
            'side-nav':
              !allinoneService.expandSideBar &&
              checkedToken &&
              allinoneService.navbar &&
              allinoneService.TeamsPermisson &&
              !allinoneService.isCs365View &&
              !allinoneService.isMemberModule,
            'side-nav-expand':
              allinoneService.expandSideBar &&
              !allinoneService.isCs365View &&
              !allinoneService.isMemberModule
          }"
        >
          <ng-container
            *ngIf="
              checkedToken &&
              allinoneService.navbar &&
              allinoneService.sidebar &&
              (allinoneService.isAttendanceView ||
                !allinoneService.isAttendanceView) &&
              !allinoneService.isTransportionView &&
              !allinoneService.isQrModule &&
              !allinoneService.isCs365View &&
              !allinoneService.isMemberModule
            "
          >
            <ng-container *ngFor="let menu of sidebarmenus">
              <ng-container *ngIf="menu.open == '1'">
                <div
                  [ngClass]="{
                    'side-nav-item': !allinoneService.expandSideBar,
                    'side-nav-item-expand': allinoneService.expandSideBar
                  }"
                  [routerLink]="menu.path"
                  (click)="sidecustomer(menu)"
                >
                  <div *ngIf="allinoneService.appView == '001'">
                    <div
                      [ngClass]="{
                        'nav-item': !allinoneService.expandSideBar,
                        'nav-item-expand': allinoneService.expandSideBar,
                        'nav-btn-select':
                          (menu.path == '/posts' && router.url == menu.path) ||
                          (menu.path == '/checkin' &&
                            router.url == menu.path) ||
                          (menu.path == '/attendance/checkin' &&
                            router.url == menu.path) ||
                          (menu.path == '/message' &&
                            router.url == menu.path) ||
                          (menu.path == '/hxm' &&
                            router.url.startsWith(menu.path)) ||
                          (menu.path == '/attendance/hxm' &&
                            router.url.startsWith(menu.path)) ||
                          (menu.path == '/sales' &&
                            (router.url.startsWith(menu.path) ||
                              router.url.startsWith('/org/projects?q=crm'))) ||
                          (menu.path == '/service' &&
                            router.url.startsWith(menu.path)) ||
                          (menu.path == '/office' &&
                            router.url.startsWith(menu.path)) ||
                          (menu.path == '/attendance/office' &&
                            router.url.startsWith(menu.path)) ||
                          ((menu.path == '/adminconsole' ||
                            menu.path == '/attendance/adminconsole') &&
                            router.url != '/posts' &&
                            router.url != '/checkin' &&
                            router.url != '/attendance/checkin' &&
                            router.url != '/message' &&
                            !router.url.startsWith('/sales') &&
                            !router.url.startsWith('/org/projects?q=crm') &&
                            !router.url.startsWith('/service') &&
                            !router.url.startsWith('/office') &&
                            !router.url.startsWith('/attendance/office') &&
                            !router.url.startsWith('/hxm') &&
                            !router.url.startsWith('/attendance/hxm'))
                      }"
                    >
                      <div class="nav-btn">
                        <svg-icon
                          [src]="menu.icon"
                          [stretch]="false"
                          [matTooltip]="menu.name"
                          matTooltipPosition="right"
                          [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                        >
                        </svg-icon>
                      </div>
                      <div
                        *ngIf="allinoneService.expandSideBar"
                        class="menu-name"
                      >
                        {{ menu.name }}
                      </div>
                    </div>
                  </div>
                  <div *ngIf="allinoneService.appView == '002'">
                    <div
                      [ngClass]="{
                        'nav-item': !allinoneService.expandSideBar,
                        'nav-item-expand': allinoneService.expandSideBar,
                        'nav-btn-select':
                          (menu.path.startsWith('/teams') &&
                            router.url.startsWith('/teams')) ||
                          (menu.path.startsWith('/timeandattendance') &&
                            router.url.startsWith('/timeandattendance')) ||
                          (menu.path.startsWith('/requestapproval') &&
                            router.url.startsWith('/requestapproval')) ||
                          (menu.path.startsWith('/employeecentral') &&
                            router.url.startsWith('/employeecentral'))
                      }"
                    >
                      <div class="nav-btn">
                        <svg-icon
                          [src]="menu.icon"
                          [stretch]="false"
                          [matTooltip]="menu.name"
                          matTooltipPosition="right"
                          [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                        >
                        </svg-icon>
                      </div>
                      <div
                        *ngIf="allinoneService.expandSideBar"
                        class="menu-name"
                      >
                        {{ menu.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- <div *ngIf="menu.path == '/office'" class="p-2">
                    <mat-divider style="border-top-color: #0000005c"></mat-divider>
                </div> -->
              <ng-container *ngIf="menu.open != '1'">
                <div
                  [ngClass]="{
                    'side-nav-item': !allinoneService.expandSideBar,
                    'side-nav-item-expand': allinoneService.expandSideBar
                  }"
                  (click)="openapp(menu.path)"
                >
                  <div class="nav-btn">
                    <svg-icon
                      [src]="menu.icon"
                      [stretch]="false"
                      [matTooltip]="menu.name"
                      matTooltipPosition="right"
                      [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                    >
                    </svg-icon>
                  </div>
                  <div *ngIf="allinoneService.expandSideBar" class="menu-name">
                    {{ menu.name }}
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- kokine side bar -->
      <div
        *ngIf="
          checkedToken &&
          this.allinoneService.navbar &&
          allinoneService.TeamsPermisson &&
          isMemberAdmin
        "
      >
        <div
          [ngClass]="{
            'side-nav':
              !allinoneService.expandSideBar &&
              checkedToken &&
              allinoneService.navbar &&
              allinoneService.TeamsPermisson &&
              !allinoneService.isCs365View &&
              allinoneService.isMemberModule,
            'side-nav-expand':
              allinoneService.expandSideBar &&
              !allinoneService.isCs365View &&
              allinoneService.isMemberModule
          }"
        >
          <ng-container
            *ngIf="
              checkedToken &&
              allinoneService.navbar &&
              allinoneService.sidebar &&
              allinoneService.isMemberModule &&
              !allinoneService.isTransportionView &&
              !allinoneService.isQrModule &&
              !allinoneService.isCs365View &&
              !allinoneService.isAttendanceView
            "
          >
            <ng-container *ngFor="let menu of adminsidebarMenuList">
              <ng-container *ngIf="menu.open == '1'">
                <div
                  [ngClass]="{
                    'side-nav-item': !allinoneService.expandSideBar,
                    'side-nav-item-expand': allinoneService.expandSideBar
                  }"
                  [routerLink]="menu.path"
                  (click)="goRoute(menu, menu.path)"
                >
                  <div *ngIf="allinoneService.appView == '001'">
                    <div
                      [ngClass]="{
                        'nav-item': !allinoneService.expandSideBar,
                        'nav-item-expand': allinoneService.expandSideBar,
                        'nav-btn-select':
                          ((router.url == '/members' ||
                            router.url.startsWith('/members/list') ||
                            router.url.startsWith('/members/register') ||
                            router.url.startsWith('/members/approval')) &&
                            menu.path == 'members') ||
                          ((router.url == '/swim' ||
                            router.url.startsWith('/members/summerswimmer') ||
                            router.url.startsWith('/members/specialswim') ||
                            router.url.startsWith('/members/templist') ||
                            router.url.startsWith('/members/guest-form') ||
                            router.url.startsWith('/members/guestlist') ||
                            router.url.startsWith('/members/batchlist')) &&
                            menu.path == 'swim') ||
                          ((router.url == '/payment' ||
                            router.url.startsWith('/members/payment') ||
                            router.url.startsWith('/members/paymentinvoice') ||
                            router.url.startsWith(
                              '/members/monthlyfeeslist'
                            )) &&
                            menu.path == 'payment') ||
                          ((router.url == '/gym' ||
                            router.url.startsWith('/members/gymlist')) &&
                            menu.path == 'gym') ||
                          ((router.url == '/leave' ||
                            router.url.startsWith('/members/leavelist')) &&
                            menu.path == 'leave')
                      }"
                    >
                      <div class="nav-btn">
                        <img src="{{ menu.icon }}" width="20px" />
                      </div>
                      <div
                        *ngIf="allinoneService.expandSideBar"
                        class="menu-name"
                      >
                        {{ menu.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- bg-white -->
      <div
        class="flex-fill"
        style="
          background-color: rgb(247 247 247 / 55%) !important;
          min-height: calc(100vh - 50px);
          height: 100%;
        "
        [ngClass]="{
          sidebar:
            allinoneService.TeamsPermisson &&
            !allinoneService.expandSideBar &&
            allinoneService.navbar &&
            allinoneService.sidebar &&
            (allinoneService.isAttendanceView ||
              !allinoneService.isAttendanceView) &&
            !allinoneService.isTransportionView &&
            !allinoneService.isQrModule &&
            !allinoneService.isCs365View &&
            !allinoneService.isMemberModule,

          'sidebar-expand':
            allinoneService.expandSideBar &&
            allinoneService.navbar &&
            allinoneService.sidebar &&
            (allinoneService.isAttendanceView ||
              !allinoneService.isAttendanceView) &&
            !allinoneService.isTransportionView &&
            !allinoneService.isQrModule &&
            !allinoneService.isCs365View &&
            !allinoneService.isMemberModule
        }"
      >
        <router-outlet
          *ngIf="checkedToken && allinoneService.TeamsPermisson"
        ></router-outlet>
        <ng-container
          *ngIf="
            checkedToken &&
            this.allinoneService.navbar &&
            !allinoneService.TeamsPermisson
          "
        >
          <app-invalid-permission></app-invalid-permission>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
