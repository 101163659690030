import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-office-workpolicy-filter',
  templateUrl: './office-workpolicy-filter.component.html',
  styleUrls: ['./office-workpolicy-filter.component.css']
})
export class OfficeWorkpolicyFilterComponent implements OnInit {

  filters = {
    userid: '',
    username: '',
    employeeid: '',
    post: '',
    department: '',
    division: ''
  };

  posts: string[] = [];
  departments: string[] = [];
  divisions: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { memberList: any[] },
  public dialogRef: MatDialogRef<OfficeWorkpolicyFilterComponent>) { }

  ngOnInit(): void {
    const { memberList } = this.data;
    console.log(memberList)

    this.posts = [...new Set(memberList.map((member) => member.post))];
    this.departments = [...new Set(memberList.map((member) => member.department))];
    this.divisions = [...new Set(memberList.map((member) => member.division))];
  }

  onSearch(): void {
    this.dialogRef.close(this.filters);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}