import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-workplicy-config',
  templateUrl: './workplicy-config.component.html',
  styleUrls: ['./workplicy-config.component.scss'],
})
export class WorkplicyConfigComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  isMobileView: boolean = false;
  isAdmin: boolean = false;
  isEditing: boolean = false;
  isAdding: boolean = false;
  isFocus: boolean = false;
  searchText: string = '';
  saveLoading: boolean = false;
  formSubmitted: boolean = false;
  orgid: string = '';
  organizations: any = [];
  getwpSubscription!: Subscription;
  subscriptions = new SubSink();

  wpConLoading: boolean = false;

  wpConList: any = [];

  wpConForm = {
    wpid: '',
    name: '',
    description: '',
    uselate: false,
    useearlyout: false,
    latetime: '0',
    earlyouttime: '0',
    default: false,
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getWpCon();
  }

  newWpConfig() {
    this.isAdding = true;
  }

  submitForm() {
    this.formSubmitted = true;
    var data = {
      wpid: this.wpConForm.wpid,
      orgid: this.orgid,
      name: this.wpConForm.name.toString().trim(),
      description: this.wpConForm.description.toString().trim(),
      callatetime: this.wpConForm.uselate,
      calearlyouttime: this.wpConForm.useearlyout,
      latetime: this.wpConForm.latetime.toString().trim(),
      earlyouttime: this.wpConForm.earlyouttime.toString().trim(),
      default: this.wpConForm.default,
    };
    console.log(data);
    if (this.wpConForm.name == '') {
      return;
    }
    if (
      this.wpConForm.uselate &&
      (this.wpConForm.latetime === '' || this.wpConForm.latetime === null || this.wpConForm.latetime === undefined)
    ) {
      return;
    }
    if (
      this.wpConForm.useearlyout &&
      (this.wpConForm.earlyouttime === '' || this.wpConForm.earlyouttime === null || this.wpConForm.latetime === undefined)
    ) {
      return;
    }
    this.addWpCon();
  }

  addWpCon() {
    this.saveLoading = true;

    if (this.wpConForm.latetime == null) {
      this.wpConForm.latetime = '0';
    }

    if (this.wpConForm.earlyouttime == null) {
      this.wpConForm.earlyouttime = '0';
    }

    var data = {
      wpid: this.wpConForm.wpid,
      orgid: this.orgid,
      name: this.wpConForm.name.toString().trim(),
      description: this.wpConForm.description.toString().trim(),
      callatetime: this.wpConForm.uselate,
      calearlyouttime: this.wpConForm.useearlyout,
      latetime: this.wpConForm.latetime.toString().trim(),
      earlyouttime: this.wpConForm.earlyouttime.toString().trim(),
      default: this.wpConForm.default,
    };
    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.editWpConfig(data)
      : this.kunyekService.addWpConfig(data);

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        console.log('add/edit res');
        console.log(res);

        if (res.returncode == '300') {
          if (this.isEditing) {
            var i = this.wpConList.findIndex(
              (x: any) => x.wpid == this.wpConList.wpid
            );
            if (i > -1) {
              this.wpConList[i].name = data.name;
              this.wpConList[i].description = data.description;
              this.wpConList[i].callatetime = data.callatetime;
              this.wpConList[i].calearlyouttime = data.calearlyouttime;
              this.wpConList[i].latetime = data.latetime;
              this.wpConList[i].earlyouttime = data.earlyouttime;
              this.wpConList[i].default = data.default;
            } else {
              this.refresh();
            }
          } else {
            var tempData = { ...data, wpid: res.wpid };
            this.wpConList.push(tempData);
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  getWpCon() {
    this.wpConLoading = true;
    const data = {
      orgid: this.orgid,
      wpid: '',
    };
    this.getwpSubscription && this.getwpSubscription.unsubscribe();
    this.getwpSubscription = this.kunyekService.getWpConfig(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.wpConList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.wpConLoading = false;
      },
      (err) => {
        this.wpConLoading = false;
      }
    );
  }

  editWpCon(data: any) {
    this.isEditing = true;
    this.isAdding = true;

    if (this.wpConForm.latetime == null) {
      this.wpConForm.latetime = '0';
    }

    if (this.wpConForm.earlyouttime == null) {
      this.wpConForm.earlyouttime = '0';
    }

    this.wpConForm = {
      wpid: data.wpid,
      name: data.name,
      description: data.description,
      uselate: data.callatetime,
      useearlyout: data.calearlyouttime,
      latetime: data.latetime,
      earlyouttime: data.earlyouttime,
      default: data.default,
    };
  }

  deleteWpCon(id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.wpConLoading = true;
        const data = {
          orgid: this.orgid,
          wpid: id,
        };
        console.log(data);
        this.kunyekService.deleteWpConfig(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.wpConList.findIndex((x: any) => x.wpid == id);
              if (i > -1) {
                this.wpConList.splice(i, 1);
              }
              if (
                Math.round(this.wpConList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.wpConLoading = false;
          },
          (err: any) => {
            this.wpConLoading = false;
          }
        );
      }
    });
  }

  refresh() {
    this.currentPage = 1;
    this.getWpCon();
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort: Sort) {
    const data = this.wpConList;
    if (!sort.active || sort.direction === '') {
      this.wpConList = data;
      return;
    }
    this.wpConList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'default':
          return this.allinoneService.compare(a.default, b.default, isAsc);
        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.wpConForm = {
      wpid: '',
      name: '',
      description: '',
      uselate: false,
      useearlyout: false,
      latetime: '0',
      earlyouttime: '0',
      default: false,
    };
  }

  onKeyPress(event: KeyboardEvent): void {
    const charCode = event.charCode || event.keyCode;
    const char = String.fromCharCode(charCode);
  
    if (!/^[0-999]$/.test(char)) {
      event.preventDefault();
    }
  }
}
