import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { NavigationBarComponent } from './admin/navigation-bar/navigation-bar.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { LocationsComponent } from './admin/locations/locations.component';
import { MembersComponent } from './admin/members/members.component';
import { EventsComponent } from './admin/events/events.component';
import { LoginComponent } from './admin/login/login.component';

import { ButtonLoaderComponent } from './shared/components/button-loader/button-loader.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';

import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyComponent } from './admin/verify/verify.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from './shared/services/message.service';
import { AllInOneService } from './shared/services/all-in-one.service';
import { KunyekService } from './shared/services/kunyek.service';
import { EventsAttendanceComponent } from './admin/events-attendance/events-attendance.component';
import { EventsDetailComponent } from './admin/events-detail/events-detail.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ContactsTracingComponent } from './admin/contacts-tracing/contacts-tracing.component';
import { TooltipDirective } from './shared/directives/tooltip.directive';
import { DomainComponent } from './admin/domain/domain.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './admin/map/map.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { LocationsNewComponent } from './admin/locations-new/locations-new.component';
import { ContactTracingDetailsComponent } from './admin/contact-tracing-details/contact-tracing-details.component';
import { QrLoginComponent } from './admin/qr-login/qr-login.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgxPanZoomModule } from 'ngx-panzoom';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChatComponent } from './admin/chat/chat.component';
import { ChatContactsComponent } from './admin/chat-contacts/chat-contacts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MatCheckboxDefaultOptions,
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { MatCardModule } from '@angular/material/card';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatInfoComponent } from './admin/chat-info/chat-info.component';
import { FilterPipe } from './shared/filter.pipe';
import { VoterFilterPipe } from './voting/voters/voter-filter.pipe';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { CreateGroupComponent } from './admin/create-group/create-group.component';
import { CheckinEventModalComponent } from './admin/checkin-event-modal/checkin-event-modal.component';
import { CheckInComponent } from './admin/check-in/check-in.component';
import { DomainsComponent } from './admin/domains/domains.component';
import { DomainMembersComponent } from './admin/domain-members/domain-members.component';
import { PopoverComponent } from './shared/components/popover/popover.component';
import { AdminsRootComponent } from './admin/admins-root/admins-root.component';
import { ServerMaintenanceComponent } from './admin/server-maintenance/server-maintenance.component';
import { EulaComponent } from './admin/eula/eula.component';
import { PrivacyPolicyComponent } from './admin/privacy-policy/privacy-policy.component';
import { AdminsComponent } from './admin/admins/admins.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { JobsComponent } from './admin/jobs/jobs.component';
import { PostsComponent } from './admin/posts/posts.component';
import { SocialPostsComponent } from './admin/social-posts/social-posts.component';

import { SocialPostsImagesComponent } from './admin/social-posts-images/social-posts-images.component';
import { SocialPostsDetailComponent } from './admin/social-posts-detail/social-posts-detail.component';
import { AttendancesComponent } from './admin/attendances/attendances.component';
import { AutosizeModule } from 'ngx-autosize';
import { BlockUserComponent } from './admin/block-user/block-user.component';
import { FilterDialogComponent } from './admin/filter-dialog/filter-dialog.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { LikeTagListComponent } from './admin/like-tag-list/like-tag-list.component';
// import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import { AddContactComponent } from './admin/add-contact/add-contact.component';
import { UnblockUserComponent } from './admin/unblock-user/unblock-user.component';
import { DragDropDirective } from './shared/directives/dragdrop.directive';
import { MatChipsModule } from '@angular/material/chips';
import { GetuserpofilePipe } from './admin/chat/getuserpofile.pipe';
import { FormatTimeToShowPipe } from './admin/chat/format-time-to-show.pipe';
import { ReplaceEnterForChatPipe } from './admin/chat/replace-enter-for-chat.pipe';
import { GetReactCountPipe } from './admin/chat/get-react-count.pipe';
import { CheckDateForTodayPipe } from './admin/chat/check-date-for-today.pipe';
import { GetChatTimePipe } from './admin/chat/get-chat-time.pipe';
import { AlreadyLikedPipe } from './admin/chat/already-liked.pipe';
import { TasksComponent } from './admin/tasks/tasks.component';
import { ClasscodeComponent } from './admin/classcode/classcode.component';
import { CountsComponent } from './admin/counts/counts.component';
import { NeoPrivacyPolicyComponent } from './admin/neo-privacy-policy/neo-privacy-policy.component';
import { NeoHomeComponent } from './admin/neo-home/neo-home.component';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { MemberFilterPipe } from './admin/members/member-filter.pipe';
import { CheckInMembersComponent } from './admin/check-in-members/check-in-members.component';
// import { PayrollSharedModule } from 'projects/payroll/src/app/app.module';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { HierarchyComponent } from './admin/hierarchy/hierarchy.component';
import { PositionsComponent } from './admin/positions/positions.component';
import { HierarchyAddComponent } from './admin/hierarchy-add/hierarchy-add.component';
import { TreeModule } from 'primeng/tree';
import { CheckInAttendencesComponent } from './admin/check-in-attendences/check-in-attendences.component';
import { CheckInAttendancesDetailsComponent } from './admin/check-in-attendances-details/check-in-attendances-details.component';
import { CheckInDaterangeDetailsComponent } from './admin/check-in-daterange-details/check-in-daterange-details.component';
import { CheckInProjectDetailsComponent } from './admin/check-in-project-details/check-in-project-details.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HierarchyInfoComponent } from './admin/hierarchy-info/hierarchy-info.component';
import { HierarchyChildrenComponent } from './admin/hierarchy-children/hierarchy-children.component';
import { HierarchyEditComponent } from './admin/hierarchy-edit/hierarchy-edit.component';
import { HierarchyEditDetailsComponent } from './admin/hierarchy-edit-details/hierarchy-edit-details.component';

import { CheckInProjectDaterangeComponent } from './admin/check-in-project-daterange/check-in-project-daterange.component';

import { VotingQrComponent } from './voting/voting-qr/voting-qr.component';
import { VotingComponent } from './voting/voting/voting.component';
import { VotingViewComponent } from './voting/voting-view/voting-view.component';
import { VotingRoundSummaryComponent } from './voting/voting-round-summary/voting-round-summary.component';
import { CandidatesComponent } from './voting/candidates/candidates.component';
import { VotersComponent } from './voting/voters/voters.component';
import { VotedListComponent } from './voting/voted-list/voted-list.component';
import { SigninComponent } from './voting/signin/signin.component';
import { CampaignComponent } from './voting/campaign/campaign.component';
import { ProxyComponent } from './voting/proxy/proxy.component';
import { SharePostComponent } from './admin/share-post/share-post.component';
import { DatePipe } from '@angular/common';
import { MyDatePickerModule } from 'mydatepicker';
import { NotificationComponent } from './noti-module/notification/notification.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CreatePostComponent } from './admin/create-post/create-post.component';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { CheckInShareComponent } from './admin/check-in-share/check-in-share.component';
import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import { UserProfileEditComponent } from './admin/user-profile-edit/user-profile-edit.component';
import { ChatEditComponent } from './admin/chat-edit/chat-edit.component';
import { ProjectReportComponent } from './contributor/project-report/project-report.component';
import { ProjectReportDetailComponent } from './contributor/project-report-detail/project-report-detail.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// CRM Module
import { CrmCustomerComponent } from './crm/crm-customer/crm-customer.component';

import { CrmPipelineComponent } from './crm/crm-pipeline/crm-pipeline.component';

import { DealComponent } from './crm/deal/deal.component';

import { LeadComponent } from './crm/lead/lead.component';
import { CrmServiceAddComponent } from './crm/crm-service-add/crm-service-add.component';
import { CrmFilterPipe } from './crm/crm/crm-filter.pipe';
import { CrmTicketHistoryComponent } from './crm/crm-ticket-history/crm-ticket-history.component';
import { CrmDashboardComponent } from './crm/crm-dashboard/crm-dashboard.component';
import { CrmBreadcrumbComponent } from './shared/components/crm-breadcrumb/crm-breadcrumb.component';
import { CrmServiceFormComponent } from './crm/crm-service-form/crm-service-form.component';
import { CrmStatusFormComponent } from './crm/status-form/status-form.component';
import { CrmPriorityFormComponent } from './crm/crm-priority-form/crm-priority-form.component';
import { CrmServiceFilterbydateComponent } from './crm/crm-service-filterbydate/crm-service-filterbydate.component';
import { CrmProductComponent } from './crm/crm-product/crm-product.component';
import { CrmComponent } from './crm/crm/crm.component';
import { CrmLeadAddComponent } from './crm/crm-lead-add/crm-lead-add.component';
import { CrmLeadConvertComponent } from './crm/crm-lead-convert/crm-lead-convert.component';
import { CrmDealAddComponent } from './crm/crm-deal-add/crm-deal-add.component';
import { CustomersComponent } from './crm/customers/customers.component';
import { CrmCustomerDealComponent } from './crm/crm-customer-deal/crm-customer-deal.component';
import { CustomerContactsComponent } from './crm/customer-contacts/customer-contacts.component';
import { PipelineTotalamountComponent } from './crm/pipeline-totalamount/pipeline-totalamount.component';
import { ProductsComponent } from './crm/products/products.component';
import { CrmPersonInChargeComponent } from './crm/crm-person-in-charge/crm-person-in-charge.component';
import { MeetingTitleComponent } from './admin/meeting-title/meeting-title.component';
import { CrmCustomerInfoComponent } from './crm/crm-customer-info/crm-customer-info.component';
import { TimeAgoPipe } from './shared/datetimeago/time-ago.pipe';
import { CrmProductModuleComponent } from './crm/crm-product-module/crm-product-module.component';
import { Connect365privacypolicyComponent } from './connect365privacypolicy/connect365privacypolicy.component';
import { LogisticsDashboardComponent } from './logistics/logistics-dashboard/logistics-dashboard.component';
import { LogisticsOrderComponent } from './logistics/logistics-order/logistics-order.component';
import { LogisticsCustomerComponent } from './logistics/logistics-customer/logistics-customer.component';
import { LogisticsOrderqrComponent } from './logistics/logistics-orderqr/logistics-orderqr.component';
import { CrmDashboardServiceComponent } from './crm/crm-dashboard-service/crm-dashboard-service.component';
import { LogisticsBatchComponent } from './logistics/logistics-batch/logistics-batch.component';
import { LogisticsAddbatchComponent } from './logistics/logistics-addbatch/logistics-addbatch.component';
import { LogisticsAssignComponent } from './logistics/logistics-assign/logistics-assign.component';
import { LogisticsUsersComponent } from './logistics/logistics-users/logistics-users.component';
import { LogisticOrdersBatchesComponent } from './logistics/logistic-orders-batches/logistic-orders-batches.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RoleComponent } from './admin/role/role.component';
import { RequestTypeComponent } from './admin/office/request-type/request-type.component';
import { OfficeDashboardComponent } from './admin/office/office-dashboard/office-dashboard.component';
import { CrmWorkFlowComponent } from './crm/crm-work-flow/crm-work-flow.component';
import { OfficeLeaveEntitlementComponent } from './admin/office/office-leave-entitlement/office-leave-entitlement.component';
import { OfficeChooseMembersComponent } from './admin/office/office-choose-members/office-choose-members.component';
import { OfficeChooseTypeComponent } from './admin/office/office-choose-type/office-choose-type.component';
import { KhubPrivacyComponent } from './khub-privacy/khub-privacy.component';
import { OfficeLeaveTemplateComponent } from './admin/office/office-leave-template/office-leave-template.component';
import { OfficeTemplateTypeComponent } from './admin/office/office-template-type/office-template-type.component';
import { OfficeLeaveCalendarComponent } from './admin/office/office-leave-calendar/office-leave-calendar.component';
import { DeliPrivacyComponent } from './deli-privacy/deli-privacy.component';
import { AlertBoxComponent } from './shared/components/alert-box/alert-box.component';
import { OfficeHolidayComponent } from './admin/office/office-holiday/office-holiday.component';
import { Hi365PrivacyComponent } from './hi365-privacy/hi365-privacy.component';
import { OfficeLeaveOpeningComponent } from './admin/office/office-leave-opening/office-leave-opening.component';
import { OfficeLeaveOpeningFormComponent } from './admin/office/office-leave-opening-form/office-leave-opening-form.component';
import { KhubEulaComponent } from './khub-eula/khub-eula.component';
import { OfficeRequestComponent } from './admin/office/office-request/office-request.component';
import { OfficeApprovalComponent } from './admin/office/office-approval/office-approval.component';
import { DeliEulaComponent } from './deli-eula/deli-eula.component';
import { ApprovalFilterComponent } from './admin/office/approval-filter/approval-filter.component';
import { CrmSummaryAdvansearchComponent } from './crm/crm-summary-advansearch/crm-summary-advansearch.component';
import { CrmCustomerAdvansearchComponent } from './crm/crm-customer-advansearch/crm-customer-advansearch.component';
import { ConnecthcmPrivacyComponent } from './connecthcm-privacy/connecthcm-privacy.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { OfficeWorkingHourComponent } from './admin/office/office-working-hour/office-working-hour.component';
import { ApprovalExportComponent } from './admin/office/approval-export/approval-export.component';
import { OfficeBreadcrumbComponent } from './shared/components/office-breadcrumb/office-breadcrumb.component';
import { OfficeLeaveSummaryComponent } from './admin/office/office-leave-summary/office-leave-summary.component';
import { UsermappingComponent } from './admin/usermapping/usermapping.component';
import { QrSetupComponent } from './qr360/qr-setup/qr-setup.component';
import { QrListViewComponent } from './qr360/qr-list-view/qr-list-view.component';
import { ViewQrComponent } from './qr360/view-qr/view-qr.component';
import { MembertypesComponent } from './admin/membertypes/membertypes.component';
import { MemberPositionControlComponent } from './admin/member-position-control/member-position-control.component';
import { MemberPositionHistoryComponent } from './admin/member-position-history/member-position-history.component';
import { HierarchyDeleteComponent } from './admin/hierarchy-delete/hierarchy-delete.component';
import { LeaveSummaryReportComponent } from './admin/office/leave-summary-report/leave-summary-report.component';
import { LeaveAbsenceDetailViewComponent } from './admin/leave-absence-detail-view/leave-absence-detail-view.component';
import { CheckinQrComponent } from './admin/checkin-qr/checkin-qr.component';
import { OfficeLeaveOpeningImportComponent } from './admin/office/office-leave-opening-import/office-leave-opening-import.component';
import { OfficeAnniversaryComponent } from './admin/office/office-anniversary/office-anniversary.component';
import { OfficeCalculateLeaveComponent } from './admin/office/office-calculate-leave/office-calculate-leave.component';
import { SafePipe } from './shared/safepipe/safe.pipe';
import { VimeModule } from '@vime/angular';
import { FormateDateToShowPipe } from './shared/formatdate/formate-date-to-show.pipe';
import { GetVideoIdPipe } from './shared/video/get-video-id.pipe';
import { OfficeUserRequestComponent } from './admin/office/office-user-request/office-user-request.component';
import { OfficeUserRequestFilterComponent } from './admin/office/office-user-request-filter/office-user-request-filter.component';
import { SocialPostsImageDetailComponent } from './admin/social-posts-image-detail/social-posts-image-detail.component';
import { EmployeeCentralComponent } from './admin/employee-central/employee-central.component';
import { HcmBreadcrumbComponent } from './shared/components/hcm-breadcrumb/hcm-breadcrumb.component';
import { CurtomRouteReuseStrategy } from './shared/services/route-reuse.service';
import { CheckInTicketComponent } from './admin/check-in-ticket/check-in-ticket.component';
import { CheckinTicketDetailsComponent } from './admin/checkin-ticket-details/checkin-ticket-details.component';
import { CustomerNameComponent } from './crm/customer-name/customer-name.component';
import { AppRestrictionComponent } from './admin/app-restriction/app-restriction.component';
import { AppRestrictionSetupComponent } from './admin/app-restriction-setup/app-restriction-setup.component';
import { CrmCustomerGroupComponent } from './crm/crm-customer-group/crm-customer-group.component';
import { OfficeShiftComponent } from './admin/office/office-shift/office-shift.component';
import { CustomerBranchComponent } from './crm/customer-branch/customer-branch.component';
import { OfficeRosterComponent } from './admin/office/office-roster/office-roster.component';
import { OfficeWorkpolicyComponent } from './admin/office/office-workpolicy/office-workpolicy.component';
import { OfficeWorkpolicyChooseMembersComponent } from './admin/office/office-workpolicy-choose-members/office-workpolicy-choose-members.component';
import { VehicleComponent } from './admin/transporation/vehicle/vehicle.component';
import { PickupPointComponent } from './admin/transporation/pickup-point/pickup-point.component';
import { RequestUserListComponent } from './admin/transporation/request-user-list/request-user-list.component';
import { TransportationBreadcrumbComponent } from './shared/components/transportation-breadcrumb/transportation-breadcrumb.component';
import { CarWayScheduleComponent } from './admin/transporation/car-way-schedule/car-way-schedule.component';
import { FerryRequestComponent } from './admin/transporation/ferry-request/ferry-request.component';
import { DailyRequestComponent } from './admin/transporation/daily-request/daily-request.component';
import { RequestPickupComponent } from './admin/transporation/request-pickup/request-pickup.component';
import { TransportationDashboardComponent } from './admin/transporation/transportation-dashboard/transportation-dashboard.component';
import { UserInfoCarwayComponent } from './admin/transporation/user-info-carway/user-info-carway.component';
import { EmployeeidMappingComponent } from './admin/employeeid-mapping/employeeid-mapping.component';
import { Router } from '@angular/router';
import { MemberPositionControlSyncComponent } from './admin/member-position-control-sync/member-position-control-sync.component';
import { UserQrListViewComponent } from './qr360/user-qr-list-view/user-qr-list-view.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { OfficeLeavePolicyComponent } from './admin/office/office-leave-policy/office-leave-policy.component';
import { AttendanceQrComponent } from './admin/attendance-qr/attendance-qr.component';
import { AttendanceQrSetupComponent } from './admin/attendance-qr-setup/attendance-qr-setup.component';
import { AttendanceQrViewComponent } from './admin/attendance-qr-view/attendance-qr-view.component';
import { LateAndEarlyoutPolicyComponent } from './admin/office/late-and-earlyout-policy/late-and-earlyout-policy.component';
import { LateChoosePostComponent } from './admin/office/late-choose-post/late-choose-post.component';
import { WorkplicyConfigComponent } from './admin/office/workplicy-config/workplicy-config.component';
import { LateAndEarlyoutReportComponent } from './admin/office/late-and-earlyout-report/late-and-earlyout-report.component';
import { EarlyoutReportComponent } from './admin/office/earlyout-report/earlyout-report.component';
import { DropoffPointComponent } from './admin/transporation/dropoff-point/dropoff-point.component';
import { CarWayScheduleDetailComponent } from './admin/transporation/car-way-schedule-detail/car-way-schedule-detail.component';
import { EmployeeRequestionComponent } from './admin/employee-requestion/employee-requestion.component';
import { RequisitionRequestComponent } from './admin/office/requisition-request/requisition-request.component';
import { RequisitionApprovalComponent } from './admin/office/requisition-approval/requisition-approval.component';
import { RequisitionRolesComponent } from './admin/office/requisition-roles/requisition-roles.component';
import { OfficeChooseRanksComponent } from './admin/office/office-choose-ranks/office-choose-ranks.component';
import { HrRequisitionComponent } from './admin/office/hr-requisition/hr-requisition.component';
import { AbsentreportDetailsComponent } from './admin/office/absentreport-details/absentreport-details.component';
import { OfficeRoomsComponent } from './admin/office/office-rooms/office-rooms.component';
import { OfficeDesksComponent } from './admin/office/office-desks/office-desks.component';
import { DriverComponent } from './admin/transporation/driver/driver.component';
import { DatetimeagoPipe } from './shared/datetimeago/datetimeago.pipe';
import { TimecardReportComponent } from './admin/office/timecard-report/timecard-report.component';
import { TimeInOutReportComponent } from './admin/office/time-in-out-report/time-in-out-report.component';
import { RulesAndRegulationComponent } from './admin/office/rules-and-regulation/rules-and-regulation.component';
import { RulesAndRegulationSetupComponent } from './admin/office/rules-and-regulation-setup/rules-and-regulation-setup.component';
import { FileViewerComponent } from './shared/components/file-viewer/file-viewer.component';
import { OutstandingRequestsComponent } from './admin/transporation/outstanding-requests/outstanding-requests.component';
import { UseridMappingComponent } from './admin/userid-mapping/userid-mapping.component';
import { RequisitionReasonComponent } from './admin/office/requisition-reason/requisition-reason.component';
import { ExportDetailListComponent } from './crm/export-detail-list/export-detail-list.component';
import { IncompleteReportComponent } from './admin/office/incomplete-report/incomplete-report.component';
import { AbsentreportComponent } from './admin/office/absentreport/absentreport.component';
import { RecruitmentsComponent } from './admin/office/recruitments/recruitments.component';
import { ReasonComponent } from './admin/office/reason/reason.component';
import { RequisitionUserInfoComponent } from './admin/office/requisition-user-info/requisition-user-info.component';
import { MembersRoleComponent } from './admin/members-role/members-role.component';
import { ServiceMonthlyReportComponent } from './crm/service-monthly-report/service-monthly-report.component';
import { ServiceOverageSetupComponent } from './crm/service-overage-setup/service-overage-setup.component';
import { ServiceOverageReportComponent } from './crm/service-overage-report/service-overage-report.component';
import { LeaveCountReportComponent } from './admin/office/leave-count-report/leave-count-report.component';
import { LeaveCountReportDetailsComponent } from './admin/office/leave-count-report-details/leave-count-report-details.component';
import { OfficeLeaveOpeingHistoryComponent } from './admin/office/office-leave-opeing-history/office-leave-opeing-history.component';
import { ManhourDetailReportComponent } from './crm/manhour-detail-report/manhour-detail-report.component';
import { CheckinReportComponent } from './admin/office/checkin-report/checkin-report.component';
import { ChatInfoImageEditorComponent } from './admin/chat-info-image-editor/chat-info-image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LateEarlyoutFormComponent } from './admin/office/late-earlyout-form/late-earlyout-form.component';
import { LateEarlyoutDataFormComponent } from './admin/office/late-earlyout-data-form/late-earlyout-data-form.component';
import { LateEarlyoutRequestComponent } from './admin/office/late-earlyout-request/late-earlyout-request.component';
import { PayLevelComponent } from './admin/pay-level/pay-level.component';
import { LateEarlyoutTimeReportComponent } from './admin/office/late-earlyout-time-report/late-earlyout-time-report.component';
import { WorkFromHomeRequestComponent } from './admin/office/work-from-home-request/work-from-home-request.component';
import { OfficeCalendarComponent } from './admin/office/office-calendar/office-calendar.component';
import { OfficeCalenderTableComponent } from './admin/office/office-calender-table/office-calender-table.component';
import { OffInLieuRequestComponent } from './admin/office/off-in-lieu-request/off-in-lieu-request.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LateEarlyoutReportPolicyComponent } from './admin/office/late-earlyout-report-policy/late-earlyout-report-policy.component';
import { RmsPrivacyComponent } from './rms-privacy/rms-privacy.component';
import { HrRequisitionFilterComponent } from './admin/office/hr-requisition-filter/hr-requisition-filter.component';
import { ProjectStatusComponent } from './admin/project-status/project-status.component';
import { ImportLeaveEntitlementComponent } from './admin/office/import-leave-entitlement/import-leave-entitlement.component';
import { CalcuateBroughtForwardComponent } from './admin/office/calcuate-brought-forward/calcuate-brought-forward.component';
import { DeviceIdComponent } from './admin/device-id/device-id.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { Cs365DashboardComponent } from './crm/cs365/dashboard/dashboard.component';
import { Cs365ServiceTicketsComponent } from './crm/cs365/service-tickets/service-tickets.component';
import { Cs365BreadcrumbComponent } from './crm/cs365/cs365-breadcrumb/cs365-breadcrumb.component';
import { ServiceTicketsFormComponent } from './crm/cs365/service-tickets-form/service-tickets-form.component';
import { ServiceTicketsHistoryComponent } from './crm/cs365/service-tickets-history/service-tickets-history.component';
import { QuestionComponent } from './crm/cs365/question/question.component';
import { LabelSetupComponent } from './crm/cs365/label-setup/label-setup.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HistoryDetailComponent } from './crm/cs365/history-detail/history-detail.component';
import { AccessoriesRequestComponent } from './admin/office/accessories-request/accessories-request.component';
import { PayPeriodComponent } from './admin/pay-period/pay-period.component';
import { PayPeriodFormComponent } from './admin/pay-period-form/pay-period-form.component';
import { ReferenceDashboardComponent } from './admin/reference-dashboard/reference-dashboard.component';
import { TeamDashboardComponent } from './admin/team-dashboard/team-dashboard.component';
import { TeamBreadcrumbComponent } from './shared/components/team-breadcrumb/team-breadcrumb.component';
import { WarrantyComponent } from './admin/warranty/warranty.component';
import { WarrantyFormComponent } from './admin/warranty-form/warranty-form.component';
import { OnlynumberDirective } from './shared/directives/onlynumber.directive';
import { ServiceWarrantyComponent } from './crm/cs365/service-warranty/service-warranty.component';
import { ServiceWarrantyAdvancedsearchComponent } from './crm/cs365/service-warranty-advancedsearch/service-warranty-advancedsearch.component';
import { DropdownButtonComponent } from './crm/cs365/dropdown-button/dropdown-button.component';
import { ArrayFilterPipe } from './shared/array-filter.pipe';
import { WarrantyTicketsComponent } from './crm/cs365/warranty-tickets/warranty-tickets.component';
import { WarrantyTicketsDetailsComponent } from './crm/cs365/warranty-tickets-details/warranty-tickets-details.component';
import { InvalidPermissionComponent } from './admin/invalid-permission/invalid-permission.component';
import { AccessoriesApprovalComponent } from './admin/office/accessories-approval/accessories-approval.component';
import { AccessoriesRequestHistoryComponent } from './admin/office/accessories-request-history/accessories-request-history.component';
import { AccessoriesFilterComponent } from './admin/office/accessories-filter/accessories-filter.component';
import { OfficeTravelDetailComponent } from './admin/office/office-travel-detail/office-travel-detail.component';
import { SelectTravelComponent } from './admin/office/select-travel/select-travel.component';
import { MemberDashboardComponent } from './members/member-dashboard/member-dashboard.component';
import { MemberRegisterComponent } from './members/member-register/member-register.component';
import { MemberRelationFormComponent } from './members/member-relation-form/member-relation-form.component';
import { ChooseMemberComponent } from './members/choose-member/choose-member.component';
import { MemberRequestlistComponent } from './members/memberl_requestlist/member_requestlist.component';
import { MonthlyfeesetupComponent } from './members/monthlyfeesetup/monthlyfeesetup.component';
import { MonthlyfeedetailComponent } from './members/monthlyfeedetail/monthlyfeedetail.component';
import { MonthlyfeeListComponent } from './members/monthlyfee-list/monthlyfee-list.component';
import { KmemberBreadcrumbComponent } from './members/kmember-breadcrumb/kmember-breadcrumb.component';
import { MemberPaymentComponent } from './members/member-payment/member-payment.component';
import { MemberGuestRegisterFormComponent } from './members/member-guest-register-form/member-guest-register-form.component';
import { BTypeMemberRegisterComponent } from './members/b-type-member-register/b-type-member-register.component';
import { MemberApprovalListComponent } from './members/member-approval-list/member-approval-list.component';
import { MemberApprovalFormComponent } from './members/member-approval-form/member-approval-form.component';
import { MemberApprovalDialogComponent } from './members/member-approval-dialog/member-approval-dialog.component';
import { MemberApprovalHistoryComponent } from './members/member-approval-history/member-approval-history.component';
import { MemberPaymentformComponent } from './members/member-paymentform/member-paymentform.component';
import { MemberPaymentHistoryComponent } from './members/member-payment-history/member-payment-history.component';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MemberCardRegistorComponent } from './members/member-card-registor/member-card-registor.component';
import { RegisterComponent } from './members/register/register.component';
import { SelectReferrerComponent } from './members/select-referrer/select-referrer.component';
import { PublicRegisterStatusComponent } from './members/public-register-status/public-register-status.component';
import { SummerSwimFormComponent } from './members/summer-swim-form/summer-swim-form.component';
import { SpecialSwimFormComponent } from './members/special-swim-form/special-swim-form.component';
import { TempSwimFormComponent } from './members/temp-swim-form/temp-swim-form.component';
import { GymFormComponent } from './members/gym-form/gym-form.component';
import { SummerSwimmerListComponent } from './members/summer-swimmer-list/summer-swimmer-list.component';
import { SpecialSwimListComponent } from './members/special-swim-list/special-swim-list.component';
import { GymListComponent } from './members/gym-list/gym-list.component';
import { TempSwimmerListComponent } from './members/temp-swimmer-list/temp-swimmer-list.component';
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';
import { GeneralPaymentInvoiceFormComponent } from './members/general-payment-invoice-form/general-payment-invoice-form.component';
import { GeneralPaymentInvoiceListComponent } from './members/general-payment-invoice-list/general-payment-invoice-list.component';
import { LeaveFormComponent } from './members/leave-form/leave-form.component';
import { LeaveListComponent } from './members/leave-list/leave-list.component';
import { LeaveDetailComponent } from './members/leave-detail/leave-detail.component';
import { SelectMemberComponent } from './members/select-member/select-member.component';
import { LeaveSelectMemberComponent } from './shared/components/leave-select-member/leave-select-member.component';
import { SummerSwimmerDetailComponent } from './members/summer-swimmer-detail/summer-swimmer-detail.component';
import { GeneralPaymentDetailComponent } from './members/general-payment-detail/general-payment-detail.component';
import { SpecialSwimDetailComponent } from './members/special-swim-detail/special-swim-detail.component';
import { TempDetailComponent } from './members/temp-detail/temp-detail.component';
import { GeneralPaymentInvoiceUpdateComponent } from './members/general-payment-invoice-update/general-payment-invoice-update.component';
import { GymDetailComponent } from './members/gym-detail/gym-detail.component';
import { SummerSwimmerPreviewComponent } from './members/summer-swimmer-preview/summer-swimmer-preview.component';
import { SpecialSwimmerPreviewComponent } from './members/special-swimmer-preview/special-swimmer-preview.component';
import { TempSwimmerPreviewComponent } from './members/temp-swimmer-preview/temp-swimmer-preview.component';
import { GymMemberPreviewComponent } from './members/gym-member-preview/gym-member-preview.component';
import { GuestFormComponent } from './members/guest-form/guest-form.component';
import { KMemberSelectComponent } from './shared/components/k-member-select/k-member-select.component';
import { GuestListComponent } from './members/guest-list/guest-list.component';
import { GuestDetailComponent } from './members/guest-detail/guest-detail.component';
import { BatchListComponent } from './members/batch-list/batch-list.component';
import { BatchFormComponent } from './members/batch-form/batch-form.component';
import { BatchDetailComponent } from './members/batch-detail/batch-detail.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { ListComponent } from './shared/components/list/list.component';
import { SynchistoryComponent } from './admin/synchistory/synchistory.component';
import { MemberInfoSyncDialogComponent } from './admin/member-info-sync-dialog/member-info-sync-dialog.component';
import { CrmSummaryComponent } from './crm/crm-summary/crm-summary/crm-summary.component';
import { OfficeWorkpolicyFilterComponent } from './admin/office/office-workpolicy-filter/office-workpolicy-filter.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false,
  useBothWheelAxes: true,
};

const customCurrencyMaskConfig: any = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    AppComponent,
    OrganizationsComponent,
    NavigationBarComponent,
    DashboardComponent,
    LocationsComponent,
    MembersComponent,
    EventsComponent,
    ButtonLoaderComponent,
    ConfirmDialogComponent,
    LoginComponent,
    VerifyComponent,
    EventsAttendanceComponent,
    EventsDetailComponent,
    ContactsTracingComponent,
    TooltipDirective,
    DragDropDirective,
    DomainComponent,
    MapComponent,
    BreadcrumbComponent,
    LocationsNewComponent,
    ContactTracingDetailsComponent,
    QrLoginComponent,
    ChatComponent,
    ChatContactsComponent,
    ChatInfoComponent,
    FilterPipe,
    TimeAgoPipe,
    LoadingComponent,
    CreateGroupComponent,
    CheckinEventModalComponent,
    CheckInComponent,
    DomainsComponent,
    DomainMembersComponent,
    PopoverComponent,
    AdminsRootComponent,
    ServerMaintenanceComponent,
    EulaComponent,
    PrivacyPolicyComponent,
    AdminsComponent,
    ProjectsComponent,
    JobsComponent,
    PostsComponent,
    SocialPostsComponent,
    CreatePostComponent,
    SocialPostsImagesComponent,
    SocialPostsDetailComponent,
    AttendancesComponent,
    BlockUserComponent,
    FilterDialogComponent,
    ReportsComponent,
    LikeTagListComponent,
    UserProfileComponent,
    UserProfileEditComponent,
    AddContactComponent,
    UnblockUserComponent,
    GetuserpofilePipe,
    FormatTimeToShowPipe,
    ReplaceEnterForChatPipe,
    GetReactCountPipe,
    CheckDateForTodayPipe,
    GetChatTimePipe,
    AlreadyLikedPipe,
    TasksComponent,
    ClasscodeComponent,
    CountsComponent,
    NeoPrivacyPolicyComponent,
    NeoHomeComponent,
    MemberFilterPipe,
    CheckInMembersComponent,
    HierarchyComponent,
    PositionsComponent,
    HierarchyAddComponent,
    CheckInAttendencesComponent,
    CheckInAttendancesDetailsComponent,
    CheckInDaterangeDetailsComponent,
    CheckInProjectDetailsComponent,
    HierarchyInfoComponent,
    HierarchyChildrenComponent,
    HierarchyEditComponent,
    HierarchyEditDetailsComponent,
    CrmComponent,
    CrmLeadAddComponent,
    ProductsComponent,
    CrmLeadConvertComponent,
    CrmDealAddComponent,
    CustomersComponent,
    CrmCustomerDealComponent,
    CustomerContactsComponent,
    CheckInProjectDaterangeComponent,
    PipelineTotalamountComponent,
    VotingQrComponent,
    VotingComponent,
    VotingViewComponent,
    VotingRoundSummaryComponent,
    CandidatesComponent,
    VotersComponent,
    VotedListComponent,
    SigninComponent,
    VoterFilterPipe,
    CampaignComponent,
    ProxyComponent,
    SharePostComponent,
    NotificationComponent,
    AdminDashboardComponent,
    CrmServiceAddComponent,
    CrmFilterPipe,
    CrmTicketHistoryComponent,
    CrmDashboardComponent,
    CrmBreadcrumbComponent,
    CrmSummaryComponent,
    CheckInShareComponent,
    ChatEditComponent,
    CrmServiceFormComponent,
    CrmStatusFormComponent,
    CrmPriorityFormComponent,
    ProjectReportComponent,
    ProjectReportDetailComponent,
    CrmServiceFilterbydateComponent,
    CrmProductComponent,
    CrmPersonInChargeComponent,
    MeetingTitleComponent,
    CrmCustomerInfoComponent,
    CrmProductModuleComponent,
    Connect365privacypolicyComponent,
    LogisticsDashboardComponent,
    LogisticsOrderComponent,
    LogisticsCustomerComponent,
    LogisticsOrderqrComponent,
    CrmDashboardServiceComponent,
    LogisticsBatchComponent,
    LogisticsAddbatchComponent,
    LogisticsAssignComponent,
    LogisticsUsersComponent,
    LogisticOrdersBatchesComponent,
    RoleComponent,
    RequestTypeComponent,
    OfficeDashboardComponent,
    OfficeLeaveEntitlementComponent,
    OfficeChooseMembersComponent,
    OfficeChooseTypeComponent,
    CrmWorkFlowComponent,
    KhubPrivacyComponent,
    OfficeLeaveTemplateComponent,
    OfficeTemplateTypeComponent,
    OfficeLeaveCalendarComponent,
    DeliPrivacyComponent,
    AlertBoxComponent,
    OfficeHolidayComponent,
    Hi365PrivacyComponent,
    OfficeLeaveOpeningComponent,
    OfficeLeaveOpeningFormComponent,
    KhubEulaComponent,
    OfficeRequestComponent,
    OfficeApprovalComponent,
    DeliEulaComponent,
    ApprovalFilterComponent,
    CrmSummaryAdvansearchComponent,
    CrmCustomerAdvansearchComponent,
    ConnecthcmPrivacyComponent,
    FileUploadComponent,
    OfficeWorkingHourComponent,
    ApprovalExportComponent,
    OfficeBreadcrumbComponent,
    OfficeLeaveSummaryComponent,
    LeadComponent,
    DealComponent,
    CrmCustomerComponent,
    CrmPipelineComponent,
    UsermappingComponent,
    QrSetupComponent,
    QrListViewComponent,
    ViewQrComponent,
    MembertypesComponent,
    MemberPositionControlComponent,
    MemberPositionHistoryComponent,
    HierarchyDeleteComponent,
    LeaveSummaryReportComponent,
    LeaveAbsenceDetailViewComponent,
    CheckinQrComponent,
    OfficeLeaveOpeningImportComponent,
    OfficeAnniversaryComponent,
    OfficeCalculateLeaveComponent,
    SafePipe,
    FormateDateToShowPipe,
    GetVideoIdPipe,
    OfficeUserRequestComponent,
    OfficeUserRequestFilterComponent,
    SocialPostsImageDetailComponent,
    EmployeeCentralComponent,
    HcmBreadcrumbComponent,
    CheckInTicketComponent,
    CheckinTicketDetailsComponent,
    CustomerNameComponent,
    AppRestrictionComponent,
    AppRestrictionSetupComponent,
    CrmCustomerGroupComponent,
    OfficeShiftComponent,
    CustomerBranchComponent,
    OfficeRosterComponent,
    OfficeWorkpolicyComponent,
    OfficeWorkpolicyChooseMembersComponent,
    VehicleComponent,
    DriverComponent,
    PickupPointComponent,
    DropoffPointComponent,
    CarWayScheduleDetailComponent,
    RequestUserListComponent,
    TransportationBreadcrumbComponent,
    CarWayScheduleComponent,
    FerryRequestComponent,
    DailyRequestComponent,
    RequestPickupComponent,
    TransportationDashboardComponent,
    UserInfoCarwayComponent,
    EmployeeidMappingComponent,
    MemberPositionControlSyncComponent,
    UserQrListViewComponent,
    OfficeLeavePolicyComponent,
    AttendanceQrComponent,
    AttendanceQrSetupComponent,
    AttendanceQrViewComponent,
    LateAndEarlyoutPolicyComponent,
    LateChoosePostComponent,
    WorkplicyConfigComponent,
    LateAndEarlyoutReportComponent,
    EarlyoutReportComponent,
    EmployeeRequestionComponent,
    RequisitionRequestComponent,
    RequisitionApprovalComponent,
    RequisitionRolesComponent,
    OfficeChooseRanksComponent,
    HrRequisitionComponent,
    AbsentreportComponent,
    AbsentreportDetailsComponent,
    OfficeRoomsComponent,
    OfficeDesksComponent,
    DatetimeagoPipe,
    TimecardReportComponent,
    TimeInOutReportComponent,
    RulesAndRegulationComponent,
    RulesAndRegulationSetupComponent,
    FileViewerComponent,
    OutstandingRequestsComponent,
    UseridMappingComponent,
    RequisitionReasonComponent,
    ExportDetailListComponent,
    IncompleteReportComponent,
    RecruitmentsComponent,
    ReasonComponent,
    RequisitionUserInfoComponent,
    MembersRoleComponent,
    ServiceMonthlyReportComponent,
    ServiceOverageSetupComponent,
    ServiceOverageReportComponent,
    LeaveCountReportComponent,
    LeaveCountReportDetailsComponent,
    OfficeLeaveOpeingHistoryComponent,
    ManhourDetailReportComponent,
    CheckinReportComponent,
    ChatInfoImageEditorComponent,
    LateEarlyoutFormComponent,
    LateEarlyoutDataFormComponent,
    LateEarlyoutRequestComponent,
    PayLevelComponent,
    LateEarlyoutTimeReportComponent,
    WorkFromHomeRequestComponent,
    OfficeCalendarComponent,
    OfficeCalenderTableComponent,
    OffInLieuRequestComponent,
    PageNotFoundComponent,
    LateEarlyoutReportPolicyComponent,
    RmsPrivacyComponent,
    HrRequisitionFilterComponent,
    ProjectStatusComponent,
    ImportLeaveEntitlementComponent,
    CalcuateBroughtForwardComponent,
    DeviceIdComponent,
    Cs365DashboardComponent,
    Cs365ServiceTicketsComponent,
    Cs365BreadcrumbComponent,
    ServiceTicketsFormComponent,
    ServiceTicketsHistoryComponent,
    QuestionComponent,
    LabelSetupComponent,
    AccessoriesRequestComponent,
    HistoryDetailComponent,
    PayPeriodComponent,
    PayPeriodFormComponent,
    ReferenceDashboardComponent,
    TeamDashboardComponent,
    TeamBreadcrumbComponent,
    WarrantyComponent,
    WarrantyFormComponent,
    OnlynumberDirective,
    ServiceWarrantyComponent,
    ServiceWarrantyAdvancedsearchComponent,
    DropdownButtonComponent,
    ArrayFilterPipe,
    WarrantyTicketsComponent,
    WarrantyTicketsDetailsComponent,
    InvalidPermissionComponent,
    AccessoriesApprovalComponent,
    AccessoriesRequestHistoryComponent,
    AccessoriesFilterComponent,
    OfficeTravelDetailComponent,
    SelectTravelComponent,
    MemberDashboardComponent,
    MemberRegisterComponent,
    MemberRelationFormComponent,
    ChooseMemberComponent,
    MonthlyfeesetupComponent,
    MonthlyfeedetailComponent,
    MonthlyfeeListComponent,
    KmemberBreadcrumbComponent,
    MemberPaymentComponent,
    MemberGuestRegisterFormComponent,
    BTypeMemberRegisterComponent,
    MemberApprovalListComponent,
    MemberApprovalFormComponent,
    MemberApprovalDialogComponent,
    MemberApprovalHistoryComponent,
    MemberPaymentformComponent,
    MemberPaymentHistoryComponent,
    MemberListComponent,
    MemberRequestlistComponent,
    MemberCardRegistorComponent,
    RegisterComponent,
    SelectReferrerComponent,
    PublicRegisterStatusComponent,
    SummerSwimFormComponent,
    SpecialSwimFormComponent,
    TempSwimFormComponent,
    GymFormComponent,
    SummerSwimmerListComponent,
    SpecialSwimListComponent,
    GymListComponent,
    TempSwimmerListComponent,
    BreadcrumbsComponent,
    GeneralPaymentInvoiceFormComponent,
    GeneralPaymentInvoiceListComponent,
    LeaveFormComponent,
    LeaveListComponent,
    LeaveDetailComponent,
    SelectMemberComponent,
    LeaveSelectMemberComponent,
    SummerSwimmerDetailComponent,
    GeneralPaymentDetailComponent,
    SpecialSwimDetailComponent,
    TempDetailComponent,
    GeneralPaymentInvoiceUpdateComponent,
    GymDetailComponent,
    SummerSwimmerPreviewComponent,
    SpecialSwimmerPreviewComponent,
    TempSwimmerPreviewComponent,
    GymMemberPreviewComponent,
    GuestFormComponent,
    KMemberSelectComponent,
    GuestListComponent,
    GuestDetailComponent,
    BatchListComponent,
    BatchFormComponent,
    BatchDetailComponent,
    PaginationComponent,
    ListComponent,
    SynchistoryComponent,
    MemberInfoSyncDialogComponent,
    OfficeWorkpolicyFilterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    NgxQRCodeModule,
    GoogleMapsModule,
    MatSortModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    MatIconModule,
    MatButtonModule,
    TextFieldModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    MatListModule,
    NgSelectModule,
    NgxPaginationModule,
    AutosizeModule,
    MatTabsModule,
    MatChipsModule,
    ChartsModule,
    // PayrollSharedModule,
    MatTooltipModule,
    OrganizationChartModule,
    TreeModule,
    MatAutocompleteModule,
    TabViewModule,
    NgxPanZoomModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    CarouselModule,
    ClipboardModule,
    MatSelectModule,
    VimeModule,
    ImageCropperModule,

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgIdleKeepaliveModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [],
      },
    }),
    AngularSvgIconModule.forRoot(),
    NgxExtendedPdfViewerModule,
    BsDatepickerModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MyDatePickerModule
  ],
  providers: [
    CookieService,
    NgxImageCompressService,
    MessageService,
    AllInOneService,
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppCustomLogic,
      multi: true,
      deps: [Router],
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }

export function initializeAppCustomLogic(router: Router): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      // localhost
      // www.qr360.org
      if (window.location.hostname == 'www.qr360.org') {
        router.resetConfig([
          { path: '', component: LoginComponent },
          { path: 'qrlogin', component: QrLoginComponent },
          { path: 'login', component: LoginComponent },
          { path: 'verify', component: VerifyComponent },
          {
            path: 'home',
            component: UserQrListViewComponent,
            canActivate: [AuthGuard],
          },
          { path: '**', redirectTo: '' },
        ]);
      }

      resolve();
      // setTimeout(() => {
      //   console.log(
      //     '***3 seconds latter, custom logic finished, Angular init***'
      //   );

      // }, 3000);
    });
}
