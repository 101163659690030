<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid" [ngClass]="{'scroll':checkModeal}">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal">
            <div class="label title" *ngIf="!customerAdd" style="font-size: 17px;">Customers</div>
            <div class="label title" *ngIf="customerAdd" style="font-size: 17px;">Add Customer</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="!leadAdd && !customerAdd && !dealAdd && !serviceAdd && !historyshow && !summaryCheck">
            <div class="d-flex"
                *ngIf="!goCusBol && !goDealBol && !gopipBol && routevalueP == '' && routevalueQ == '' && routevalueAll== '' && customername == '' && !checkModeal && !checkserviceM && !checkserviceO">
                <div class="me-auto">
                    <app-crm-breadcrumb
                        *ngIf="!goCusBol && !goDealBol && !gopipBol && routevalueP == '' && routevalueQ == '' && routevalueAll== '' && customername == '' && !customercare"
                        [s_breadcrumb]="'Service Tickets'">
                    </app-crm-breadcrumb>
                    <app-crm-breadcrumb s_breadcrumb="Self Service" [t_breadcrumb]="'Service Tickets'"
                        *ngIf="customercare" (emit)="goCrm()">
                    </app-crm-breadcrumb>
                </div>
                <div *ngIf="isMobileView" style="font-size: 15px;">
                    Total&nbsp;:&nbsp;{{total | number}}

                </div>
                <!--  -->

                <div class="card  custom-card leftstatus" *ngIf=" !isMobileView" style="border: none;">
                    <span class="justify-content-start align-items-center"
                        style="padding: 3px 8px 3px 8px;font-size: 15px;"> <span
                            class="total-count-num">{{totalopencount | number}} </span> <span
                            style="margin-right: 4px;">Open + </span> | <span style="margin-left: 4px;"
                            class="total-count-num"> {{totalclosedcount | number}}</span> Completed+</span>
                </div>
            </div>
            <app-crm-breadcrumb *ngIf="goCusBol && !checkModeal " s_breadcrumb="Service"
                [t_breadcrumb]="'Service Tickets'" (emit)="cancelFunction(1)">
            </app-crm-breadcrumb>

            <div class="d-flex"
                *ngIf="(routevalueP != '' || routevalueQ != '' || routevalueAll !='') && !checkModeal && !checkserviceO">
                <div class="me-auto">
                    <app-crm-breadcrumb *ngIf="allinoneService.isCustomerCare" [s_breadcrumb]="'Self Service'"
                        [t_breadcrumb]="'Service Summary'" [f_breadcrumb]="'Service Tickets'"
                        (emit)="goSummarySelfservice()" (emit1)="goSummary()">
                    </app-crm-breadcrumb>
                    <app-crm-breadcrumb *ngIf="!allinoneService.isCustomerCare" [s_breadcrumb]="'Service Summary'"
                        [t_breadcrumb]="'Service Tickets'" (emit)="goSummary()">
                    </app-crm-breadcrumb>
                </div>
                <div *ngIf="isMobileView" style="font-size: 15px;">
                    Total&nbsp;:&nbsp;{{total | number }}

                </div>
                <!--  -->

                <div class="card  custom-card  leftstatus" *ngIf=" !isMobileView" style="border: none;">
                    <span class="justify-content-start align-items-center"
                        style="padding: 3px 8px 3px 8px;font-size: 15px;"> <span
                            class="total-count-num">{{totalopencount | number}} </span> <span
                            style="margin-right: 4px;">Open +</span> | <span style="margin-left: 4px;"
                            class="total-count-num"> {{totalclosedcount | number}}</span> Completed+</span>
                </div>
            </div>


            <app-crm-breadcrumb *ngIf="customername && !checkModeal" [s_breadcrumb]="'Customers'"
                [t_breadcrumb]="customername" [f_breadcrumb]="'Service Tickets'" (emit)="backCustomerbreadcrumb()"
                (emit1)="backCustomerbreadcrumbedit()">
            </app-crm-breadcrumb>
            <app-crm-breadcrumb *ngIf="checkserviceM" [s_breadcrumb]="'Service Monthly Report'"
                [t_breadcrumb]="'Service Tickets'" (emit)="backservicmonthly()">
            </app-crm-breadcrumb>
            <app-crm-breadcrumb *ngIf="checkserviceO" [s_breadcrumb]="'Service Overdue Report'"
                [t_breadcrumb]="'Service Tickets'" (emit)="backservicmonthly()">
            </app-crm-breadcrumb>

            <!--  (emit1)="cancels(2)"  -->
            <!-- isMoblie -->

            <!-- comment status -->


            <div class="d-flex flex-wrap">

                <div class="showbtn me-auto  mb-2">
                    <button class="btn btn-custom me-2 " (click)="new()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>


                </div>
                <div class="me-2 mb-2" *ngIf="!filterstartdate && !filterenddate  && isMobileView">
                    <div class="btn btn-custom " (click)="filterByDate()" matTooltip="Advanced Search">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <!-- style="width:24px;height: 24px;font-size: 20px;line-height: 1.2;" -->
                    </div>
                </div>
                <div class=" mb-2" *ngIf="isMobileView ">
                    <button class="btn btn-custom" (click)="refresh()" matTooltip="Refresh" [disabled]="!crmForm.orgid">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>

                <div *ngIf="isMobileView" class="ismobile" style="  width: 100%;
                        overflow-x: auto;
                        overflow-y: hidden;
                        white-space: nowrap;
                        display: flex;">
                    <div *ngIf="!customercare" class="mb-2  me-2 " [matMenuTriggerFor]="internalmenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                            {{internalname}}
                        </button>
                    </div>
                    <mat-menu #internalmenu="matMenu" xPosition="before" class="menuproject">

                        <button style="line-height: 2.2 !important;" class="mat-item"
                            (click)="checkInternal('all')">All</button>

                        <button style="line-height: 2.2 !important;" class="mat-item"
                            (click)="checkInternal('exclude')">Customers</button>
                        <button style="line-height: 2.2 !important;" class="mat-item"
                            (click)="checkInternal('internal')">Internal </button>
                    </mat-menu>

                    <div *ngIf="customercare" class="mb-2 me-2  align-items-center" [matMenuTriggerFor]="customermenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                            (click)="removeStatus()">
                            {{statusname != 'Status' ? '' : '' }} {{statusname}}<span class="statusname"
                                *ngIf="statusnameList.length > 1 && customercare">+{{statusnameList.length - 1}}</span>
                        </button>
                    </div>
                    <mat-menu #customermenu="matMenu" id="service-menu" xPosition="before">
                        <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                            <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="statussearchcus"
                                style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border:none">

                        </div>
                        <button class="mat-item" (click)="statusChanges('status','index')">-</button>
                        <div class="mat-item "
                            *ngFor="let stu of statusListsCustomer  | crmFilter : statussearchcus:text1:text2:17; let index = index;"
                            (click)="$event.stopPropagation();">
                            <input [checked]="stu.check" class="form-check-input  cus" type="checkbox"
                                id="status{{index}}" name="status" (click)="statusChangeCustomers(stu,index)">
                            <label for="status{{index}}" class="form-check-label cus mb-1 ms-1">
                                {{stu.name}}
                            </label>

                        </div>
                        <!-- <button class="mat-item" *ngFor="let stu of statusListsCustomer  | crmFilter : statussearchcus:text1:text2:17;let index = index" (click)="statusChangeCustomers(stu,index)">{{stu.name}}</button> -->
                    </mat-menu>
                    <div *ngIf=" !customercare" class="mb-2 me-2 d-flex align-items-center"
                        [matMenuTriggerFor]="orgmenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                            (click)="removeStatus()">
                            {{statusname != 'Status' ? '' : '' }} {{statusname}}<span class="statusname"
                                *ngIf="statusnameList.length > 1">+{{statusnameList.length - 1}}</span>
                        </button>
                    </div>

                    <mat-menu #orgmenu="matMenu" id="service-menu" xPosition="before">
                        <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                            <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="statussearch"
                                style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;">

                        </div>
                        <button class="mat-item" (click)="statusChanges('status','index')">-</button>
                        <div class="mat-item "
                            *ngFor="let stu of statusLists  | crmFilter : statussearch:text1:text2:17; let index = index;"
                            (click)="$event.stopPropagation();">
                            <input [checked]="stu.check" class="form-check-input cus" type="checkbox"
                                id="status{{index}}" name="status" (click)="statusChanges(stu,index)">
                            <label for="status{{index}}" class="form-check-label cus mb-1 ms-1">
                                {{stu.name}}
                            </label>

                        </div>

                    </mat-menu>
                    <div class="mb-2 me-2 d-flex  align-items-center" [matMenuTriggerFor]="typemenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle
                            style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                            (click)="removeService()">
                            {{typename != 'Type' ? '' : '' }}{{typename}}
                        </button>
                    </div>

                    <mat-menu #typemenu="matMenu" id="service-menu" xPosition="before">
                        <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                            <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="servicesearch"
                                style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;">

                        </div>
                        <button class="mat-item" (click)="seviceChanges('type')">-</button>
                        <button class="mat-item"
                            *ngFor="let item of servcietypelist | crmFilter : servicesearch:text1:text2:18;"
                            (click)="seviceChanges(item)">{{item.name}} (
                            {{item.shortcode}} )</button>
                    </mat-menu>
                    <!--  -->
                    <div *ngIf="filtergetdata.ticketNo" class="btn-group me-2 mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Ticket No. : -->
                            {{filtergetdata.ticketNo}}
                        </button>

                        <button (click)="closeAdvanSearch(1)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>


                    </div>
                    <div *ngIf="filtergetdata.refno" class="btn-group me-2 mb-2 d-flex  align-items-center" role="group"
                        aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Ref No. : -->
                            {{filtergetdata.refno}}
                        </button>

                        <button (click)="closeAdvanSearch(13)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>


                    </div>
                    <!-- refno -->
                    <!--  -->
                    <div *ngIf="filtergetdata.assignedtome == true"
                        class="me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            Assigned To Me
                        </button>
                        <button (click)="closeAdvanSearch(2)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">

                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <!--  -->
                    <div *ngIf="filtergetdata.picName" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Assigned To : -->
                            {{filtergetdata.picName}}
                        </button>
                        <button (click)="closeAdvanSearch(3)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <!--  -->
                    <div *ngIf="filtergetdata.priorityName" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Priority : -->
                            {{filtergetdata.priorityName}}
                        </button>
                        <button (click)="closeAdvanSearch(4)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.projectName" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Project : -->
                            {{filtergetdata.projectName}}
                        </button>
                        <button (click)="closeAdvanSearch(5)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.description"
                        class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center" role="group"
                        aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Description : -->
                            {{filtergetdata.description}}
                        </button>

                        <button (click)="closeAdvanSearch(14)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>


                    </div>
                    <div *ngIf="filtergetdata.detail " class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Detail : -->
                            {{filtergetdata.detail}}
                        </button>

                        <button (click)="closeAdvanSearch(15)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>


                    </div>
                    <div *ngIf="filtergetdata.productName" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Product : -->
                            {{filtergetdata.productName}}
                        </button>
                        <button (click)="closeAdvanSearch(6)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.modulename" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Module : -->
                            {{filtergetdata.modulename}}
                        </button>
                        <button (click)="closeAdvanSearch(7)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.customerName" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Customer : -->
                            {{filtergetdata.customerName}}
                        </button>
                        <button (click)="closeAdvanSearch(8)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.reportedby" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Reported By : -->
                            {{filtergetdata.reportedby}}
                        </button>
                        <button (click)="closeAdvanSearch(11)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>

                    <div *ngIf="filtergetdata.age" class="me-2 btn-group mb-2 d-flex  align-items-center" role="group"
                        aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Age : -->
                            {{filtergetdata.showname}}
                        </button>
                        <button (click)="closeAdvanSearch(12)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.recurring" class="me-2 btn-group mb-2 d-flex  align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d"
                            (click)="filterByDate()">Recurring</button>
                        <button (click)="closeAdvanSearch(16)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">

                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.cusgroupid"
                        class=" me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                        aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Date : -->
                            {{filtergetdata.groupname}}
                        </button>
                        <button (click)="closeAdvanSearch(17)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>
                    <div *ngIf="filtergetdata.branchid" class=" me-2 btn-group mb-2 d-flex flex-wrap align-items-center"
                        role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Date : -->
                            {{filtergetdata.branchname}}
                        </button>
                        <button (click)="closeAdvanSearch(18)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>


                    <div *ngIf="filterenddate && viewearilercheck == false"
                        class=" me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                            <!-- Date : -->
                            {{checkdate ? filterstartdateshow + ' -' : '' }} {{filterenddateshow}}
                        </button>
                        <button (click)="closeAdvanSearch(9)" type="button" class="btn btn-secondary"
                            style="background-color: #87929d;border-color:#87929d">
                            <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                        </button>
                    </div>


                    <div *ngIf="filtergetdata.ticketNo || filtergetdata.assignedtome == true || filtergetdata.picName || filtergetdata.priorityName
        || filtergetdata.productName || filtergetdata.modulename || filtergetdata.customerName || filtergetdata.projectName || filtergetdata.reportedby || filtergetdata.age || filterenddate  || filtergetdata.description || filtergetdata.detail || filtergetdata.refno && isMobileView"
                        class="reset me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                        aria-label="Basic example" (click)="removeDate()">
                        Reset
                    </div>
                </div>



                <div *ngIf="!customercare && !isMobileView" class="mb-2 me-2 " [matMenuTriggerFor]="internalmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{internalname}}
                    </button>
                </div>
                <mat-menu #internalmenu="matMenu" xPosition="before" class="menuproject">

                    <button style="line-height: 2.2 !important;" class="mat-item"
                        (click)="checkInternal('all')">All</button>
                    <button style="line-height: 2.2 !important;" class="mat-item"
                        (click)="checkInternal('exclude')">Customers</button>
                    <button style="line-height: 2.2 !important;" class="mat-item"
                        (click)="checkInternal('internal')">Internal </button>

                </mat-menu>
                <div *ngIf="customercare && !isMobileView" class="mb-2 me-2 d-flex flex-wrap align-items-center"
                    [matMenuTriggerFor]="customermenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeStatus()">
                        <!-- {{statusname != 'Status' ? 'Status : ' : '' }} {{statusname}} -->
                        {{statusname != 'Status' ? '' : '' }} {{statusname}}<span class="statusname"
                            *ngIf="statusnameList.length > 1 && customercare">+{{statusnameList.length - 1}}</span>
                    </button>
                </div>
                <mat-menu #customermenu="matMenu" id="service-menu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="statussearchcus"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;">
                    </div>
                    <button class="mat-item" (click)="statusChanges('status','index')">-</button>
                    <div class="mat-item "
                        *ngFor="let stu of statusListsCustomer  | crmFilter : statussearchcus:text1:text2:17; let index = index;"
                        (click)="$event.stopPropagation();">
                        <input [checked]="stu.check" class="form-check-input cus" type="checkbox" id="status{{index}}"
                            name="status" (click)="statusChangeCustomers(stu,index)">
                        <label for="status{{index}}" class="form-check-label cus mb-1 ms-1">
                            {{stu.name}}
                        </label>

                    </div>


                </mat-menu>
                <div *ngIf=" !customercare && !isMobileView" class="mb-2 me-2 d-flex flex-wrap align-items-center"
                    [matMenuTriggerFor]="orgmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeStatus()">
                        {{statusname != 'Status' ? '' : '' }} {{statusname}}<span class="statusname"
                            *ngIf="statusnameList.length > 1">+{{statusnameList.length - 1}}</span>
                    </button>
                </div>

                <mat-menu #orgmenu="matMenu" id="service-menu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="statussearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;">

                    </div>
                    <button class="mat-item" (click)="statusChanges('status','index')">-</button>
                    <!-- <button class="mat-item" *ngFor="let stu of statusLists | crmFilter : statussearch:text1:text2:19;" (click)="statusChanges(stu)"><input class="form-check-input cus" type="checkbox" id="status"
                                name="status">{{stu.name}}</button> matRipple-->

                    <div class="mat-item "
                        *ngFor="let stu of statusLists  | crmFilter : statussearch:text1:text2:17; let index = index;"
                        (click)="$event.stopPropagation();">
                        <input [checked]="stu.check" class="form-check-input cus" type="checkbox" id="status{{index}}"
                            name="status" (click)="statusChanges(stu,index)">
                        <label for="status{{index}}" class="form-check-label cus mb-1 ms-1">
                            {{stu.name}}
                        </label>


                    </div>


                </mat-menu>
                <div *ngIf="!isMobileView" class="mb-2 me-2 d-flex flex-wrap align-items-center"
                    [matMenuTriggerFor]="typemenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeService()">
                        {{typename != 'Type' ? ' ' : '' }}{{typename}}
                    </button>
                </div>

                <mat-menu #typemenu="matMenu" id="service-menu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="servicesearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;">

                    </div>
                    <button class="mat-item" (click)="seviceChanges('type')">-</button>
                    <button class="mat-item"
                        *ngFor="let item of servcietypelist | crmFilter : servicesearch:text1:text2:18;"
                        (click)="seviceChanges(item)">{{item.name}} (
                        {{item.shortcode}} )</button>
                </mat-menu>
                <div *ngIf="filtergetdata.ticketNo && !isMobileView"
                    class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Ticket No. : -->
                        {{filtergetdata.ticketNo}}
                    </button>

                    <button (click)="closeAdvanSearch(1)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>


                </div>
                <div *ngIf="filtergetdata.refno && !isMobileView"
                    class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Ref No. : -->
                        {{filtergetdata.refno}}
                    </button>

                    <button (click)="closeAdvanSearch(13)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>


                </div>


                <div *ngIf="filtergetdata.assignedtome == true && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        Assigned To Me
                    </button>
                    <button (click)="closeAdvanSearch(2)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">

                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.picName && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Assigned To :  -->
                        {{filtergetdata.picName}}
                    </button>
                    <button (click)="closeAdvanSearch(3)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.priorityName && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Priority : -->
                        {{filtergetdata.priorityName}}
                    </button>
                    <button (click)="closeAdvanSearch(4)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.projectName && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Project : -->
                        {{filtergetdata.projectName}}
                    </button>
                    <button (click)="closeAdvanSearch(5)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.description && !isMobileView"
                    class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Description : -->
                        {{filtergetdata.description}}
                    </button>

                    <button (click)="closeAdvanSearch(14)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>


                </div>
                <div *ngIf="filtergetdata.detail && !isMobileView"
                    class="btn-group me-2 mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Detail : -->
                        {{filtergetdata.detail}}
                    </button>

                    <button (click)="closeAdvanSearch(15)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>


                </div>
                <div *ngIf="filtergetdata.productName && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Product : -->
                        {{filtergetdata.productName}}
                    </button>
                    <button (click)="closeAdvanSearch(6)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.modulename && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Module : -->
                        {{filtergetdata.modulename}}
                    </button>
                    <button (click)="closeAdvanSearch(7)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.customerName && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Customer : -->
                        {{filtergetdata.customerName}}
                    </button>
                    <button (click)="closeAdvanSearch(8)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.reportedby && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Reported By : -->
                        {{filtergetdata.reportedby}}
                    </button>
                    <button (click)="closeAdvanSearch(11)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.age && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Age : -->
                        {{filtergetdata.showname}}
                    </button>
                    <button (click)="closeAdvanSearch(12)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.recurring && !isMobileView"
                    class="me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d"
                        (click)="filterByDate()">Recurring</button>
                    <button (click)="closeAdvanSearch(16)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">

                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.cusgroupid && !isMobileView"
                    class=" me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Date : -->
                        {{filtergetdata.groupname}}
                    </button>
                    <button (click)="closeAdvanSearch(17)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filtergetdata.branchid && !isMobileView"
                    class=" me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Date : -->
                        {{filtergetdata.branchname}}
                    </button>
                    <button (click)="closeAdvanSearch(18)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="filterenddate && viewearilercheck == false && !isMobileView "
                    class=" me-2 btn-group mb-2 d-flex flex-wrap align-items-center" role="group"
                    aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="filterByDate()">
                        <!-- Date : -->
                        {{checkdate ? filterstartdateshow + ' -' : '' }} {{filterenddateshow}}
                    </button>
                    <button (click)="closeAdvanSearch(9)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">
                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>

                <div *ngIf="!isMobileView " class="reset me-2 btn-group mb-2 d-flex flex-wrap align-items-center">
                    <div *ngIf=" filtergetdata.ticketNo || filtergetdata.assignedtome == true || filtergetdata.picName || filtergetdata.priorityName
                        || filtergetdata.productName || filtergetdata.modulename || filtergetdata.customerName || filtergetdata.projectName || filtergetdata.reportedby  || filtergetdata.age || filterenddate || filtergetdata.description || filtergetdata.detail || filtergetdata.refno "
                        role="group" aria-label="Basic example" (click)="removeDate()">
                        Reset
                    </div>
                </div>



            </div>

            <!-- <div class="d-flex flex-wrap">
    
                    <div *ngIf="!customercare && isMobileView" class="d-flex align-items-center me-2  mb-2 ">
                        <div class="form-check mrb" *ngIf="internal">
                            <input class="form-check-input" type="checkbox" id="ainternal1" name="open1" checked="checked" (change)="checkInternal($event)">
                            <label class="form-check-label ms-1" for="ainternal1">
                                Internal
                            </label>
                        </div>
                        <div class="form-check mrb" *ngIf="!internal">
                            <input class="form-check-input " type="checkbox" id="ainternal2" name="open2" (change)="checkInternal($event)">
                            <label class="form-check-label  ms-1" for="ainternal2">
                                Internal
                            </label>
                        </div>
                    </div>
                    <div *ngIf="!customercare && isMobileView" class="d-flex align-items-center me-2  mb-2 ">
                        <div class="form-check mrb" *ngIf="excludeinternal">
                            <input class="form-check-input" type="checkbox" id="einternal1" name="open1" checked="checked" (change)="checkExcludeInternal($event)">
                            <label class="form-check-label ms-1" for="einternal1">
                                Exclude Internal
                            </label>
                        </div>
                        <div class="form-check mrb" *ngIf="!excludeinternal">
                            <input class="form-check-input " type="checkbox" id="einternal2" name="open2" (change)="checkExcludeInternal($event)">
                            <label class="form-check-label  ms-1" for="einternal2">
                                Exclude Internal
                            </label>
                        </div>
                    </div>
                </div> -->


            <div class=" w-100   d-flex flex-wrap">
                <div class="w-100 d-flex flex-wrap ">

                    <div class="input-group mb-2 me-2" style="width: 300px !important;"
                        *ngIf="customercare == true && !isMobileView">
                        <input class="form-control" type="text" placeholder="&#xf002; Search with Ticket/Customer"
                            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
                            [(ngModel)]="customernamecare" (keyup)="searchWithTicketCustomerChange($event)"
                            [ngClass]="{'c-search-input-with-clear' : searchTextService }"
                            style="font-family: FontAwesome,Ubuntu;" disabled>
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextService"
                            (click)="clear(1)" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                        <!-- <mat-autocomplete #autocustomers="matAutocomplete">
                                <mat-option *ngFor="let cus of customers | crmFilter : searchTextService:text1:text2:11" [value]="cus.customername" (onSelectionChange)="customerSelect(cus)" (click)="customerSelect(cus)">
                                    {{cus.customername}} - {{cus.customermobile}}
                                </mat-option>
                            </mat-autocomplete> -->

                    </div>


                    <div class="input-group mb-2" *ngIf="isMobileView  && customercare == true ">
                        <input class="form-control" type="text" placeholder="&#xf002; Search with Ticket/Customer"
                            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
                            [(ngModel)]="searchTextService" (keyup)="searchWithTicketCustomerChange($event)"
                            [ngClass]="{'c-search-input-with-clear' : searchTextService }"
                            style="font-family: FontAwesome,Ubuntu;" [matAutocomplete]="autocustomers" disabled>
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextService"
                            (click)="clear(1)" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                        <mat-autocomplete #autocustomers="matAutocomplete">
                            <mat-option *ngFor="let cus of customers | crmFilter : searchTextService:text1:text2:11"
                                [value]="cus.customername" (onSelectionChange)="customerSelect(cus)"
                                (click)="customerSelect(cus)">
                                {{cus.customername}} - {{cus.customermobile}}
                            </mat-option>
                        </mat-autocomplete>

                    </div>

                    <div class="input-group mb-2" *ngIf="isMobileView  && customercare == false ">
                        <input class="form-control" type="text" placeholder="&#xf002; Search with Ticket/Customer"
                            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
                            [(ngModel)]="searchTextService" (keyup)="searchWithTicketCustomerChange($event)"
                            [ngClass]="{'c-search-input-with-clear' : searchTextService }"
                            style="font-family: FontAwesome,Ubuntu;" [matAutocomplete]="autocustomers"
                            (keyup.enter)="searchTicket(searchTextService)">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextService"
                            (click)="clear(1)" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                        <mat-autocomplete #autocustomers="matAutocomplete">
                            <mat-option *ngFor="let cus of customers | crmFilter : searchTextService:text1:text2:11"
                                [value]="cus.customername" (onSelectionChange)="customerSelect(cus)"
                                (click)="customerSelect(cus)">
                                {{cus.customername}} - {{cus.customermobile}}
                            </mat-option>
                        </mat-autocomplete>

                    </div>

                    <div class="input-group mb-2 me-2" style="width: 300px !important;"
                        *ngIf="!isMobileView && customercare == false">
                        <input [matAutocomplete]="autocustomer" class="form-control" type="text"
                            placeholder="&#xf002; Search with Ticket/Customer" aria-label="Search"
                            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchTextService"
                            [ngClass]="{'c-search-input-with-clear' : searchTextService }"
                            (keyup)="searchWithTicketCustomerChange($event)" style="font-family: FontAwesome,Ubuntu;"
                            (keyup.enter)="searchTicket(searchTextService)">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextService"
                            (click)="clear(1)" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                        <mat-autocomplete #autocustomer="matAutocomplete">
                            <mat-option *ngFor="let cus of customers | crmFilter : searchTextService:text1:text2:11"
                                [value]="cus.customername" (onSelectionChange)="customerSelect(cus)"
                                (click)="customerSelect(cus)">
                                {{cus.customername}} - {{cus.customermobile}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="dropdown mb-2 me-2 " *ngIf="!isMobileView">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="me-2 mb-2" *ngIf=" !isMobileView">
                        <div class="btn btn-custom " (click)="filterByDate()" matTooltip="Advanced Search">
                            <!-- <mat-icon>filter_alt</mat-icon> -->
                            <i class="fa fa-filter" aria-hidden="true"></i>
                            <!-- style="width:24px;height: 24px;font-size: 20px;line-height: 1.2;" -->
                        </div>
                    </div>
                    <div class=" mb-2" *ngIf="!isMobileView">
                        <button class="btn btn-custom" (click)="refresh()" matTooltip="Refresh"
                            [disabled]="!crmForm.orgid">
                            <!-- <span>Search</span> -->
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>



                    <!-- <div class="d-flex align-items-center mb-3 me-2" id="filterdate" *ngIf="filterstartdate && filterenddate">
                            <span class="datefilter" (click)="filterByDate()">
                                {{allinoneService.formatDBToShow(allinoneService.formatDate(filterstartdate))}} -
                                {{allinoneService.formatDBToShow(allinoneService.formatDate(filterenddate))}}
                            </span>
                            <mat-icon *ngIf="filterstartdate && filterenddate" class="remove-date" style="margin-left: 10px;cursor: pointer;" (click)="removeDate()">
                                close</mat-icon>
                        </div> -->


                    <div class="line mb-2" *ngIf="!isMobileView"></div>
                    <div class=" mb-2" *ngIf="!isMobileView">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Service Tickets To Excel" [disabled]="excelexportLoading">
                            <img *ngIf="!excelexportLoading" class="excel-icon" src="../../../assets/images/excel.png">
                            <span *ngIf="excelexportLoading" style="margin-right: 10px;"
                                class="spinner-border spinner-border-sm button-spinner" role="status"
                                aria-hidden="true"></span>
                            Export
                        </button>

                    </div>

                    <div class="line mb-2" *ngIf="!isMobileView"></div>

                    <!-- <div *ngIf="!customercare && !isMobileView" class="d-flex align-items-center me-2  mb-2 ">
                            <div class="form-check mrb" *ngIf="internal">
                                <input class="form-check-input" type="checkbox" id="ainternal1" name="open1" checked="checked" (change)="checkInternal($event)">
                                <label class="form-check-label  ms-1" for="ainternal1">
                                    Internal
                                </label>
                            </div>
                            <div class="form-check mrb" *ngIf="!internal">
                                <input class="form-check-input " type="checkbox" id="ainternal2" name="open2" (change)="checkInternal($event)">
                                <label class="form-check-label  ms-1" for="ainternal2">
                                    Internal
                                </label>
                            </div>
                        </div>
                        <div *ngIf="!customercare && !isMobileView" class="d-flex align-items-center me-2  mb-2 ">
                            <div class="form-check mrb" *ngIf="excludeinternal">
                                <input class="form-check-input" type="checkbox" id="einternal1" name="open1" checked="checked" (change)="checkExcludeInternal($event)">
                                <label class="form-check-label  ms-1" for="einternal1">
                                    Exclude Internal
                                </label>
                            </div>
                            <div class="form-check mrb" *ngIf="!excludeinternal">
                                <input class="form-check-input " type="checkbox" id="einternal2" name="open2" (change)="checkExcludeInternal($event)">
                                <label class="form-check-label  ms-1" for="einternal2">
                                    Exclude Internal
                                </label>
                            </div>
                        </div>
                        <div class="line mb-2" *ngIf="!isMobileView"></div> -->

                    <div class="d-flex align-items-center me-auto mb-2" *ngIf=" !isMobileView">
                        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total |
                            number }}</span>
                    </div>


                    <div *ngIf="!gettingData && !checkModeal && !isMobileView" class="d-flex align-items-center  mb-2 ">
                        <pagination-template *ngIf="data.length > 0 " #p="paginationApi"
                            (pageChange)="pageChange($event)">
                            <div class="d-flex align-items-center justify-content-center">
                                <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                                    (click)="p.previous()">
                                    <span class="me-1">&laquo;</span>&nbsp;Prev
                                </div>
                                <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                    <span class="page" (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() !== page.value">
                                        <span>{{ page.label }}</span>
                                    </span>
                                    <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                        <span>{{ page.label }}</span>
                                    </div>
                                </div>
                                <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                                    (click)="p.next()">
                                    Next&nbsp;<span class="ms-1">&raquo;</span>
                                </div>
                            </div>
                        </pagination-template>
                    </div>
                </div>

                <!-- <div class="d-flex align-items-center mb-3 me-2" id="advanceSearch" *ngIf="filtergetdata && filterenddate">
    
                            <span class="advanceText" (click)="filterByDate()">
                                <span *ngIf="filtergetdata.ticketNo" style="color: grey;margin-right: 10px;">
    
                                    <span style="font-size: 15px;
                             font-weight: 500;
                             color: black;">Ticket No. :</span> {{filtergetdata.ticketNo}} <span style="color: black">,</span>
                            </span>
                            <span *ngIf="filtergetdata.assignedtome == true" style="font-size: 15px;
                             font-weight: 500;
                             color: black;">Assigned To Me,
                                </span>
    
                            <span *ngIf="filtergetdata.picName" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                            font-weight: 500;
                            color: black;">Assigned To :</span> {{filtergetdata.picName}} <span style="color: black;">,</span>
                            </span>
                            <span *ngIf="filtergetdata.priorityName" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                            font-weight: 500;
                            color: black;">Priority :</span> {{filtergetdata.priorityName}} <span style="color: black;">,</span>
                            </span>
                            <span *ngIf="filtergetdata.projectName" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                        font-weight: 500;
                        color: black;">Project :</span> {{filtergetdata.projectName}} <span style="color: black;">,</span>
                            </span>
                            <span *ngIf="filtergetdata.productName" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                    font-weight: 500;
                    color: black;">Product :</span> {{filtergetdata.productName}} <span style="color: black;">,</span>
                            </span>
                            <span *ngIf="filtergetdata.modulename" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                font-weight: 500;
                color: black;">Module :</span> {{filtergetdata.modulename}} <span style="color: black;">,</span>
                            </span>
    
    
                            <span *ngIf="filtergetdata.customerName" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                             font-weight: 500;
                             color: black;">Customer :</span> {{filtergetdata.customerName}} <span style="color: black">,</span>
                            </span>
    
    
                            <span *ngIf="filterenddate" style="color: grey;margin-right: 10px;">
                                    <span style="    font-size: 15px;
                                font-weight: 500;
                                color: black;">Date :</span> {{filterenddate}}
                            </span>
                            </span>
                            <mat-icon *ngIf="filtergetdata && filterenddate" class="remove-date" style="margin-left: 10px;cursor: pointer;" (click)="removeDate()">
                                close</mat-icon>
                        </div> -->

            </div>







            <div>
                <div class="profile-container" *ngIf="gettingData && isMobileView">
                    <div *ngFor="let i of [1,2,3,4,5,6]" style="margin-bottom: 8px;">

                        <div class="profile-item justify-content-between" style="display: flex;">
                            <div>
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                              'height.px': 20,
                                              'width.px' : 80,
                                              'margin-bottom' : 0
                                          }"></ngx-skeleton-loader>
                            </div>
                            <!-- <div>
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                              'height.px': 20,
                                              'width.px' : 80,
                                              'margin-bottom' : 0
                                          }"></ngx-skeleton-loader>
                                    </div> -->
                        </div>
                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 20,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                        <div class="d-flex ">

                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 20,
                                'width.px': 140,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>


                <div *ngIf="isMobileView && gettingData == false">

                    <div class=" d-flex  "
                        *ngFor="let item of data |  paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index"
                        style="background-color: #eaeaea73;margin-bottom: 3px;">
                        <div class="" style="padding-left: 10px;padding-bottom: 7px;width: 100%;"
                            [ngClass]="{'medium':item.priority == '0010','normal':item.priority == '00500','high':item.priority == '00900'}"
                            (click)="editService(item)">
                            <div class="d-flex justify-content-between">
                                <div style="padding-top: 4px;font-size: 0.8rem;color: gray;">
                                    #{{item.ticketno}}

                                    <span *ngIf="item.reopen == 'true'">
                                        <!-- <span> -->
                                        <i class="fa fa-repeat" aria-hidden="true" style="color: red;font-size: 10px;"
                                            matTooltip="Recurring"></i>
                                    </span>
                                </div>
                                <div style="padding-right: 10px;padding-top: 4px;font-size: 0.8rem;color: gray;">
                                    {{item.startdatetime}}
                                </div>

                            </div>

                            <div id="descTextarea" style="padding-right: 10px;font-size: 0.9rem;">
                                {{item.servicedesc}}
                            </div>
                            <div style="    font-size: 0.8rem;
                                margin-bottom: 4px;
                                color: gray;
                            ">
                                {{item.version}}
                            </div>

                            <!-- <div>
    
                                    <button class="statusbutton" class="typeservcie"> {{item.typename}}</button>
                                </div> -->

                            <!-- <span *ngIf="item.type == '0010'" class="typeservcie" matTooltip="{{item.longtypename}}">{{item.typename}}</span>
                                <span *ngIf="item.type != '0010'" class="servcietype" matTooltip="{{item.longtypename}}">{{item.typename}}</span>
                                <span *ngIf="customercare" class="open-status" [ngClass]="{'cloased-status': item.status == '00900' }">{{item.status ==
                                    '0010' ? 'New' : item.status == '0040' ? 'Completed' : item.status ==
                                    '00900' ? 'Closed' : 'WIP'}}</span> -->
                            <div class="d-flex flex-wrap" style="margin-top: 2px;">
                                <!-- <div *ngIf="!customercare" style=" white-space: nowrap; 
                                    max-width: 200px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;margin-right: 4px; font-size: 11px;" class="open-status-mobile">
                                            {{item.statusname}}</div> -->

                                <span *ngIf="customercare" class="open-status-mobile" style="white-space: nowrap; 
                                        max-width: 200px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;margin-right: 4px; font-size: 11px;"
                                    [ngClass]="{'close-status-mobile': item.status == '00900' }">{{item.status ==
                                    '0010' ? 'New' : item.status == '0040' ? 'Completed' : item.status ==
                                    '00900' ? 'Closed' : 'WIP'}}</span>
                                <span *ngIf="!customercare" class="open-status-mobile mb-1" style="white-space: nowrap; 
                                        max-width: 200px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;margin-right: 4px; font-size: 11px;"
                                    [ngClass]="{'close-status-mobile': item.status == '00900' }">{{item.statusname}}</span>

                                <!-- <div class="priority" style="font-size: 11px;">
                                            {{item.typename}}
                                        </div> -->
                                <span *ngIf="item.type == '0010'" class="priority mb-1"
                                    style="color: red;box-shadow: inset 0 0 0 1px red;font-size: 11px;"
                                    matTooltip="{{item.longtypename}}">{{item.typename}}</span>
                                <span *ngIf="item.type != '0010'" class="priority mb-1" style="font-size: 11px;"
                                    matTooltip="{{item.longtypename}}">{{item.typename}}</span>

                                <div *ngIf="item.project != ''" class="priority mb-1" style="font-size: 11px;"
                                    matTooltip="Project">{{item.project}}</div>
                                <div *ngIf="item.product != ''" class="priority mb-1" style="font-size: 11px;"
                                    matTooltip="Product">{{item.product}}</div>
                                <div *ngIf="item.isinternal == 'false' && item.name != ''" class="d-flex priority mb-1">
                                    <!-- <span style="margin-top: -2px;">
    
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="17" height="17" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 512 512" xml:space="preserve"
                                            class=""><g><g id="Layer_16" data-name="Layer 16"><path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d" data-original="#87929d" class=""></path><path d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z" fill="#87929d" data-original="#87929d" class=""></path></g></g></svg>
                                        </span> -->
                                    <div *ngIf="item.name != ''" style="font-size: 11px;" matTooltip="Cusotmer">
                                        {{item.name}}</div>
                                </div>
                                <div *ngIf="item.isinternal == 'true' && item.name != ''" class="d-flex priority mb-1">
                                    <span style="margin-top: -2px;">

                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xmlns:svgjs="http://svgjs.com/svgjs" width="17" height="17" x="0" y="0"
                                            viewBox="0 0 32 32" style="enable-background:new 0 0 512 512"
                                            xml:space="preserve" class="">
                                            <g>
                                                <g id="Layer_16" data-name="Layer 16">
                                                    <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d"
                                                        data-original="#87929d" class=""></path>
                                                    <path
                                                        d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z"
                                                        fill="#87929d" data-original="#87929d" class=""></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <div *ngIf="item.name != ''" style="font-size: 11px;" matTooltip="Cusotmer">
                                        {{item.name}}</div>
                                </div>

                                <div *ngIf="item.name == '' && item.isinternal == 'true'" class="priority d-flex mb-1">

                                    <span *ngIf="item.isinternal == 'true'" matTooltip="Internal"
                                        style="margin-top: -1.4px;">

                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xmlns:svgjs="http://svgjs.com/svgjs" width="17" height="17" x="0" y="0"
                                            viewBox="0 0 32 32" style="enable-background:new 0 0 512 512"
                                            xml:space="preserve" class="">
                                            <g>
                                                <g id="Layer_16" data-name="Layer 16">
                                                    <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d"
                                                        data-original="#87929d" class=""></path>
                                                    <path
                                                        d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z"
                                                        fill="#87929d" data-original="#87929d" class=""></path>
                                                </g>
                                            </g>
                                        </svg>
                                        <!-- <img  class="" style="width: 10px;
                                    height: auto;
                                    margin-right: 10px;" src="../../../assets/images/internal.png"> -->
                                    </span>
                                    <!-- *ngIf="" -->
                                    <span>
                                        <div id="descTextarea" style="font-size: 11px;">Internal</div>
                                    </span>
                                </div>

                            </div>
                            <!-- style="white-space: nowrap; 
                                    width: 135px; 
                                    overflow: hidden;
                                    text-overflow: ellipsis; 
                                    " -->

                            <div>


                            </div>

                        </div>






                    </div>
                </div>





                <div matSort (matSortChange)="sortDataService($event)" class="table-responsive" *ngIf="!isMobileView">
                    <table class="table table-responsive table-borderless servicetable">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="ticketno"
                                    style="width: 80px;">Ticket
                                </td>
                                <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="description">Description
                                </td>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="customername">Customer /
                                    Contact
                                </td>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="status">Status</td>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="targetdatetime">Target Date
                                </td>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="duedatetime">Due Date
                                </td>
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="ageday">Age
                                </td>
                                <!-- targetdatetimeshow -->
                                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="reportedby">Reported By
                                </td>
                                <!-- <td scope="col" class="col-lg-1 col-md-1">Version
                                    </td> -->
                                <td scope="col" class=" d-flex justify-content-center">Action</td>

                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData">
                            <tr>
                                <td colspan="9" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="data.length == 0">
                                <td colspan="9" class="nodata">Empty</td>
                            </tr>
                            <tr
                                *ngFor="let item of data | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index">
                                <td (click)="editService(item)" style="padding-top: 5px;cursor: pointer;">
                                    <span>
                                        {{item.ticketno}}
                                    </span>
                                    <span *ngIf="item.reopen == 'true'">
                                        <!-- <span> -->
                                        <i class="fa fa-repeat" aria-hidden="true" style="color: red;font-size: 10px;"
                                            matTooltip="Recurring"></i>
                                    </span>
                                    <!-- *ngIf="item.reticketno != ''" -->

                                </td>
                                <td class="td-datas" (click)="editService(item)" style="cursor: pointer;">
                                    <span>
                                        <div id="descTextarea">{{item.servicedesc}}</div>

                                    </span>
                                    <div class="d-flex" style="margin-top: 5px;">
                                        <div *ngIf="item.priority != ''" class="priority"
                                            [ngClass]="{'priorityprimary': item.priority == '00500' ,'priorityred':item.priority == '00900' }">
                                            {{ item.priority == '0010' ? 'Low' : item.priority == '00500' ? 'High' :
                                            'Critical'}}
                                        </div>
                                        <div *ngIf="item.project != ''" class="priority" matTooltip="Project">
                                            {{item.project}}
                                        </div>
                                        <!-- <div *ngIf="item.type != ''" class="priority" matTooltip="Service Type">
                                                {{item.type}}
                                            </div> -->
                                        <div *ngIf="item.product != ''" class="priority" matTooltip="Product">
                                            {{item.product}}
                                        </div>
                                        <div *ngIf="item.version" class="priority" matTooltip="Version">
                                            {{item.version}}
                                        </div>

                                    </div>


                                </td>

                                <td *ngIf="!item.customerdefault && !isAdmin" (click)="editService(item)"
                                    style="cursor: pointer;">
                                    <span class="d-flex">

                                        <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0"
                                                viewBox="0 0 32 32" style="enable-background:new 0 0 512 512"
                                                xml:space="preserve" class="">
                                                <g>
                                                    <g id="Layer_16" data-name="Layer 16">
                                                        <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                        <path
                                                            d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        <span *ngIf="item.name == '' && item.isinternal == 'true'">
                                            <div id="descTextarea">Internal</div>
                                        </span>
                                        <span *ngIf="item.name">
                                            <div id="descTextarea">{{item.name}}</div>
                                        </span>
                                    </span>

                                    <div *ngIf="item.contacts != null">
                                        <span style="font-size: 13px;color:grey;"> {{item.contacts.name}}</span>

                                        <br>
                                    </div>
                                </td>
                                <td *ngIf="!item.customerdefault && isAdmin" class=" gocus" (click)="editService(item)"
                                    style="cursor: pointer;">
                                    <span class="d-flex">

                                        <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0"
                                                viewBox="0 0 32 32" style="enable-background:new 0 0 512 512"
                                                xml:space="preserve" class="">
                                                <g>
                                                    <g id="Layer_16" data-name="Layer 16">
                                                        <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                        <path
                                                            d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        <span *ngIf="item.name == '' && item.isinternal == 'true'">
                                            <div id="descTextarea">Internal</div>
                                        </span>
                                        <span *ngIf="item.name">
                                            <div id="descTextarea">{{item.name}}</div>
                                        </span>
                                    </span>

                                    <div *ngIf="item.contacts != null">
                                        <span style="font-size: 13px;color:grey;"> {{item.contacts.name}}</span>
                                        <br>
                                    </div>
                                </td>
                                <td *ngIf="item.customerdefault" class="" (click)="editService(item)"
                                    style="cursor: pointer;">
                                    <div class="d-flex">

                                        <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">

                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0"
                                                viewBox="0 0 32 32" style="enable-background:new 0 0 512 512"
                                                xml:space="preserve" class="">
                                                <g>
                                                    <g id="Layer_16" data-name="Layer 16">
                                                        <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                        <path
                                                            d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z"
                                                            fill="#87929d" data-original="#87929d" class=""></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <!-- <img  class="" style="width: 10px;
                                            height: auto;
                                            margin-right: 10px;" src="../../../assets/images/internal.png"> -->
                                        </span>
                                        <!-- *ngIf="" -->
                                        <span *ngIf="item.name == '' && item.isinternal == 'true'">
                                            <div id="descTextarea">Internal</div>
                                        </span>
                                        <span>
                                            <div id="descTextarea">{{item.name}}</div>

                                        </span>
                                    </div>

                                </td>

                                <td class="" (click)="editService(item)" style="cursor: pointer;">
                                    <div class="d-flex">
                                        <span *ngIf="item.type == '0010'" class="typeservcie"
                                            matTooltip="{{item.longtypename}}">{{item.typename}}</span>
                                        <span *ngIf="item.type != '0010'" class="servcietype"
                                            matTooltip="{{item.longtypename}}">{{item.typename}}</span>
                                        <span *ngIf="customercare" class="open-status"
                                            [ngClass]="{'closed-status-deal': item.status == '00900' }">{{item.status ==
                                            '0010' ? 'New' : item.status == '0040' ? 'Completed' : item.status ==
                                            '00900' ? 'Closed' : item.status ==
                                            '0060' ? 'UAT' : item.status == '0061' ? 'UAT - Failed': item.status ==
                                            '0062' ? 'UAT - Passed' : 'WIP'}}</span>
                                        <span *ngIf="!customercare" class="open-status "
                                            [ngClass]="{'closed-status-deal': item.status == '00900' }">{{item.statusname}}</span>

                                    </div>

                                    <span>{{item.startdatetime}} </span><span
                                        *ngIf="item.status == '00900' || item.status == '0040'">-
                                        {{item.enddatetime}}</span>

                                </td>
                                <td style="cursor: pointer;">
                                    {{item.targetdatetimeshow }}
                                </td>
                                <td style="cursor: pointer;">
                                    {{ item.duedatetime | date:'dd/MM/YYYY' }}
                                </td>
                                <td style="cursor: pointer;">
                                    <span>
                                        {{item.ageday}} day(s)
                                    </span>
                                </td>

                                <td style="cursor: pointer;" class="td-data"><span>{{item.reportedbyname}}</span></td>
                                <!-- <td class="" (click)="editService(item)"><span>{{item.version}}</span></td> -->

                                <td class="d-flex justify-content-center">
                                    <button matTooltip="Edit" class="btn btn-outline-primary"
                                        (click)="editService(item)">
                                        <i class='fa fa-edit'></i>
                                    </button>
                                    <button matTooltip="History" class="ms-2 btn btn-outline-primary"
                                        (click)="history(item)">
                                        <i class="fa fa-history"></i>
                                    </button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="!gettingData  && !checkModeal ">
                <pagination-template *ngIf="data.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template>

            </div>
        </div>




        <div *ngIf="leadAdd">
            <app-crm-lead-add [orgid]="crmForm.orgid" [businessType]="businessType" [isExcel]="isExcelLead"
                [currencyType]="currencyType" [editData]="dataForEdit" [statusList]="leadStatus"
                [industrytypelist]="industryList" [leadSourceList]="leadsourceList" (save)="complete()"
                (crmcancel)="cancel()">
            </app-crm-lead-add>
        </div>
        <div *ngIf="historyshow">
            <app-crm-ticket-history [orgid]="crmForm.orgid" [productlist]="productListdata"
                [statuslist]="servcietypelist" [historydata]="dataForEdit" (save)="complete()" (crmcancel)="cancel()"
                [customercare]="customercare">
            </app-crm-ticket-history>
        </div>


        <div *ngIf="serviceAdd">
            <app-crm-service-add (serviceadd)="backCustomer()" [checkOrg]="checkOrgB" [customercare]="customercare"
                [customerid]="customeridcare" [customercaredata]="getCustomercaredata" [orgid]="crmForm.orgid"
                [currencyType]="currencyType" [editData]="dataForEdit" [statuslist]="statusLists"
                [servielist]="servcietypelist" [priority]="priority" [projectList]="projectList" (save)="complete()"
                (servicecancel)="cancelservice($event)">
            </app-crm-service-add>
        </div>

        <div *ngIf="dealAdd">
            <app-crm-deal-add [orgid]="crmForm.orgid" [stageList]="stages" [currencyType]="currencyType"
                [fromCustomer]="false" [fromPipeline]="false" [editData]="dataForEdit" [statusList]="dealStatus"
                [customerStatus]="customerStatus" (save)="complete()" (crmcancel)="cancel()"></app-crm-deal-add>
        </div>

        <div *ngIf="customerAdd">
            <app-customers [orgid]="crmForm.orgid" [checkModeal]="checkModeal" [isExcel]="isExcel"
                [businessType]="businessType" [currencyType]="currencyType" [editData]="dataForEdit"
                [customerStatus]="customerStatus" [statusList]="dealStatus" [stageList]="stages" (save)="complete()"
                (crmcancel)="cancel()">
            </app-customers>
        </div>

        <!-- <div *ngIf="summaryCheck"> -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
                </app-crm-summary> -->
        <!-- app-crm-summary -->
        <!-- </div> -->
    </div>


</div>
<!-- </perfect-scrollbar> -->