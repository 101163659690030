import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss'],
})
export class DomainsComponent implements OnInit {
  domainAddorNot: boolean = false;
  saveLoading: boolean = false;
  domainListLoading: boolean = false;
  domainFormSubmitted: boolean = false;
  checkingMember: boolean = false;
  isEditing: boolean = false;

  domainListToShow: any[] = [];

  p: number = 1;

  domainForm = {
    id: '',
    description: '',
    domainid: '',
    shortcode: '',
  };

  orgImgURL: any;

  @ViewChild('fileInput') fileInput: any;

  constructor(
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public kunyekService: KunyekService,
    private router: Router
  ) {
    if (!this.allinoneService.isAdminOf('root')) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    this.getDomainList();
    // this.removeImage()
  }

  sortData(sort: Sort) {
    const data = this.domainListToShow;
    if (!sort.active || sort.direction === '') {
      this.domainListToShow = data;
      return;
    }

    this.domainListToShow = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'des':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'id':
          return this.allinoneService.compare(a.domainid, b.domainid, isAsc);
        case 'shortcode':
          return this.allinoneService.compare(a.shortcode, b.shortcode, isAsc);
        default:
          return 0;
      }
    });
  }

  cancel() {
    this.domainAddorNot = false;
    this.domainFormSubmitted = false;
    this.isEditing = false;
    this.domainForm = {
      id: '',
      description: '',
      shortcode: '',
      domainid: '',
    };
    this.saveLoading = false;
    this.removeImage();
  }

  addDomain() {
    this.domainAddorNot = true;
  }

  submitDomain() {
    this.domainFormSubmitted = true;
    if (
      this.domainForm.description != '' &&
      this.domainForm.shortcode != '' &&
      this.domainForm.domainid != ''
    ) {
      this.saveDomain();
    }
  }

  async saveDomain() {
    this.saveLoading = true;
    var data = {
      domainid: this.domainForm.domainid,
      shortcode: this.domainForm.shortcode,
      description: this.domainForm.description,
      imagename: this.fileName,
    };

    if (this.isEditing) {
      data = Object.assign(data, { id: this.domainForm.id });
    }

    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.updateDomain(data)
      : this.kunyekService.createDomain(data);

    fun.subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.fileName != '' && this.orgImgURL != this.imgURL) {
            if (this.fileSelected.size >= 307200) {
              this.fileSelected = await this.allinoneService.compressImage(
                this.fileSelected
              );
            }
            // const result = await this.allinoneService.newfileUploadToS3(
            //   this.fileSelected,
            //   this.fileName,
            //   'domain/'
            // );
            // if (result) {
            //   this.saveLoading = false;
            //   this.allinoneService.domains
            //     .filter(
            //       (domain: any) => domain.domainid == this.domainForm.domainid
            //     )
            //     .map((tempDomain: any) => {
            //       tempDomain.description = this.domainForm.description;
            //     });

            //   this.cancel();
            //   this.getDomainList();
            // } else {
            //   this.messageService.openSnackBar(
            //     'Error while uploading attachment.',
            //     'warn'
            //   );
            //   this.saveLoading = false;
            // }

            let bucketkey = 'domain/' + this.fileName;

            this.allinoneService
              .uploadFileToS3WithPresignedUrl(
                bucketkey,
                this.fileSelected,
                this.fileSelected.type
              )
              .subscribe({
                next: (success) => {
                  if (success) {
                    console.log('File upload completed:');
                    this.saveLoading = false;
                    this.allinoneService.domains
                      .filter(
                        (domain: any) =>
                          domain.domainid == this.domainForm.domainid
                      )
                      .map((tempDomain: any) => {
                        tempDomain.description = this.domainForm.description;
                      });

                    this.cancel();
                    this.getDomainList();
                  }
                },
                error: (err) => {
                  this.messageService.openSnackBar(
                    'Error while uploading attachment.',
                    'warn'
                  );
                  this.saveLoading = false;
                },
              });
          } else {
            this.saveLoading = false;
            // this.messageService.openSnackBar('SUCCESS.', 'success')
            //update domain  dropdown
            this.allinoneService.domains
              .filter(
                (domain: any) => domain.domainid == this.domainForm.domainid
              )
              .map((tempDomain: any) => {
                tempDomain.description = this.domainForm.description;
              });

            this.cancel();
            this.getDomainList();
          }
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  editDomain(item: any) {
    this.isEditing = true;
    this.domainForm = {
      id: item.id,
      description: item.description,
      shortcode: item.shortcode,
      domainid: item.domainid,
    };
    this.domainAddorNot = true;

    this.orgImgURL = item.imageurl;
    this.imgURL = item.imageurl;
    this.fileName = item.imagename;
    // console.log(this.imgURL);
  }

  getDomainList(): void {
    this.domainListLoading = true;
    this.kunyekService.getDomains().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.domainListToShow = res.domains;
          this.domainListLoading = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.domainListLoading = false;
      }
    );
  }

  viewAdmins() {
    this.router.navigateByUrl('/admins?domain=' + this.domainForm.domainid);
  }

  imgURL: any;
  // imagePath: any;
  fileName: any;
  fileSelected: any;

  uploadFile(event: any) {
    this.fileSelected = event[0];
    // console.log(this.fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  uploadFile1(event: any) {
    this.fileSelected = event.target.files[0];
    // console.log(fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }
}
