import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as S3 from 'aws-sdk/clients/s3';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { ImageProcessingService } from 'src/app/shared/services/image-processing-service.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss'],
})
export class UserProfileEditComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;

  profileSubmitted: boolean = false;
  saveLoading: boolean = false;

  user: any;
  fileName: any = '';
  fileSelected: any = '';
  userForm: any = {
    imgURL: '',
    username: '',
    userID: '',
    syskey: '',
    domain: '',
    domainid: '',
    nrc: '',
    state: '',
    region: '',
    township: '',
    address: '',
  };

  states: any = [];
  regions: any = [];
  townships: any = [];

  isBlocking: boolean = false;

  currentCount: number = 10;
  type: number = 2;
  currentStart: number = 0;
  currentEnd: number = 10;
  loading: boolean = false;

  error: boolean = false;

  uid: any;

  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    private imageProcessingService: ImageProcessingService,
    public dialogRef: MatDialogRef<UserProfileEditComponent>,
    private http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = data;

    // this.user = this.allinoneService.profile;
    this.userForm = {
      imgURL: this.user.imagename,
      username: this.user.username,
      userID: this.user.showuserid,
      syskey: this.user.syskey,
      domain: this.user.domain,
      domainid: this.user.domainid,
      nrc: this.user.nrc,
      state: this.user.state,
      region: this.user.region,
      township: this.user.township,
      address: this.user.address,
    };
  }

  ngOnInit(): void {}

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  uploadFile(event: any) {
    this.fileSelected = event[0];
    // console.log(this.fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.userForm.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
  }

  uploadFile1(event: any) {
    this.fileSelected = event.target.files[0];
    console.log(this.fileSelected);


    // if (this.fileSelected) {
    //   this.fileName = this.getFileName(this.fileSelected);
    //   this.imageProcessingService.removeMetadata(this.fileSelected).subscribe((sanitizedBlob) => {
    //     var sanitizedImageUrl = URL.createObjectURL(sanitizedBlob);
    //     this.userForm.imgURL = sanitizedImageUrl;
    //     this.fileSelected = new File([sanitizedBlob], this.fileSelected.name, { type: this.fileSelected.type });
    //     console.log(this.fileSelected);
    //   });
    // }

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.fileSelected);
    reader.onload = (_event) => {
      this.userForm.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.fileSelected);
    console.log(this.fileName);
  }

  removeImage() {
    this.fileName = '';
    this.fileSelected = '';
    this.userForm.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }

  cancel() {
    this.dialogRef.close(false);
  }


  submitProfile() {
    this.profileSubmitted = true;
    if (this.userForm.username == '') {
    } else {
      this.saveProfile();
    }
  }

  async saveProfile() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    let data = {
      syskey: this.userForm.syskey ? this.userForm.syskey : '',
      username: this.userForm.username,
      nrc: this.userForm.nrc,
      state: this.userForm.state,
      region: this.userForm.region,
      township: this.userForm.township,
      address: this.userForm.address,
      imagename: this.fileName ? this.fileName : '',
      domain: this.userForm.domain,
      domainid: this.userForm.domainid,
    };

    if (this.fileName != '') {
      if (this.fileSelected.size >= 102400) {
        this.fileSelected = await this.allinoneService.compressImage(
          this.fileSelected, 0.1
        );
        if (this.fileSelected == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.dialogRef.disableClose = false;
          this.saveLoading = false;
          return;
        }
      }
      // const result = await this.allinoneService.proffileUploadToS3(
      //   this.fileSelected,
      //   this.fileName,
      //   'user/'
      // );
      // if (result) {
      // } else {
      //   this.dialogRef.disableClose = false;
      //   this.messageService.openSnackBar(
      //     'Error while uploading attachment.',
      //     'warn'
      //   );
      // }

      let bucketkey = 'user/' + this.fileName + '/' + this.fileName;

      this.allinoneService.uploadFileToS3WithPresignedUrl(bucketkey, this.fileSelected, this.fileSelected.type).subscribe({
        next: (success) => {
          if (success) {
            console.log('File upload completed:');
          }
        },
        error: (err) => console.error('Error:', err),
      });
    }

    this.kunyekService.updateProfile(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.allinoneService.profile.imagename = res.imagename;
          this.allinoneService.profile.username = res.username;
          this.dialogRef.close(true);
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.dialogRef.disableClose = false;
        this.saveLoading = false;
      }
    );
  }
}
