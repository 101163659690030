import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Workbook } from 'exceljs';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-checkin-report',
  templateUrl: './checkin-report.component.html',
  styleUrls: ['./checkin-report.component.scss'],
})
export class CheckinReportComponent implements OnInit {
  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 500;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getList!: Subscription;
  getMemberSub!: Subscription;

  organizations: any = [];
  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  gettingMemberPosition: boolean = false;
  subscriptions = new SubSink();

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  minDate = new Date();
  d = new Date();
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  tempweekbefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBefore = new Date(this.tempweekbefore);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB(),
  );
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US',
  ).toString();

  gettingData: boolean = false;
  checkinList: any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  typeList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Time In/Out',
      value: 'inout',
    },
    {
      name: 'Check In',
      value: 'checkin',
    },
    {
      name: 'Activity',
      value: 'activity',
    },
  ];

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  openfilter: boolean = false;

  submitForm = {
    startdate: this.aweekBefore,
    enddate: new Date(Date.now()),
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    type: '',
    typename: 'All',
    status: '001',
  };

  // Socket
  socketConnectTime: any;
  public socketConnectionStatus: string = 'disconnected';
  private socket: WebSocket | undefined;
  connectionId: string = '';
  connectSocketLoading: boolean = true;
  public socketReceivedMessage: string = '';
  updateCId: boolean = false;
  drsocketfileurl: any = '';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private http: HttpClient,
    private filter: FilterPipe,
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  setLastDat() {
    // this.dateChange();
    var currentEndDate = formatDate(
      this.submitForm.startdate,
      this.piedateFormat,
      'en-US',
    ).toString();
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.submitForm.enddate > this.minDate ||
      this.submitForm.enddate < this.submitForm.startdate
    ) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.departmentList = res.departmentlist;
          // this.divisionList = res.divisionlist;
          // this.teamidList = res.teamidlist;
          for (var i = 0; i < res.departmentlist.length; i++) {
            this.departmentList.push(res.departmentlist[i]);
          }
          for (var i = 0; i < res.divisionlist.length; i++) {
            this.divisionList.push(res.divisionlist[i]);
          }
          for (var i = 0; i < res.teamidlist.length; i++) {
            this.teamidList.push(res.teamidlist[i]);
          }
          for (var i = 0; i < res.costcenterlist.length; i++) {
            this.costCenterList.push(res.costcenterlist[i]);
          }
          for (var i = 0; i < res.subdivision.length; i++) {
            this.subDivisionList.push(res.subdivision[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn',
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      },
    );
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  typeChange(type: any) {
    if (this.submitForm.type != type.value) {
      this.submitForm.type = type.value;
      this.submitForm.typename = type.name;
    }
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(
      () => {
        if (this.socketConnectionStatus != 'disconnected') {
          this.socket?.close(3001);
          this.connectionId = '';
          this.messageService.openSnackBar('Request Time Out', 'warn');
          this.searchLoading = false;
        }
      },
      this.allinoneService.reportSocketTimeoutMins * 60 * 1000,
    );
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '008',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getCheckinReport();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            this.checkinList = res;
            this.searchLoading = false;
            this.connectionId = '';
            this.socket?.close(3001);
            clearTimeout(this.socketConnectTime);
          });
        }
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  getCheckinReport() {
    if (!this.submitForm.startdate) {
      return this.messageService.openSnackBar(
        'Start Date cannot be blank.',
        'warn',
      );
    }
    if (!this.submitForm.enddate) {
      return this.messageService.openSnackBar(
        'End Date cannot be blank.',
        'warn',
      );
    }
    this.searchLoading = true;
    var data = {
      orgid: this.orgid,
      date: '',
      startdate: this.allinoneService.parseDate(this.submitForm.startdate),
      enddate: this.allinoneService.parseDate(this.submitForm.enddate),
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
      type: this.submitForm.type,
      connectionid: this.connectionId,
      activestatus: this.openfilter ? this.submitForm.status : '001',
    };
    console.log('api call data');
    console.log(data);

    this.getList && this.getList.unsubscribe();
    this.getList = this.kunyekService.getCheckinReport(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          // this.timecardList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn',
          );
          this.searchLoading = false;
        }
      },
      (err: any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.searchLoading = false;
      },
    );
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  downloadSheet() {
    var startd = formatDate(
      new Date(this.submitForm.startdate),
      this.formatToShow,
      'en-US',
    ).toString();
    var endd = formatDate(
      new Date(this.submitForm.enddate),
      this.formatToShow,
      'en-US',
    ).toString();
    var name = 'Attendance Report' + '(' + startd + '-' + endd + ').xlsx';
    var exdata: any = [];
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');
    var filteredLocData = this.filter.transform(
      this.checkinList,
      this.searchText,
      15,
    );
    filteredLocData.map((item: any) => {
      var temp = {
        EmployeeID: item.employeeid,
        UserID: item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        TeamID: item.teamid,
        CostCenter: item.costcenter,
        Type:
          item.iotype == 'in'
            ? 'Time In'
            : item.iotype == 'out'
              ? 'Time Out'
              : item.iotype == 'checkin'
                ? 'Check In'
                : 'Activity',
        Date: this.allinoneService.formatDBToShow(item.date),
        Time:
          this.allinoneService.formatTimeToShow(item.starttime) +
          (item.iotype == 'activity'
            ? ' - ' + this.allinoneService.formatTimeToShow(item.endtime)
            : ''),
        Location:
          item.iotype == 'activity'
            ? ''
            : (item.location == '' && item.lat == '' && item.long == ''
                ? 'Unknown Location'
                : item.loctype == '1' ||
                    item.loctype == '2' ||
                    item.loctype == '3' ||
                    item.loctype == '5'
                  ? '@'
                  : item.loctype == '4'
                    ? '#'
                    : '') + item.location,
        LatLong:
          item.iotype == 'activity'
            ? ''
            : item.lat != '' && item.long != ''
              ? item.lat + ',' + item.long
              : '',
        Description: item.description,
        iotype: item.iotype,
        loctype: item.loctype,
      };
      exdata.push(temp);
    });
    workSheet.addRow([
      'Employee ID',
      'User ID',
      'Name',
      'Department',
      'Division',
      'TeamID',
      'Cost Center',
      'Type',
      'Date',
      'Time',
      'Location',
      'LatLong',
      'Description',
    ]);
    // set a minimun width of each column
    workSheet.columns.forEach((column) => {
      column.width = 15;
    });

    // set title row bold
    workSheet.getRow(1).font = { bold: true };
    exdata.forEach((item: any) => {
      const row = workSheet.addRow([
        item.EmployeeID,
        item.UserID,
        item.Name,
        item.Department,
        item.Division,
        item.TeamID,
        item.CostCenter,
        item.Type,
        item.Date,
        item.Time,
        item.Location,
        item.LatLong,
        item.Description,
      ]);

      const col = row.getCell(11);
      col.font = {
        color: {
          argb:
            (item.iotype != 'activity' && item.loctype == '3') ||
            item.iotype == 'activity'
              ? '000000'
              : 'FF0000',
        },
      };
      const col2 = row.getCell(12);
      col2.font = {
        color: {
          argb:
            (item.iotype != 'activity' && item.loctype == '3') ||
            item.iotype == 'activity'
              ? '000000'
              : 'FF0000',
        },
      };
    });
    workBook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, name);
    });
  }

  sortData(sort: Sort) {
    const data = this.checkinList;
    if (!sort.active || sort.direction === '') {
      this.checkinList = data;
      return;
    }
    this.checkinList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'department') {
        return this.allinoneService.compare(a.department, b.department, isAsc);
      } else if (sort.active == 'division') {
        return this.allinoneService.compare(a.division, b.division, isAsc);
      } else if (sort.active == 'type') {
        return this.allinoneService.compare(a.iotype, b.iotype, isAsc);
      } else if (sort.active == 'date') {
        return this.allinoneService.compare(a.date, b.date, isAsc);
      } else if (sort.active == 'time') {
        return this.allinoneService.compare(a.starttime, b.starttime, isAsc);
      }
      return 0;
    });
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      // ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
  }

  clear() {
    this.searchText = '';
  }

  trackByKey(index: any, item: any) {
    return item.syskey;
  }
}
