<app-crm-breadcrumb
  *ngIf="!customercare"
  s_breadcrumb="Service Tickets"
  [t_breadcrumb]="isEditing ? 'Edit' : 'New'"
  (emit)="cancelFunction()"
>
</app-crm-breadcrumb>
<app-crm-breadcrumb
  s_breadcrumb="Self Service"
  [t_breadcrumb]="'Service Tickets'"
  [f_breadcrumb]="isEditing ? 'Edit' : 'New'"
  *ngIf="customercare"
  (emit)="goCrm()"
  (emit1)="cancelFunction()"
>
</app-crm-breadcrumb>
<!-- <app-crm-breadcrumb *ngIf="customercare" [s_breadcrumb]="Self Service" [s_breadcrumb]="Self Service" [f_breadcrumb]="isEditing ? 'Edit' : 'New'" >
</app-crm-breadcrumb> -->
<!-- <app-crm-breadcrumb *ngIf="isMove" s_breadcrumb="Services" [t_breadcrumb]="'Move'" (emit)="cancelFunction()">
</app-crm-breadcrumb> -->

<div class="close-save-button mb-3">
  <button
    type="button"
    class="btn btn-custom me-2"
    (click)="cancelFunction()"
    *ngIf="!saveLoading && !isMove"
  >
    Cancel
  </button>

  <!-- <button type="button" class="btn btn-custom me-2" (click)="cancelMove()" *ngIf="!saveLoading && isMove">
        Cancel
    </button> -->
  <div *ngIf="!editsavecheck">
    <app-button-loader
      *ngIf="!statuscheck"
      (click)="submitService()"
      [button_text]="'Save'"
      [load_button_text]="'Saving'"
      [isLoading]="saveLoading"
    >
    </app-button-loader>
    <button *ngIf="statuscheck" class="btn btn-custom" type="button" disabled>
      Save
    </button>
  </div>

  <div *ngIf="editsavecheck">
    <button class="btn btn-custom" type="button" disabled>Save</button>
  </div>
  <!-- <div class="line mb-2" *ngIf="isEditing && !isMove && serviceForm.status != '00900' "></div> -->
  <!-- <app-button-loader *ngIf="isMove" (click)="submitMoveService()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
    </app-button-loader> -->

  <!-- <button *ngIf="isEditing && !isMove && serviceForm.status != '00900'" type="button" class="btn btn-custom  btn-outline me-auto" (click)="move()">
        Move
    </button> -->
  <!-- <button *ngIf="isEditing && serviceForm.status != '00900'" type="button" class="btn btn-outline-danger closetick" (click)="closeTicket()">
        Close Ticket
    </button> -->
  <!-- <div *ngIf="isEditing && serviceForm.status != '00900'  && !isMove">

        <button type="submit" [disabled]="closetyploading" *ngIf="closetyploading" class="btn btn-outline-danger closetick justify-content-center">
        <span class="spinner-border spinner-border-sm button-spinner" style="color: red !important;" role="status" aria-hidden="true"></span>
        <span class="mx-1">Closing</span>
    </button>

        <button type="submit" *ngIf="!closetyploading" class="btn btn-outline-danger closetick justify-content-center" (click)="closeTicket()">
        Close Ticket
    </button>
    </div> -->
</div>

<div *ngIf="!isMove">
  <div class="serviceinfo">
    <div class="card card-body my-card">
      <div class="info">Service Info</div>
      <div class="servcie">
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="customer" class="form-label">Ticket No.</label>
            <input
              type="text"
              class="form-control"
              id="tickeno"
              name="tickeno"
              [(ngModel)]="serviceForm.tickeno"
              #ref="ngModel"
              disabled
            />
          </div>
          <div class="mb-3 col-md-6">
            <label for="customer" class="form-label">Ref No.</label>
            <input
              type="text"
              class="form-control"
              id="tickeno"
              name="tickeno"
              [(ngModel)]="serviceForm.refno"
              #ref="ngModel"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3">
            <label for="remark" class="form-label">Description</label>
            <textarea
              class="form-control"
              id="description"
              name="description"
              [(ngModel)]="serviceForm.description"
              rows="3"
              #desc="ngModel"
              [ngClass]="{ 'is-invalid': serviceFormSubmitted && desc.invalid }"
              required
            ></textarea>
            <div
              class="invalid-feedback"
              *ngIf="
                desc.invalid ||
                ((desc.dirty || desc.touched) && serviceFormSubmitted)
              "
            >
              <div *ngIf="desc.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="ref" class="form-label">Service Type</label>
            <!-- <label for="ref" *ngIf="isdomainAdmin" class="form-label servicetypes d-flex align-items-center justify-content-start" (click)="serviceFromAdd()">Service Type <span class="icon"><i
                                    class='fa fa-edit'></i></span></label> -->
            <ng-select
              class="role-select"
              [items]="servielist"
              bindLabel="name"
              placeholder="-"
              appendTo="body"
              [multiple]="false"
              [(ngModel)]="servicetypelist"
              (change)="serviceTypechange($event)"
              required
              #type="ngModel"
              [ngClass]="{
                'service-select-invalid':
                  servicetypelist.length == 0 && serviceFormSubmitted
              }"
            >
            </ng-select>

            <!-- [disabled]="getServiceType" -->
            <!-- <select class="form-select" aria-label="Select type" id="type" name="type"
                            [(ngModel)]="serviceForm.servicetype" required #type="ngModel"
                            [ngClass]="{ 'is-invalid': serviceFormSubmitted && type.invalid }"
                            [disabled]="getServiceType">
                            <option value=''>Select Service Type</option>
                            <option *ngFor="let type of servielist;trackBy: trackByServicetype" value="{{type.code}}">
                                {{type.name}}</option>
                        </select> -->
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getServiceType"
            ></mat-progress-bar>
            <!-- <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && serviceFormSubmitted">
                            <div *ngIf="type.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div> -->
            <div
              class="invalid-ngselect"
              *ngIf="servicetypelist.length == 0 && serviceFormSubmitted"
            >
              <div>
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>

          <div class="mb-3 col-md-6" *ngIf="!customercare && !statuscheck">
            <div class="d-flex">
              <label for="ref" class="form-label me-auto">Status </label>
              <div class="" *ngIf="serviceForm.status == '00900'">
                <div class="form-check cus" *ngIf="!checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
                <div class="form-check cus" *ngIf="checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    checked="checked"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
              </div>
            </div>

            <ng-select
              class="role-select"
              [items]="statuslist"
              bindLabel="name"
              placeholder="-"
              appendTo="body"
              [multiple]="false"
              [(ngModel)]="statusnew"
              (change)="statuschange($event)"
              required
              #type="ngModel"
              [ngClass]="{
                'service-select-invalid':
                  statusnew.length == 0 && serviceFormSubmitted
              }"
            >
            </ng-select>
            <!-- <select class="form-select" aria-label="Select type" id="status" name="status" [(ngModel)]="serviceForm.status" (change)="statuscodeChange($event)" [disabled]="getSatausType ">
                            <option *ngFor="let sta of statuslist; trackBy: trackByStatusCode" value="{{sta.code}}"
                                selected="sta.code == serviceForm.status">
                                {{sta.name}}</option>
                        </select> -->
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getSatausType"
            ></mat-progress-bar>
            <div
              class="invalid-ngselect"
              *ngIf="statusnew.length == 0 && serviceFormSubmitted"
            >
              <div>
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>

          <div
            class="mb-3 col-md-6"
            *ngIf="!statuscheck && customercare && isEditing == false"
          >
            <div class="d-flex">
              <label for="ref" class="form-label me-auto">Status </label>
              <div class="" *ngIf="serviceForm.status == '00900'">
                <div class="form-check cus" *ngIf="!checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
                <div class="form-check cus" *ngIf="checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    checked="checked"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
              </div>
            </div>
            <select
              class="form-select"
              aria-label="Select type"
              id="status"
              name="status"
              [(ngModel)]="serviceForm.status"
              (change)="statuscodeChange($event)"
              [disabled]="getSatausType"
            >
              <option
                *ngFor="
                  let sta of statuslistCustomercare;
                  trackBy: trackByStatusCode
                "
                value="{{ sta.code }}"
                selected="sta.code == serviceForm.status"
              >
                {{ sta.name }}
              </option>
            </select>

            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getSatausType"
            ></mat-progress-bar>
          </div>
          <div
            class="mb-3 col-md-6"
            *ngIf="
              editStatus &&
              !statuscheck &&
              customercare &&
              isEditing == true &&
              (serviceForm.status == '0010' || serviceForm.status == '00900')
            "
          >
            <div class="d-flex">
              <label for="ref" class="form-label me-auto">Status </label>
              <div class="" *ngIf="serviceForm.status == '00900'">
                <div class="form-check cus" *ngIf="!checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
                <div class="form-check cus" *ngIf="checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    checked="checked"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
              </div>
            </div>

            <select
              class="form-select"
              aria-label="Select type"
              id="status"
              name="status"
              [(ngModel)]="serviceForm.status"
              (change)="statuscodeChange($event)"
              [disabled]="getSatausType"
            >
              <option
                *ngFor="
                  let sta of statuslistCustomercare;
                  trackBy: trackByStatusCode
                "
                value="{{ sta.code }}"
                selected="sta.code == serviceForm.status"
              >
                {{ sta.name }}
              </option>
            </select>

            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getSatausType"
            ></mat-progress-bar>
          </div>
          <div class="mb-3 col-md-6" *ngIf="statuscheck">
            <div class="d-flex">
              <label for="ref" class="form-label me-auto">Status </label>
              <div class="" *ngIf="serviceForm.status == '00900'">
                <div class="form-check cus" *ngIf="!checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
                <div class="form-check cus" *ngIf="checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    checked="checked"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
              </div>
            </div>

            <select
              class="form-select"
              aria-label="Select type"
              id="status"
              name="status"
              [disabled]="statuscheck"
            >
              <option value="00900">Closed</option>
            </select>
          </div>

          <div
            class="mb-3 col-md-6"
            *ngIf="
              !editStatus &&
              !statuscheck &&
              customercare &&
              isEditing == true &&
              serviceForm.status != '0010'
            "
          >
            <div class="d-flex">
              <label for="ref" class="form-label me-auto">Status </label>
              <div class="" *ngIf="serviceForm.status == '00900'">
                <div class="form-check cus" *ngIf="!checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
                <div class="form-check cus" *ngIf="checkvoid">
                  <input
                    class="form-check-input cus"
                    type="checkbox"
                    id="void"
                    name="void"
                    checked="checked"
                    (change)="voidcheck($event)"
                  />
                  <label class="form-check-label cus mb-1 ms-1" for="void">
                    Void
                  </label>
                </div>
              </div>
            </div>

            <select
              class="form-select"
              aria-label="Select type"
              id="status"
              name="status"
              [(ngModel)]="statuscustomercode"
              (change)="statuscodeChange($event)"
              [disabled]="getSatausType"
            >
              <!--   -->
              <option
                *ngIf="
                  statuscustomercode != '0060' &&
                  statuscustomercode != '0061' &&
                  statuscustomercode != '0062' &&
                  statuscustomercode != '00900' &&
                  statuscustomercode != '0040'
                "
                value="{{ statuscustomercode }}"
              >
                WIP
              </option>

              <option
                *ngFor="
                  let data of customerstatusList;
                  trackBy: trackByStatusCode
                "
                value="{{ data.code }}"
              >
                {{ data.name }}
              </option>
            </select>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getSatausType"
            ></mat-progress-bar>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-md-12">
            <!-- <label for="ref" class="form-label" (click)="Priority()">Priority</label> -->
            <div class="d-flex align-items-center">
              <div class="form-check me-5" *ngFor="let prio of priority">
                <label class="radio-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="priority"
                    [id]="prio.code"
                    #priority="ngModel"
                    [(ngModel)]="serviceForm.priority"
                    [value]="prio.code"
                    [checked]="prio.code == serviceForm.priority"
                  />
                  {{ prio.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <!-- isProduct -->
            <label
              *ngIf="(!isProduct && !isdomainAdmin) || customercare"
              for="project"
              class="form-label"
              >Product
            </label>
            <label
              *ngIf="isProduct && !isdomainAdmin && !customercare"
              for="project"
              class="form-label project"
              (click)="productFormAdd()"
              >Product</label
            >
            <label
              *ngIf="!isProduct && isdomainAdmin && !customercare"
              for="project"
              class="form-label project"
              (click)="productFormAdd()"
              >Product</label
            >
            <!-- <div  class="d-flex">
                            <label for="project" class="form-label">Product </label>
                            <span>(Custom)</span>

                        </div>
                        <div  class="d-flex">
                            <label for="project" class="form-label project" (click)="productFormAdd()">Product</label>
                            <span>(Custom)</span>
                        </div>
                        <div  class="d-flex">
                            <label for="project" class="form-label project" (click)="productFormAdd()">Product</label>
                            <span>(Custom)</span>
                        </div> -->
            <div>
              <ng-select
                class="role-select"
                [items]="productListdata"
                bindLabel="name"
                bindValue="productid"
                placeholder="-"
                appendTo="body"
                [multiple]="false"
                [(ngModel)]="serviceForm.productid"
                #product="ngModel"
                (change)="productSelect($event)"
              >
              </ng-select>
              <mat-progress-bar
                mode="indeterminate"
                *ngIf="getProductLoading"
              ></mat-progress-bar>
              <!-- <div class="invalid-ngselect" *ngIf="product.invalid  && serviceFormSubmitted">
                                <div>
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div> -->
              <!-- <input type="text" aria-label="Number" class="form-control" [(ngModel)]="productValue" (ngModelChange)="productChange()" (keyup.enter)="productChange()" [matAutocomplete]="autoproduct" required #product="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && product.invalid }">
                            <mat-autocomplete #autoproduct="matAutocomplete">
                                <mat-option *ngFor="let pic of productListdata | crmFilter :productValue:text1:text2:8" [value]="pic.name" (optionSelected)="productSelect(pic)" (onSelectionChange)="productSelect(pic)" (click)="productSelect(pic)">
                                    {{pic.name == '' ? pic.userid : pic.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-progress-bar mode="indeterminate" *ngIf="getProductLoading"></mat-progress-bar>
                            <div class="invalid-feedback" *ngIf="product.invalid || (product.dirty || product.touched) && serviceFormSubmitted">
                                <div *ngIf="product.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div> -->
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <!-- isProject -->
            <label
              *ngIf="(!isProject && !isdomainAdmin) || customercare"
              for="project"
              class="form-label"
              >Project</label
            >
            <label
              *ngIf="!isProject && isdomainAdmin && !customercare"
              for="project"
              class="form-label project"
              (click)="projectForm()"
              >Project</label
            >
            <label
              *ngIf="isProject && !isdomainAdmin && !customercare"
              for="project"
              class="form-label project"
              (click)="projectForm()"
              >Project</label
            >
            <div>
              <!-- <select class="form-select" aria-label="Select Project" id="project" name="project" [(ngModel)]="serviceForm.project" (change)="selectProject($event)" #project="ngModel">
                                <option selected value=""> Select Project</option>

                                <option *ngFor="let item of projectList" value="{{item.projectid}}">{{item.name}}
                                </option>
                            </select> -->
              <input
                type="text"
                aria-label="Number"
                class="form-control"
                [(ngModel)]="projectValue"
                (ngModelChange)="projectChange()"
                [matAutocomplete]="autoproject"
              />
              <mat-autocomplete #autoproject="matAutocomplete">
                <mat-option
                  *ngFor="
                    let pic of projectList
                      | crmFilter : projectValue : text1 : text2 : 9
                  "
                  [value]="pic.name"
                  (onSelectionChange)="projectSelect(pic)"
                  (click)="projectSelect(pic)"
                >
                  {{ pic.name }}
                </mat-option>
              </mat-autocomplete>

              <mat-progress-bar
                mode="indeterminate"
                *ngIf="gettingProjectList"
              ></mat-progress-bar>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modulecheck">
          <div class="mb-3 col-md-6">
            <label for="modules" class="form-label">Module</label>

            <div>
              <ng-select
                class="role-select"
                [items]="modulelist"
                bindLabel="description"
                placeholder="-"
                appendTo="body"
                [multiple]="false"
                [(ngModel)]="moduleidList"
                (change)="modulePicchange($event)"
              >
              </ng-select>
              <!-- <select class="form-select" aria-label="Select type" id="module" name="module" [(ngModel)]="serviceForm.moduleid" (change)="modulePicchange($event)">
                                <option value=''>-</option>
                                <option *ngFor="let sta of modulelist" value="{{sta.moduleid}}">
                                    {{sta.description}}</option>
                            </select> -->
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="mb-3 col-md-6" *ngIf="customercare">
                        <div *ngIf="isEditing">
                     
                            <label *ngIf="!isPic && !isdomainAdmin || customercare" for="project"
                                class="form-label">Assigned To</label>
                            <label *ngIf="!isPic &&  isdomainAdmin && !customercare" for="project"
                                class="form-label project" (click)="assignedForm()">Assigned To</label>
                            <label *ngIf="isPic &&  !isdomainAdmin && !customercare" for="project"
                                class="form-label project" (click)="assignedForm()">Assigned To</label>
                            <input type="text" aria-label="Number" matInput class="form-control"
                                (ngModelChange)="picChange()" [matAutocomplete]="autoassigned" [(ngModel)]="picValue"
                                name="pic" id="pic" #pic="ngModel" [disabled]="isEditing && customercare">
                            <mat-autocomplete #autoassigned="matAutocomplete">
                                <mat-option *ngFor="let pic of  | crmFilter : picValue:text1:text2:10"
                                    [value]="pic.name" (onSelectionChange)="picSelect(pic)" (click)="picSelect(pic)">
                                    {{pic.name == '' ? pic.userid : pic.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div> -->
          <div class="mb-3 col-md-6" *ngIf="!customercare">
            <div class="d-flex">
              <div class="me-3">
                <label
                  *ngIf="(!isPic && !isdomainAdmin) || customercare"
                  for="project"
                  class="form-label"
                  >Assigned To</label
                >
                <label
                  *ngIf="!isPic && isdomainAdmin && !customercare"
                  for="project"
                  class="form-label project"
                  (click)="assignedForm()"
                  >Assigned To</label
                >
                <label
                  *ngIf="isPic && !isdomainAdmin && !customercare"
                  for="project"
                  class="form-label project"
                  (click)="assignedForm()"
                  >Assigned To</label
                >
              </div>
              <div style="cursor: pointer">
                <div
                  class="domain-dd"
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="assmenus"
                >
                  <div class="d-flex align-items-center">
                    <div class="org-name" style="font-size: 11px">
                      {{ assignedtoname }}
                    </div>
                    <i
                      class="fas fa-caret-down"
                      style="color: black; margin-left: 5px"
                    ></i>
                  </div>
                </div>

                <mat-menu
                  #assmenus="matMenu"
                  class="org-menu"
                  xPosition="before"
                >
                  <button mat-menu-item (click)="assignedChanges('all')">
                    All
                  </button>
                  <button
                    mat-menu-item
                    (click)="assignedChanges('product')"
                    *ngIf="serviceForm.productid"
                  >
                    By Product
                  </button>
                  <button
                    mat-menu-item
                    (click)="assignedChanges('project')"
                    *ngIf="serviceForm.projectid"
                  >
                    By Project
                  </button>
                </mat-menu>
              </div>
            </div>

            <ng-select
              [ngModelOptions]="{ standalone: true }"
              class="role-select"
              [items]="picList"
              bindLabel="name"
              placeholder="-"
              appendTo="body"
              [multiple]="true"
              [(ngModel)]="ngassignedtolist"
              (change)="picSelect($event)"
            >
            </ng-select>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="getAssignto"
            ></mat-progress-bar>
            <!-- [ngClass]="{ 'role-select-invalid': reqTypeForm.roleList.length == 0 && reqTypeFormSubmitted }" -->
            <!-- <input type="text" aria-label="Number" matInput class="form-control" (ngModelChange)="picChange()" [matAutocomplete]="autoassigned" [(ngModel)]="picValue" name="pic" id="pic" #pic="ngModel" [disabled]="isEditing && customercare">
                        <mat-autocomplete #autoassigned="matAutocomplete">
                            <mat-option *ngFor="let pic of picList | crmFilter : picValue:text1:text2:10" [value]="pic.name" (onSelectionChange)="picSelect(pic)" (click)="picSelect(pic)">
                                {{pic.name == '' ? pic.userid : pic.name}} <span *ngIf="pic.name != '' && pic.userid != ''">-</span> {{ pic.name != '' && pic.userid != '' ? pic.userid : ''}}
                            </mat-option>
                        </mat-autocomplete> -->
          </div>

          <div class="mb-3 col-md-6" *ngIf="!customercare">
            <label for="project" class="form-label">Version</label>

            <input
              type="text"
              class="form-control"
              id="version"
              name="version"
              [(ngModel)]="serviceForm.version"
            />
          </div>
          <div class="mb-3 col-md-12" *ngIf="isEditing && customercare">
            <label for="project" class="form-label">Version</label>
            <input
              type="text"
              class="form-control"
              id="version"
              name="version"
              [(ngModel)]="serviceForm.version"
            />
          </div>
          <div class="mb-3 col-md-12" *ngIf="!isEditing && customercare">
            <label for="project" class="form-label">Version</label>

            <input
              type="text"
              class="form-control"
              id="version"
              name="version"
              [(ngModel)]="serviceForm.version"
            />
          </div>
        </div>

        <div class="row mb-3" *ngIf="!customercare">
          <div class="col-md-12">
            <label for="mer" class="form-label">MER</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="mer"
                name="mer"
                [(ngModel)]="serviceForm.mer"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <label for="startdatetime" class="form-label"
              >Start Date Time</label
            >
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="startdatetime"
                name="startdatetime"
                *ngIf="!isEditing"
                [max]="serviceForm.startdatetime"
                [(ngModel)]="serviceForm.startdatetime"
                [disabled]="isEditing"
              />
              <input
                class="form-control"
                [value]="serviceForm.startdatetime | date : 'MM/dd/yyyy'"
                disabled="true"
                *ngIf="
                  isEditing &&
                  serviceForm.startdatetime.includes('T00:00:00.000Z')
                "
              />
              <input
                class="form-control"
                [value]="
                  serviceForm.startdatetime | date : 'MM/dd/yyyy hh:mm a'
                "
                disabled="true"
                *ngIf="
                  isEditing &&
                  !serviceForm.startdatetime.includes('T00:00:00.000Z')
                "
              />
            </div>
          </div>
          <div
            class="col-md-6"
            *ngIf="
              serviceForm.status != '00900' && serviceForm.status != '0040'
            "
          >
            <label for="targetdatetime" class="form-label"
              >Target Date Time</label
            >
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="targetdatetime"
                name="targetdatetime"
                [(ngModel)]="serviceForm.targetdatetime"
                [disabled]="customercare"
              />
            </div>
          </div>
          <div
            class="col-md-6 mt-3"
            *ngIf="
              serviceForm.status != '00900' && serviceForm.status != '0040'
            "
          >
            <label for="duedatetime" class="form-label">Due Date Time</label>
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="duedatetime"
                name="duedatetime"
                [(ngModel)]="serviceForm.duedatetime"
                [disabled]="customercare"
              />
            </div>
          </div>
          <div
            class="col-md-6"
            *ngIf="
              serviceForm.status == '00900' || serviceForm.status == '0040'
            "
          >
            <label for="startdatetime" class="form-label">End Date Time</label>
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="enddate"
                name="enddate"
                [min]="serviceForm.startdatetime"
                [(ngModel)]="serviceForm.enddatetime"
              />
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div
            class="col-md-6"
            *ngIf="
              serviceForm.status == '00900' || serviceForm.status == '0040'
            "
          >
            <label for="targetdatetime" class="form-label"
              >Target Date Time</label
            >
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="targetdatetime"
                name="targetdatetime"
                [(ngModel)]="serviceForm.targetdatetime"
                [disabled]="customercare"
              />
            </div>
          </div>
          <div
            class="col-md-6"
            *ngIf="
              serviceForm.status == '00900' || serviceForm.status == '0040'
            "
          >
            <label for="duedatetime" class="form-label">Due Date Time</label>
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="duedatetime"
                name="duedatetime"
                [(ngModel)]="serviceForm.duedatetime"
                [disabled]="customercare"
              />
            </div>
          </div>

          <!-- <div class="col-md-6">
                        <label for="completedatetime" class="form-label">Complete Date Time</label>
                        <div class="input-group">
                            <input type="datetime-local" class="form-control" id="completedatetime" name="completedatetime" [(ngModel)]="serviceForm.completedatetime">
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="cutomerinfo" style="margin-top: 20px">
    <div class="card card-body my-card">
      <div class="info">
        Customer
        <span
          *ngIf="isdomainAdmin && !customercare"
          (click)="backCustomer()"
          style="
            font-size: 11px;
            color: var(--main-color);
            cursor: pointer;
            margin-left: 5px;
          "
          ><i class="fa fa-plus me-1"></i>Add Customer</span
        >
      </div>
      <div class="customer">
        <div class="row">
          <div class="mb-3 col-md-6" *ngIf="checkinternal">
            <label for="campany" class="form-label">Name </label>

            <ng-select
              class="role-select"
              [items]="customers"
              bindLabel="customername"
              bindValue="customerid"
              placeholder="-"
              appendTo="body"
              [multiple]="false"
              [(ngModel)]="serviceForm.customerid"
              (change)="customerSelect($event)"
            >
            </ng-select>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="gettingCustomers"
            ></mat-progress-bar>
          </div>
          <div class="mb-3 col-md-6" *ngIf="!checkinternal && !customercare">
            <label for="campany" class="form-label">Name </label>
            <ng-select
              class="role-select"
              [items]="customers"
              bindLabel="customername"
              bindValue="customerid"
              placeholder="-"
              appendTo="body"
              [multiple]="false"
              [(ngModel)]="serviceForm.customerid"
              (change)="customerSelect($event)"
              #custo="ngModel"
              required
              [ngClass]="{
                'service-select-invalid': custo.invalid && serviceFormSubmitted
              }"
            >
            </ng-select>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="gettingCustomers"
            ></mat-progress-bar>
            <div
              class="invalid-ngselect"
              *ngIf="custo.invalid && serviceFormSubmitted"
            >
              <div>{{ allinoneService.input_err_msg }}</div>
            </div>
          </div>
          <div class="mb-3 col-md-6" *ngIf="!checkinternal && customercare">
            <label for="campany" class="form-label">Name </label>
            <!-- [disabled]="customercare" -->
            <input
              type="text"
              [(ngModel)]="serviceForm.customername"
              aria-label="Number"
              matInput
              class="form-control"
              [disabled]="customercare"
            />
          </div>
          <div class="mb-3 col-md-6" *ngIf="!customercare">
            <label for="branch" class="form-label">Branch </label>
            <ng-select
              [ngModelOptions]="{ standalone: true }"
              class="role-select"
              [items]="branchlist"
              bindValue="branchid"
              bindLabel="branchname"
              placeholder="-"
              appendTo="body"
              [multiple]="false"
              [(ngModel)]="serviceForm.branchid"
              (change)="branchSelect($event)"
            >
            </ng-select>
          </div>

          <div class="mb-3 col-md-6" *ngIf="customercare">
            <label for="campany" class="form-label">Branch </label>
            <!-- [disabled]="customercare" -->
            <input
              type="text"
              [(ngModel)]="branchname"
              aria-label="Number"
              matInput
              class="form-control"
              [disabled]="customercare"
            />
          </div>
          <!-- *ngIf="!checkinternal && customercare" -->

          <div *ngIf="!customercare">
            <div class="mb-3 me-2">
              <div class="form-check cus" *ngIf="checkinternal">
                <input
                  class="form-check-input cus"
                  type="checkbox"
                  id="internalcheckbox"
                  name="internalcheckbox"
                  checked="checked"
                  (change)="checkInternal($event)"
                />
                <label
                  class="form-check-label cus mb-1 ms-1"
                  for="internalcheckbox"
                >
                  Internal
                </label>
              </div>
              <div class="form-check cus" *ngIf="!checkinternal">
                <input
                  class="form-check-input cus"
                  type="checkbox"
                  id="internalcheckbox2"
                  name="internalcheckbox2"
                  (change)="checkInternal($event)"
                />
                <label
                  class="form-check-label cus mb-1 ms-1"
                  for="internalcheckbox2"
                >
                  Internal
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- serviceForm.companyname -->
          <div class="mb-3 col-md-6">
            <label for="customer" class="form-label">Contact</label>
            <input
              type="text"
              [(ngModel)]="searchContactName"
              aria-label="Number"
              matInput
              class="form-control"
              (ngModelChange)="contactChange()"
              [matAutocomplete]="autocontact"
              [disabled]="customercare"
            />
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="gettingCustomers"
            ></mat-progress-bar>
            <mat-autocomplete #autocontact="matAutocomplete">
              <mat-option
                *ngFor="
                  let con of contactlist
                    | crmFilter : searchContactName : text1 : text2 : 12
                "
                [value]="con.name"
                (onSelectionChange)="contactSelect(con)"
                (click)="contactSelect(con)"
              >
                {{ con.contactname }} - {{ con.contactemail }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="mb-3 col-md-6">
            <label for="des" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="serviceForm.customeremail"
            />
          </div>
          <div class="mb-3 col-md-6">
            <label for="des" class="form-label">Phone</label>
            <input
              type="text"
              class="form-control"
              id="phone"
              name="phone"
              [(ngModel)]="serviceForm.customerphone"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="card card-body my-card" style="margin-top: 20px">
        <div class="detailinfo">
          <div class="d-flex flex-wrap">
            <div class="mb-3 me-2">
              <div
                class="form-check cus"
                style="padding-top: 8px"
                *ngIf="checkrecurring"
              >
                <input
                  class="form-check-input cus"
                  type="checkbox"
                  id="open1"
                  name="open1"
                  checked="checked"
                  (change)="reOpen($event)"
                />
                <label class="form-check-label cus mb-1 ms-1" for="open1">
                  Recurring
                </label>
              </div>
              <div
                class="form-check cus"
                style="padding-top: 8px"
                *ngIf="!checkrecurring"
              >
                <input
                  class="form-check-input cus"
                  type="checkbox"
                  id="open2"
                  name="open2"
                  (change)="reOpen($event)"
                />
                <label class="form-check-label mb-1 cus ms-1" for="open2">
                  Recurring
                </label>
              </div>
            </div>
            <div class="mb-3">
              <div class="input-group">
                <!-- <input type="text" class="form-control" id="recuring" name="recuring" [(ngModel)]="serviceForm.reticketno"> -->
                <div
                  class="input-group me-2 mb-2 recurringtick"
                  style="width: 330px"
                >
                  <!-- (keyup.enter)="onSearch()"[ngClass]="{'c-search-input-with-clear' : searchText }" -->
                  <input
                    (keyup.enter)="onSearch()"
                    class="form-control"
                    type="text"
                    placeholder="Ticket No."
                    aria-label="Search"
                    (focus)="isFocus = true"
                    (blur)="isFocus = false"
                    [(ngModel)]="serviceForm.reticketno"
                    style="font-family: FontAwesome, Ubuntu"
                    aria-placeholder="Ticket No."
                    [disabled]="searchDisable"
                  />

                  <!-- <button class="btn btn-outline-secondary " type="button" *ngIf="serviceForm.reticketno" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                    <mat-icon class="c-icon">close</mat-icon>
                                </button> -->
                  <button
                    *ngIf="!searchDisable && !recurringLoating"
                    class="input-group-text btn btn-custom"
                    id="btnGroupAddon2"
                    (click)="onSearch()"
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                  <button
                    *ngIf="searchDisable && !recurringLoating"
                    class="btn btn-custom input-group-text"
                    style="border-left: 1px solid white"
                    (click)="cancelEdit()"
                  >
                    <i class="fa fa-close"></i>
                  </button>

                  <button class="btn btn-custom" *ngIf="recurringLoating">
                    <span
                      style="border-radius: 50%"
                      class="spinner-border spinner-border-sm button-spinner"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>

                  <!-- <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span> -->
                </div>
              </div>
            </div>
            <div
              *ngIf="checkconfirm"
              class="mb-3 me-2"
              style="font-size: 19px; color: green; margin-top: 8px"
            >
              <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            </div>
            <div
              *ngIf="falseconfirm"
              class="mb-3 me-2"
              style="font-size: 19px; color: red; margin-top: 8px"
            >
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
            </div>

            <div class="line"></div>

            <div class="mb-3" *ngIf="checkconfirm">
              <button
                class="btn btn-custom"
                id="btnGroupAddon2"
                (click)="onConfirm()"
              >
                Confirm
              </button>
            </div>
          </div>
          <div class="row">
            <div class="mb-3">
              <label for="remark" class="form-label">Detail</label>

              <textarea
                class="form-control"
                id="remark"
                name="remark"
                [(ngModel)]="serviceForm.detail"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="mb-3">
              <label for="remark" class="form-label">Solution</label>

              <textarea
                class="form-control"
                id="remark"
                name="remark"
                [(ngModel)]="serviceForm.solution"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="mb-3">
              <label for="remark" class="form-label">Remark</label>

              <textarea
                class="form-control"
                id="remark"
                name="remark"
                [(ngModel)]="serviceForm.remark"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lastupdate" *ngIf="isEditing">
      <div class="card card-body my-card" style="margin-top: 20px">
        <div class="info">Last Updated</div>
        <div class="detailinfo">
          <div class="row">
            <div class="mb-3 col-md-6">
              <label for="des" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="updatename"
                name="updatename"
                [(ngModel)]="updatename"
                disabled
              />
            </div>
            <div class="mb-3 col-md-6">
              <label for="des" class="form-label">Date Time</label>
              <input
                type="text"
                class="form-control"
                id="updatename"
                name="updatename"
                [(ngModel)]="updatetime"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!customercare"
      class="card card-body my-card"
      style="margin-top: 20px"
    >
      <div class="info">Record</div>
      <div style="margin: 10px">
        <div class="mb-3 col-md-12" *ngIf="recordAttachmentList.length > 0">
          <textarea
            class="form-control"
            id="Record"
            name="Record"
            [(ngModel)]="recordDesc"
            rows="3"
            required
            #rec="ngModel"
            [ngClass]="{
              'is-invalid':
                RecordFormSubmitted &&
                rec.invalid &&
                recordAttachmentList.length > 0
            }"
          ></textarea>
          <div
            class="invalid-feedback"
            *ngIf="
              (recordAttachmentList.length > 0 && rec.invalid) ||
              ((rec.dirty || rec.touched) && RecordFormSubmitted)
            "
          >
            <div *ngIf="rec.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-12" *ngIf="recordAttachmentList.length == 0">
          <textarea
            class="form-control"
            id="Record"
            name="Record"
            [(ngModel)]="recordDesc"
            rows="3"
            required
            #desrecord="ngModel"
            [ngClass]="{
              'is-invalid': RecordFormSubmitted && desrecord.invalid
            }"
          ></textarea>
          <div
            class="invalid-feedback"
            *ngIf="
              desrecord.invalid ||
              ((desrecord.dirty || desrecord.touched) && RecordFormSubmitted)
            "
          >
            <div *ngIf="desrecord.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="me-auto"></div>
          <div>
            <input
              style="display: none; cursor: pointer"
              type="file"
              (change)="onFileSelect1($event)"
              #hiddenpdffileinput1
              multiple
            />
            <div
              (click)="hiddenpdffileinput1.click()"
              class="fileattach"
              style="cursor: pointer"
            >
              <i
                class="fa fa-paperclip"
                aria-hidden="true"
                style="margin-top: 5px; margin-right: 6px; font-size: 15px"
              ></i>
              <div style="margin-top: 2px">Choose Attachments</div>
            </div>
          </div>

          <div *ngIf="isEditing">
            <div *ngIf="!updateRecord">
              <app-button-loader
                *ngIf="!statuscheck"
                (click)="RecordSave()"
                [button_text]="'Save Record'"
                [load_button_text]="'Saving'"
                [isLoading]="saverecord"
              >
              </app-button-loader>
              <button
                *ngIf="statuscheck"
                class="btn btn-custom"
                type="button"
                disabled
              >
                Save Record
              </button>
            </div>
            <div *ngIf="updateRecord" class="d-flex">
              <button
                type="button"
                class="btn btn-custom me-2"
                (click)="cancelUpdate()"
              >
                Cancel
              </button>
              <app-button-loader
                *ngIf="!statuscheck"
                (click)="RecordSave()"
                [button_text]="'Edit Record'"
                [load_button_text]="'Saving'"
                [isLoading]="saverecord"
              >
              </app-button-loader>
              <button
                *ngIf="statuscheck"
                class="btn btn-custom"
                type="button"
                disabled
              >
                Edit Record
              </button>
            </div>
          </div>
        </div>
        <div class="attachmentList mb-3">
          <div *ngFor="let file of recordAttachmentList; let index = index">
            <div class="pdf-container">
              <div class="pdf-filename">
                {{ file.filename }}
              </div>
              <div class="pdf-action d-flex align-items-center">
                <button
                  mat-icon-button
                  class="icon-button"
                  (click)="removeAttachmentRecord(index)"
                >
                  <mat-icon class="delete-icon">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="divider"
          *ngIf="RecordList.length != 0 && recordAttachmentList.length != 0"
        ></div>

        <div
          class="justify-content-center"
          *ngIf="getRecord"
          style="
            text-align: center;
            height: 100px;
            padding: 10px;
            align-items: center;
            vertical-align: middle;
          "
        >
          <div class="spinner-border" role="status" style="margin-top: 25px">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!-- getRecord -->
        <div *ngIf="!getRecord">
          <div
            class="card card-body my-card"
            style="
              border: 1px solid rgb(86 86 86 / 13%);
              border-radius: 5px;
              margin-top: 10px;
            "
            *ngFor="let i of RecordList"
          >
            <div class="d-flex justify-content-between">
              <div class=" ">
                {{ i.reportedbyname }}
                <span class="recordid"> ( {{ i.reportedby }} ) </span>
              </div>
              <div class="" style="text-align: end">
                <i
                  class="fa fa-edit"
                  (click)="EditRecord(i)"
                  style="margin-right: 6px; cursor: pointer"
                ></i>
                <i
                  class="fa fa-trash-o"
                  (click)="deleteRecord(i.recordid)"
                  style="margin-right: 5px; color: red; cursor: pointer"
                ></i>
              </div>
            </div>
            <div class="recorddate">{{ i.createAt }}</div>
            <hr />
            <div class="recorddesc">{{ i.description }}</div>
            <div *ngFor="let file of i.recordfilename">
              <div
                class="recordfile"
                style="cursor: pointer; width: 43%"
                (click)="downloadFile(file.fileurl, file.filename)"
              >
                {{ file.filename
                }}<span
                  style="
                    font-size: 20px;
                    transform: translateY(-10%);
                    position: absolute;
                    cursor: pointer;
                  "
                >
                  <mat-icon
                    class="download-icon"
                    style="margin: 4px; font-size: 20px"
                    >file_download</mat-icon
                  >
                </span>
              </div>
              <!-- (click)="downloadFile(file.fileurl, file.filename)" -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!customercare && isEditing"
      class="card card-body my-card"
      style="margin-top: 20px"
    >
      <div class="info">Manhours Effort</div>
      <div style="margin: 10px">
        <!-- *ngIf="manhourList.length > 0" -->

        <div class="row">
          <div class="mb-2 col-md-6">
            <label for="project" class="form-label">Assign Person</label>
            <select
              class="form-select input-margin"
              name="center"
              id="center"
              [(ngModel)]="manhourForm.assignedto"
              #assig="ngModel"
              [ngClass]="{ 'is-invalid': manhourFormSave && assig.invalid }"
              required
            >
              <!-- #center="ngModel" [ngClass]="{'is-invalid': applicationFormSave && center.invalid}" required -->
              <option value=""></option>
              <option
                *ngFor="let ass of manhourassignedlist"
                [value]="ass.picid"
              >
                {{ ass.name }}
              </option>
            </select>
            <div
              class="invalid-feedback"
              *ngIf="
                assig.invalid ||
                ((assig.dirty || assig.touched) && manhourFormSave)
              "
            >
              <div *ngIf="assig.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
          <div class="mb-2 col-md-6">
            <label for="project" class="form-label">Manhour</label>

            <input
              type="text"
              class="form-control"
              id="Manhour"
              name="Manhour"
              [(ngModel)]="manhourForm.manhour"
              #manhour="ngModel"
              [ngClass]="{ 'is-invalid': manhourFormSave && manhour.invalid }"
              required
            />
            <div
              class="invalid-feedback"
              *ngIf="
                manhour.invalid ||
                ((manhour.dirty || manhour.touched) && manhourFormSave)
              "
            >
              <div *ngIf="manhour.errors?.required">
                {{ allinoneService.input_err_msg }}
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2 col-md-12">
          <label for="project" class="form-label">Activity</label>
          <textarea
            class="form-control"
            id="Activity"
            name="Activity"
            [(ngModel)]="manhourForm.activity"
            rows="3"
            #activity="ngModel"
            [ngClass]="{ 'is-invalid': manhourFormSave && activity.invalid }"
            required
          ></textarea>

          <div
            class="invalid-feedback"
            *ngIf="
              activity.invalid ||
              ((activity.dirty || activity.touched) && manhourFormSave)
            "
          >
            <div *ngIf="activity.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>

        <div class="d-flex mb-3">
          <div class="me-auto"></div>

          <div>
            <div class="d-flex">
              <button
                type="button"
                class="btn btn-custom me-2"
                (click)="cancelmanhour()"
              >
                Cancel
              </button>
              <app-button-loader
                (click)="SaveManhour()"
                [button_text]="'Save Manhour'"
                [load_button_text]="'Saving'"
                [isLoading]="savemanmanhour"
              >
              </app-button-loader>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-3 col-md-3">Assign Person</td>
                <td scope="col" class="col-lg-3 col-md-3">Activity</td>
                <td scope="col" class="col-lg-3 col-md-3">Manhour</td>
                <td scope="col" class="d-flex justify-content-end">Action</td>
              </tr>
            </thead>
            <tbody *ngIf="getttingManhour">
              <tr>
                <td colspan="4" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="getttingManhour == false">
              <tr *ngIf="manhourlist.length == 0">
                <td colspan="4" class="nodata">Empty</td>
              </tr>
              <tr *ngFor="let item of manhourlist">
                <td
                  class="td-data"
                  style="cursor: pointer"
                  (click)="editmanhour(item)"
                >
                  <span [title]="item.assignedtoname">{{
                    item.assignedtoname
                  }}</span>
                </td>
                <td
                  class="td-data"
                  style="cursor: pointer"
                  (click)="editmanhour(item)"
                >
                  <span [title]="item.activity">{{ item.activity }}</span>
                </td>
                <td
                  class="td-data"
                  style="cursor: pointer"
                  (click)="editmanhour(item)"
                >
                  <span [title]="item.manhour">{{ item.manhour }}</span>
                </td>
                <td class="d-flex justify-content-end">
                  <button
                    [title]="'Edit'"
                    class="btn btn-outline-primary me-2"
                    (click)="editmanhour(item)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-outline-danger"
                    (click)="deletemanhour(item)"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="divider" *ngIf="RecordList.length != 0 && recordAttachmentList.length != 0">
                </div>

                <div class="justify-content-center" *ngIf="getRecord" style="text-align: center;
            height: 100px;
            padding: 10px;
            align-items: center;
            vertical-align: middle;">
                    <div class="spinner-border" role="status" style="margin-top:25px">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!getRecord">
                    <div class="card card-body my-card"
                        style=" border: 1px solid rgb(86 86 86 / 13%);border-radius: 5px;margin-top: 10px;"
                        *ngFor="let i of RecordList">
                        <div class="d-flex justify-content-between">
                            <div class=" ">{{i.reportedbyname}}
                                <span class="recordid">
                                    ( {{i.reportedby}} )
                                </span>
                            </div>
                            <div class="" style="text-align: end;">
                                <i class='fa fa-edit' (click)="EditRecord(i)"
                                    style="margin-right: 6px;cursor: pointer;"></i>
                                <i class='fa fa-trash-o' (click)="deleteRecord(i.recordid)"
                                    style="margin-right: 5px;color: red;cursor: pointer;"></i>
                            </div>
                        </div>
                        <div class="recorddate">{{i.createAt}}</div>
                        <hr>
                        <div class="recorddesc">{{i.description}}</div>
                        <div *ngFor="let file of i.recordfilename">
                            <div class="recordfile" style="cursor: pointer;width: 43%;"
                                (click)="downloadFile(file.fileurl, file.filename)">{{file.filename}}<span style="font-size: 20px; transform: translateY(-10%);
                            position: absolute;cursor: pointer;">
                                    <mat-icon class="download-icon" style="    margin: 4px;
                            font-size: 20px;">file_download</mat-icon>
                                </span></div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>

    <div class="attach" style="margin-top: 20px">
      <input
        style="display: none"
        type="file"
        (change)="onFileSelect($event)"
        #hiddenpdffileinput
        multiple
      />
      <button
        class="btn btn-outline-primary custom-btn"
        (click)="hiddenpdffileinput.click()"
      >
        <span>Choose Attachments</span>
      </button>

      <div class="attachmentList mb-3">
        <div *ngFor="let file of attachmentList; let index = index">
          <div class="pdf-container">
            <div class="pdf-filename">
              {{ file.filename }}
            </div>
            <div class="pdf-action d-flex align-items-center">
              <button
                mat-icon-button
                class="icon-button"
                (click)="removeAttachment(index)"
              >
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>
              <button
                mat-icon-button
                class="ms-2 icon-button"
                (click)="downloadFile(file.fileurl, file.filename)"
                *ngIf="file.fileurl"
              >
                <mat-icon class="download-icon">file_download</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
