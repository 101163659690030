import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-import-leave-entitlement',
  templateUrl: './import-leave-entitlement.component.html',
  styleUrls: ['./import-leave-entitlement.component.scss'],
})
export class ImportLeaveEntitlementComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;

  rawMemberList: any[] = [];
  finalMemberList: any[] = [];
  errorList: any[] = [];
  fileSelected: any = '';
  checkingExcelFile: boolean = false;
  isTemplateOk: boolean = false;
  isImporting: boolean = false;

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ImportLeaveEntitlementComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onExcelFileSelect(event: any) {
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.fileSelected = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        // this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.rawMemberList = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
          raw: false,
        });
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }

  import() {
    if (this.isTemplateOk) {
      // file upload to s3
      var filename =
        this.allinoneService.getCurrentDateToDB() +
        this.allinoneService.getCurrentTimeToDB2() +
        '_' +
        this.fileSelected.name;
      console.log(filename);
      // var uploadResult =
      //   this.allinoneService.fileUploadToS3WithPathForImageAndProgress(
      //     this.fileSelected,
      //     filename,
      //     'importfiles/origin'
      //   );

      let bucketkey = 'importfiles/origin' + '/' + filename;

      this.allinoneService
        .uploadFileToS3WithPresignedUrlProgress(
          bucketkey,
          this.fileSelected,
          this.fileSelected.type
        )
        .subscribe({
          next: (status) => {
            if (!status.success && status.progress) {
            } else if (status.success) {
              console.log('Successfully uploaded file.');
              return true;
            } else {
              console.log('There was an error uploading your file: ');

              this.messageService.openSnackBar(
                'Error while uploading your file.',
                'warn'
              );
              return false;
            }
            return false;
          },
          error: (err) => console.error('Error:', err),
        });

      this.dialogRef.close({
        memberList: this.finalMemberList,
        filename: filename,
      });
    }
  }

  downloadSampleFile() {
    this.kunyekService.downloadSampleTemplate(
      'templates/LeaveEntitlementSampleTemplate.xlsx',
      'LeaveEntitlementSampleTemplate.xlsx'
    );
  }

  removeExcel() {
    this.isTemplateOk = false;
    this.fileSelected = '';
    this.fileInput.nativeElement.value = '';
  }

  checkExcelFile() {
    this.checkingExcelFile = true;
    this.errorList = [];
    this.finalMemberList = [];

    const tempRoleExcelData: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '')
      ) {
      } else {
        const userid = raw[0] ? raw[0].toString().trim() : '';
        const username = raw[1] ? raw[1].toString().trim() : '';
        let error = false;

        if (userid == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'User ID' is empty.",
          };
          this.errorList.push(tempError);
          error = true;
        }

        // check userid duplicated in finalMemberList
        const temp = this.finalMemberList.find((x: any) => x.userid == userid);
        if (temp) {
          const tempError = {
            line: (i + 1).toString(),
            error: "User ID '" + userid + "' is duplicated.",
          };
          this.errorList.push(tempError);
          error = true;
        }
        // if no error, push to final member list
        if (!error) {
          const tempMember = {
            userid: this.allinoneService.checkUserId(userid),
            username: username,
            newuserid: '',
            userimage: '',
            checked: true,
          };
          this.finalMemberList.push(tempMember);
        }
      }
    }

    console.log('errorList ====> ');
    console.log(this.errorList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (this.errorList.length == 0 && this.finalMemberList.length > 0) {
      this.isTemplateOk = true;
    }
    this.checkingExcelFile = false;
  }
}
