import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-office-leave-opening-import',
  templateUrl: './office-leave-opening-import.component.html',
  styleUrls: ['./office-leave-opening-import.component.scss'],
})
export class OfficeLeaveOpeningImportComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;

  saveLoading: boolean = false;
  formSubmitted: boolean = false;
  isError: boolean = false;

  errorData: any;

  fileSelected: any = '';
  filename: string = '';
  filetype: string = '';
  orgid: string = '';
  domainid: string = '';
  selectedYear: string = '';

  calendarList: any = [];

  allowedFiletype = ['xlsx'];

  progress: number = 0;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<OfficeLeaveOpeningImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orgid = data.orgid;
    this.domainid = data.domainid;
    this.calendarList = data.calendarlist;
    this.isError = data.isError;
    this.errorData = data.errorData;
  }
  ngOnInit(): void {}

  uploadFile(event: any) {
    var filetype = event[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  onFileSelected(event: any) {
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.allowedFiletype.includes(filetype)) {
      this.fileSelected = event.target.files[0];
      this.filename = this.fileSelected.name;
      this.filetype = filetype;
    } else {
      this.messageService.openSnackBar(
        'This file type is not allowed.',
        'warn'
      );
    }
    this.choosefile.nativeElement.value = '';
  }

  uploadfile() {
    this.formSubmitted = true;
    if (this.selectedYear == '') {
      return;
    }
    if (!this.fileSelected) {
      return this.messageService.openSnackBar('Please upload a file.', 'warn');
    }

    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var filename =
      this.allinoneService.getCurrentDateToDB() +
      this.allinoneService.getCurrentTimeToDB2() +
      '_' +
      this.filename;
    // this.allinoneService
    //   .fileUploadToS3WithPathForImageAndProgress(
    //     this.fileSelected,
    //     filename,
    //     'importfiles/origin'
    //   )
    //   .on('httpUploadProgress', (progress) => {
    //     let progressPercentage = Math.round(
    //       (progress.loaded / progress.total) * 100
    //     );
    //     console.log(progressPercentage);
    //     this.progress = progressPercentage;
    //   })
    //   .send((err: any, data: any) => {
    //     if (err) {
    //       console.log('There was an error uploading your file: ', err);
    //       //Fail
    //       this.progress = 0;
    //       this.saveLoading = false;
    //       this.dialogRef.disableClose = false;

    //       this.messageService.openSnackBar(
    //         'Error while uploading your file.',
    //         'warn'
    //       );
    //       return false;
    //     }
    //     this.submitImport(filename);
    //     return true;
    //   });

    let bucketkey = 'importfiles/origin' + '/' + filename;

    this.allinoneService
      .uploadFileToS3WithPresignedUrlProgress(
        bucketkey,
        this.fileSelected,
        this.fileSelected.type
      )
      .subscribe({
        next: (status) => {
          if (!status.success && status.progress) {
            this.progress = status.progress;
          } else if (status.success) {
            this.submitImport(filename);
            console.log('Successfully uploaded file.');
            return true;
          } else {
            console.log('There was an error uploading your file: ');
            this.progress = 0;
            this.saveLoading = false;
            this.dialogRef.disableClose = false;

            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            return false;
          }
          return false;
        },
        error: (err) => console.error('Error:', err),
      });
  }

  submitImport(filename: string) {
    var data = {
      calendarid: this.selectedYear,
      orgid: this.orgid,
      filename: filename,
    };
    this.kunyekService.addLeaveOpeningImport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.close(true);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  onDismiss() {
    if (this.saveLoading) {
      return;
    }
    this.dialogRef.close();
  }

  downloadSample() {
    window.open(
      this.allinoneService.bucketUrl +
        'importfiles/origin/OpeningSampleTemplate.xlsx',
      '_blank'
    );
  }
  //1 - origin, 2- error
  downloadFile(type: number) {
    if (type == 1) {
      window.open(this.errorData.originfile, '_blank');
    } else {
      window.open(this.errorData.errorfile, '_blank');
    }
  }

  cancelImport() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      importstatusid: this.errorData.importstatusid,
    };
    this.kunyekService.updateLeaveOpeningImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.close(true);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }
}
